import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import Loader from "../Loader";
import "./superAstrologer.css";
import BASE_URL from "../config";
const SuperAstrologers = () => {
  const [astrologers, setAstrologers] = useState([]);
  const [selectedAstrologer, setSelectedAstrologer] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { chatId, callId } = useParams();
  const navigate = useNavigate();
  // console.log(chatId)
  const astrologersApi = `${BASE_URL}/astrologer/getSuperAstrologer`;

  const assignSuperAstrologerToPendingCallsApi = `${BASE_URL}/astrologer/assign-super-astrologer/${chatId}`;
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    const fetchAstrologers = async () => {
      setIsLoading(true);
      const response = await fetch(astrologersApi, { signal });
      const data = await response.json();
      const { superAstrologers } = data;
      // console.log("get super astrologers",data)
      if (response.ok) {
        setAstrologers(superAstrologers);
        setIsLoading(false);
      }
      setIsLoading(false);
    };
    fetchAstrologers();

    return () => controller.abort();
  }, []);

  const handleSelect = (astrologer) => {
    setSelectedAstrologer(astrologer);
    setShowPopup(true);
  };

  const handleConfirm = async () => {
    const controller = new AbortController();
    const { signal } = controller;
    let response;

    if (chatId) {
      setIsLoading(true);
      response = await fetch(assignSuperAstrologerToPendingCallsApi, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ astrologerId: selectedAstrologer }),
        signal,
      });
    } else {
      response = await fetch(
        `{BASE_URL}/astrologer/assign-super-astrologer/${chatId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ astrologerId: selectedAstrologer }),
          signal,
        }
      );
    }

    if (response.ok) {
      setShowPopup(false);
      navigate(-1);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  return (
    <div>
      <h1>Select a Super Astrologer</h1>
      <button className="astro-back-button" onClick={() => navigate(-1)}>
        <IoMdArrowRoundBack size={23} className="back-arrow" />
      </button>
      <table className="astro-table">
        <thead className="astro-he">
          <tr className="astro-bg">
            <th className="he">Astrologer Name</th>
            <th className="he">Phone Number</th>
            <th className="he">Status</th>
            <th className="he">Known Languages</th>
            <th className="he">Session Amount/min</th>
            <td className="he">No.of Waiting List</td>
            <td className="he">TotalCompleteChatToday</td>
            <th className="he">Action</th>
          </tr>
        </thead>
        <tbody>
          {astrologers?.map((astrologer) => (
            <tr key={astrologer.id} className="astro-rows">
              <td
                className="he"
                onClick={() => {
                  navigate(`/dashboard/astrologer/${astrologer._id}`, {
                    state: { astrologer },
                  });
                }}
                style={{ color: "blue", cursor: "pointer" }}
              >
                {astrologer?.astrologerName || "Loading..."}
              </td>
              <td className="he">{astrologer.phone_number}</td>
              <td className="he">{astrologer.status}</td>
              <td className="he">{astrologer?.known_languages?.join(",")}</td>
              <td className="he">{`Rs: ${astrologer.cost}/-`}</td>
              <td className="he" style={{ textAlign: "center", verticalAlign: "middle" }}>{astrologer.waitingCount}</td>
              <td className="he" style={{ textAlign: "center", verticalAlign: "middle" }}>{astrologer.completedSessionsCount}</td>
              <td className="he">
                <button
                  className="super-astrologer-confirm-button"
                  onClick={() => handleSelect(astrologer._id)}
                >
                  Select
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {showPopup && (
        <div className="super-astrologer-popup">
          <div className="super-astrologer-popup-content">
            <h3>Are you sure you want to assign {selectedAstrologer.name}?</h3>
            <div className="super-astrologer-popup-buttons">
              <button
                className="super-astrologer-confirm-button"
                onClick={handleConfirm}
              >
                Yes
              </button>
              <button
                className="super-astrologer-cancel-button"
                onClick={() => setShowPopup(false)}
              >
                Cancel
              </button>
              {isLoading === true ? <Loader /> : ""}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SuperAstrologers;
