import { useEffect, useState } from "react";
import Loader from "../Loader";
import BASE_URL from "../config";
const PastPickupTimes = ( astrologerId ) => {
  const [pickUpData, setPickUpTimeData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const getAstrologerPastPickupTimes = async () => {
    setIsLoading(true);
    const getPickupTimesApiUrl = `${BASE_URL}/astrologer/getPastPickTimeDetails`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(astrologerId),
    };

    try {
      const response = await fetch(getPickupTimesApiUrl, options);
      const data = await response.json();

      if (response.ok === true) {
        setIsLoading(false);
        const { PastPickTimeDetails } = data;
        const flattenedData = PastPickTimeDetails?.flat();

        console.log("Flattened Past Pick Details:", flattenedData);

        setPickUpTimeData(flattenedData);
      } else {
        setPickUpTimeData([]);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching Past Pickup Times:", error);
    }
  };

  useEffect(() => {
    getAstrologerPastPickupTimes();
  }, []);

  const renderPastPickupTable = () => (
    <div className="astro-table-container pymnts-container">
      <table className="astro-table">
        <thead className="astro-he">
          <tr className="astro-bg">
            <th className="he">MinimumPickTime</th>
            <th className="he">MaximumPickTime</th>
            <th className="he">AveragePickTime</th>
            <th className="he">Date</th>
          </tr>
        </thead>
        <tbody>
          {pickUpData.map((data) => (
            <tr className="astro-rows" key={data._id}>
              <td className="he">{data.minimumPickTime}</td>
              <td className="he">{data.maximumPickTime}</td>
              <td className="he">{data.averagePickTime}</td>
              <td className="he">{data?.date.split("T")[0]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : pickUpData?.length > 0 ? (
        renderPastPickupTable()
      ) : (
        <h1>There is No Pick Up History Available!</h1>
      )}
    </div>
  );
};

export default PastPickupTimes;
