import React from 'react';
import './index.css'; 

const RegisterDetailedAstrologerPopUp = ({ message, onConfirm, onCancel }) => {
    return (
        <div className="astro-popup-overlay">
            <div className="astro-popup-content">
                <p>{message}</p>
                <div className="popup-buttons">
                    <button className="astro-confirm-button" onClick={onConfirm}>
                        Confirm
                    </button>
                    <button className="astro-cancel-button" onClick={onCancel}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

export default RegisterDetailedAstrologerPopUp;