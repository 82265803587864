import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import BASE_URL from "./config";
import Loader from "./Loader";
import "./login.css";

const Login = () => {
  const [phone_number, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [responseStatus, setResponseStatus] = useState("");
  const [isOtpGot, setIsOtpGot] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const onChangeMobileNumber = (event) => {
    setPhoneNumber(event.target.value);
  };

  const onChangeOtp = (event) => {
    setOtp(event.target.value);
  };

  useEffect(() => {
    const controller = new AbortController();

    return () => {
      controller.abort();
    };
  }, []);
  const onSubmitToGetOtp = async (event) => {
    event.preventDefault();
    const enteredDetails = { phone_number };
    setIsLoading(true);
    const apiUrl = `${BASE_URL}/login/send-otp`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(enteredDetails),
    };

    try {
      const response = await fetch(apiUrl, options);
      const data = await response.json();
      if (response.ok) {
        setResponseStatus(data.message);
        setIsOtpGot(true);
        setIsLoading(false);
      } else {
        setResponseStatus(data.message);
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      setResponseStatus("Unauthorized number Please Enter a valid Number");
    }
  };

  //console.log("")

  const verifyOtp = async () => {
    const apiUrl = `${BASE_URL}/login/otp-verify`;
    const userOtp = { otp, phone_number };
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userOtp),
    };

    try {
      setIsLoading(true);
      const response = await fetch(apiUrl, options);
      const data = await response.json();
      if (response.ok) {
        const { token } = data;
        Cookies.set("jwt_token", token);
        navigate("/dashboard");
        setIsLoading(false);
      } else {
        setResponseStatus(data.message);
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setResponseStatus("Enter valid Number. Please try again.");
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={onSubmitToGetOtp} className="login-form-container">
        <h1 className="login-main-heading">ASTROPING ADMIN LOGIN</h1>
        <div className="login-input-section">
          <label className="login-mobile">Mobile Number :</label>
          <input
            value={phone_number}
            onChange={onChangeMobileNumber}
            type="tel"
            placeholder="Enter Mobile Number"
          />
        </div>
        <div className="login-input-section">
          <label className="otp-label">OTP :</label>
          <input
            value={otp}
            onChange={onChangeOtp}
            type="tel"
            placeholder="Enter OTP"
          />
        </div>
        <p>{responseStatus}</p>
        <div className="login-submit-btn">
          {isOtpGot ? (
            <button type="button" onClick={verifyOtp}>
              {isLoading === true ? "Loading..." : "Sign In"}
            </button>
          ) : (
            <button type="submit">
              {isLoading === true ? "Loading..." : "GET OTP"}
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default Login;
