import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../config";
const LiveChats = () => {
  const [currentLiveChatPage, setCurrentChatPage] = useState(1);
  const [totalLiveChatPages, setTotalLivePages] = useState(null);
  const [liveChatData, setLiveChatData] = useState([]);
  const [totalNumberOfChats, setTotalNumberOfChats] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const getLiveChats = async (signal) => {
    setIsLoading(true);
    const liveChatApiUrl = `${BASE_URL}/astrologer/get-Live-chats-data?page=${currentLiveChatPage}`;
    const response = await fetch(liveChatApiUrl, { signal });
    const data = await response.json();
    if (response.ok === true) {
      const { totalChats, totalPages, chats } = data;
      setTotalLivePages(totalPages);
      setLiveChatData(chats);
      setTotalNumberOfChats(totalChats);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }

    // console.log("live chats",data)
  };

  const handlePageChange = (direction) => {
    if (direction === "prev" && currentLiveChatPage > 1) {
      setCurrentChatPage(currentLiveChatPage - 1);
    } else if (
      direction === "next" &&
      currentLiveChatPage < totalLiveChatPages
    ) {
      setCurrentChatPage(currentLiveChatPage + 1);
    }
  };

  const LiveChatDataTable = () => (
    <>
      <div className="astro-table-container pymnts-container">
        <table className="astro-table">
          <thead className="astro-he">
            <tr className="astro-bg">
              <th className="he">User Name</th>
              <th className="he">Astrologer Name</th>
              <th className="he">User PhoneNumber</th>
              <th className="he">Astrologer PhoneNumber</th>
              <th className="he">Cost/min</th>
              <th className="he">Time Duration</th>
              <th className="he">Date Time</th>
              <th className="he">WebSocketIssueCount</th>
            </tr>
          </thead>
          <tbody>
            {liveChatData?.map((chat) => (
              <tr className="astro-rows" key={chat._id}>
                <td
                  className="he"
                  onClick={() =>
                    navigate(`/dashboard/user/${chat?.userId}`, {
                      state: {
                        user: {
                          _id: chat?.userId,
                          userName: chat?.userName,
                          phone_number: chat?.from,
                        },
                      },
                    })
                  }
                  style={{ color: "blue", cursor: "pointer" }}
                >
                  {chat?.userName || "NA"}
                </td>
                <td
                  className="he"
                  onClick={() =>
                    navigate(
                      `/dashboard/astrologer/${chat?.astrologerId?._id}`,
                      {
                        state: { astrologer: chat?.astrologerId },
                      }
                    )
                  }
                  style={{ color: "blue", cursor: "pointer" }}
                >
                  {chat?.astrologerId?.astrologerName || "NA"}
                </td>
                <td className="he">{chat.from || "NA"}</td>
                <td className="he">{chat.to || "NA"}</td>
                <td className="he">{chat?.cost || "NA"}</td>
                <td className="he">{chat?.time_duration ? `${chat.time_duration} min` : "NA"}</td>
                <td className="he">{chat?.updatedAt}</td>
                <td className="he">User: {chat?.websocketIssueCount?.user}, Astrologer: {chat?.websocketIssueCount?.astrologer}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination">
        <button
          className="pagination-button"
          onClick={() => handlePageChange("prev")}
          disabled={currentLiveChatPage === 1}
        >
          Prev
        </button>
        <span className="pagination-info">
          Page {currentLiveChatPage} of {totalLiveChatPages}
        </span>
        <button
          className="pagination-button"
          onClick={() => handlePageChange("next")}
          disabled={currentLiveChatPage === totalLiveChatPages}
        >
          Next
        </button>
      </div>
    </>
  );

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller?.signal;

    getLiveChats(signal);

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <>
      <button className="btn" onClick={() => getLiveChats()}>
        {isLoading === true ? "Loading..." : "Reload"}
      </button>

      <div></div>

      <div>
        {totalNumberOfChats !== 0
          ? LiveChatDataTable()
          : "Currently Live Chats are Not Available!"}
      </div>
    </>
  );
};
export default LiveChats;
