import React from 'react';
import Loader from "../Loader";
import Popup from 'reactjs-popup';
import { IoMdCheckmarkCircle } from "react-icons/io";
import { FaXmark } from "react-icons/fa6";
import "./index.css";

const ReactPopUp = ({ astrologerId, onConfirm, updateApiStatus }) => (
  <div className="popup-container">
    <Popup
      modal
      trigger={
        <button type="button" className="trigger-button">
          Accept
        </button>
      }
    >
      {close => (
        <div className="popup-container-display">
          <div className='button-container'>
            <button onClick={() => { close() }} className='close-btn'>
              <FaXmark />
            </button>
          </div>
          <div className="confirm-details">
            <IoMdCheckmarkCircle className="checkMark" />
            <p>Astrologer Registration Approved</p>
          </div>
          {updateApiStatus === "pending" ? (<button className="loader-button">
            <Loader />
          </button>) : (<button
            type="button"
            className="confirm-button"
            onClick={() => {
              onConfirm(astrologerId, 'accepted') // Pass astrologer ID and status 'accepted'
            }}
          >
            Confirm
          </button>)}
        </div>
      )}
    </Popup>
  </div>
);

export default ReactPopUp;
