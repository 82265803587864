import React from 'react';
import './AstroPopUp.css'; 

import { ImCancelCircle } from "react-icons/im";

const AstroPopUp = ({ message, onConfirm, onCancel }) => {
    return (
        <div className="astro-popup-overlay">
            <div className="astro-popup-content">
                        <div className='cancel-x-container'>
                                <ImCancelCircle size={29} className='cancel-icon-x' onClick={onCancel} />
                        </div>
                <p>{message}</p>
                <div className="popup-buttons">
                    <button className="astro-confirm-button" onClick={onConfirm}>
                        Confirm
                    </button>
                    <button className="astro-cancel-button" onClick={onCancel}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AstroPopUp;