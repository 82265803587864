import React, { useState, useEffect } from "react";
import axios from "axios";
import "./engagement.css";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../config";
const UserEngagement = () => {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedUser, setSelectedUser] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [showNotificationPopup, setShowNotificationPopup] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({ title: "", content: "" });
  const [searchTerm, setSearchTerm] = useState("");
  const [debounceTimer, setDebounceTimer] = useState(null);
  const [isGlobalSearch, setGlobalSearch] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const navigate = useNavigate();

  const fetchPaginatedData = async (page, controller) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/user/get-users?page=${page}`,
        { signal: controller?.signal }
      );
      if (response.data.success) {
        setUsers(response.data.data);
        setTotalUsers(response.data.pagination.totalUsers);
        setTotalPages(response.data.pagination.totalPages);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const fetchNotifications = async (userId, controller) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/notification/get-notifications?userId=${userId}`,
        { signal: controller?.signal }
      );

      if (response.data.success) {
        setNotifications(response.data.data);
        setShowNotificationPopup(true);

        if (response.data.data.length === 0) {
          setStatusMessage({
            text: "No notifications found for this user.",
            type: "info",
          });
        }
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
      setStatusMessage({
        text: "An error occurred while fetching notifications.",
        type: "error",
      });
    }
  };

  const handleSendClick = (user) => {
    setSelectedUser(user);
    const controller = new AbortController();
    fetchNotifications(user._id, controller);
  };

  const closeNotificationPopup = () => {
    setShowNotificationPopup(false);
    setShowForm(false);
    setNotifications([]);
    setFormData({ title: "", content: "" });
    setStatusMessage("");
  };

  const handleEdit = (notification) => {
    setFormData({ title: notification.title, content: notification.content });
    setShowForm(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      title: formData.title,
      message: formData.content,
      userId: selectedUser._id,
    };

    try {
      const response = await axios.post(
        `${BASE_URL}/user/sendEngagementNotification-usingUserId`,
        payload
      );
      if (response.data.success) {
        setStatusMessage({
          text: "Notification sent successfully!",
          type: "success",
        });
        setFormData({ title: "", content: "" });
        setShowForm(false);
      } else {
        setStatusMessage({
          text: "Failed to send notification.",
          type: "error",
        });
      }
    } catch (error) {
      console.error("Error sending notification:", error);
      setStatusMessage({ text: "Error sending notification.", type: "error" });
    }
  };

  const fetchSearchData = async (searchTerm, controller) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/user/search-users/?query=${searchTerm}`,
        { signal: controller?.signal }
      );
      if (response.data.success) {
        setUsers(response.data.data);
        setTotalPages(1);
      } else {
        console.error("Unable to fetch search data");
      }
    } catch (error) {
      console.error("Error fetching search data:", error);
    }
  };

  const handleSearchTermChange = (value) => {
    setSearchTerm(value);

    if (debounceTimer) clearTimeout(debounceTimer);

    const newTimer = setTimeout(() => {
      setCurrentPage(1);
      const controller = new AbortController();
      if (value.trim()) {
        setGlobalSearch(true);
        fetchSearchData(value, controller);
      } else {
        setGlobalSearch(false);
        fetchPaginatedData(1, controller);
      }
    }, 500);

    setDebounceTimer(newTimer);
  };

  useEffect(() => {
    const controller = new AbortController();

    if (isGlobalSearch && searchTerm.trim()) {
      fetchSearchData(searchTerm, controller);
    } else {
      fetchPaginatedData(currentPage, controller);
    }

    return () => controller.abort();
  }, [currentPage, isGlobalSearch]);

  return (
    <div className="atsto-container">
      <h1 className="atrologers-heading">User Engagement</h1>
      <div className="astro-search-container">
        <input
          type="text"
          placeholder="Search by User name or phone number"
          className="astro-search-input"
          value={searchTerm}
          onChange={(e) => handleSearchTermChange(e.target.value)}
        />
      </div>
      <p className="length">Total Users: {totalUsers}</p>

      <table className="astro-table">
        <thead className="astro-he">
          <tr className="astro-bg">
            <th className="he">User Name</th>
            <th className="he">Action</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user._id} className="astro-rows">
              <td
                className="he"
                onClick={() =>
                  navigate(`/dashboard/user/${user._id}`, { state: { user } })
                }
              >
                {user.userName}
              </td>
              <td>
                <button
                  className="move-button"
                  onClick={() => handleSendClick(user)}
                >
                  Select
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination">
        <button
          disabled={currentPage === 1}
          onClick={() => setCurrentPage((prev) => prev - 1)}
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage((prev) => prev + 1)}
        >
          Next
        </button>
      </div>

      {showNotificationPopup && (
        <div className="popup">
          <div className="popup-content">
            {!showForm ? (
              <>
                <h2>Notifications for {selectedUser?.userName}</h2>
                {statusMessage && (
                  <div className={`status-message ${statusMessage.type}`}>
                    {statusMessage.text}
                  </div>
                )}
                <table className="astro-table">
                  <thead className="astro-he">
                    <tr className="astro-bg">
                      <th className="he">Title</th>
                      <th className="he">Content</th>
                      <th className="he">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {notifications.length > 0 ? (
                      notifications.map((notification) => (
                        <tr key={notification._id} className="astro-rows">
                          <td className="he">{notification.title}</td>
                          <td className="he">{notification.content}</td>
                          <td className="he">
                            <button onClick={() => handleEdit(notification)}>
                              Edit
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3" className="he">
                          No notifications found for this user.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <button
                  className="popup-close-button"
                  onClick={closeNotificationPopup}
                >
                  Close
                </button>
              </>
            ) : (
              <>
                <h2>Edit Notification</h2>
                <form onSubmit={handleFormSubmit}>
                  <label>
                    Title:
                    <input
                      type="text"
                      name="title"
                      value={formData.title}
                      onChange={handleInputChange}
                      required
                    />
                  </label>
                  <label>
                    Content:
                    <textarea
                      name="content"
                      value={formData.content}
                      onChange={handleInputChange}
                      required
                    />
                  </label>
                  <div className="form-actions">
                    <button type="submit">Send</button>
                    <button type="button" onClick={closeNotificationPopup}>
                      Cancel
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default UserEngagement;
