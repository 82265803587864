import React, { useState, useEffect } from "react";
import WalletPopUp from "../WalletPopUp";
import RefundSuccessPopup from "../RefundSuccessPopup";
import "./index.css";
import Loader from "../Loader";
import { refundAmount } from "../api/walletApi";
import { IoCheckmarkDoneCircle } from "react-icons/io5";

import WalletChatTransactions from "../WalletChatTransactions/WalletChatTransactions";
import WalletCallTransactions from "../WalletCallTransactions/WalletCallTransactions";
import AstrologerChatWalletTransaction from "../AstrologerChatWalletTransaction/AstrologerChatWalletTransaction";
import AstrologerCallWalletTransaction from "../AstrologerCallWalletTransaction/AstrologerCallWalletTransaction";
const WalletTransactions = () => {
  const [activeTab, setActiveTab] = useState("users");
  const [data, setData] = useState([]);
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [filter, setFilter] = useState("chat");
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [callBackData, setCallBackData] = useState({});
  const [refundSuccessful, setRefundSuccessful] = useState(false);
  const [successPercentage, setSuccessPercentage] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [afterFilter, setAfterFilterData] = useState([]);
  const rowsPerPage = 20;

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setCurrentPage(1);
  };

  // const fetchData = async (page) => {
  //   setLoading(true);
  //   try {
  //     const response = await fetch(
  //       `${BASE_URL}/wallet/all-transactions?page=${page}`
  //     );
  //     const data = await response.json();
  //     // // console.log("current page",page)
  //     // console.log("data",data)
  //     if (response.ok) {
  //       const sortedData = data.data.sort(
  //         (a, b) => new Date(b.date) - new Date(a.date)
  //       );
  //       // console.log("sorted data",sortedData)
  //       setData(sortedData);
  //       setFilteredData(sortedData);
  //       setTotalPages(data.pagination.totalPages);

  //       // console.log("44",data.pagination.totalPages)
  //     } else {
  //       console.error("Unable to fetch data");
  //     }
  //   } catch (error) {
  //     console.error("Error in fetching", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchData(currentPage);
  // }, [currentPage]);

  useEffect(() => {
    let filtered = data;
    // console.log("filter",filter)
    if (filter === "call") {
      filtered = data.filter((item) => item.type === "call");
      // console.log(data)
    } else if (filter === "chat") {
      filtered = data.filter((item) => item.type === "chat");
    }
    if (searchTerm) {
      filtered = filtered.filter((item) =>
        item?.userName?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    //setFilteredData(filtered);
    setAfterFilterData(filtered);
    // console.log(data.length)
    // console.log(`filtered  ${filter} ${filtered.length}`)

    //     const totalFilteredPages = Math.ceil(filtered.length / rowsPerPage);
    //     setTotalPages(totalFilteredPages);
    //    // console.log("74",totalFilteredPages)
    //     setCurrentPage(1);
  }, [filter, searchTerm, data]);

  const closePopup = () => {
    setPopupVisible(false);
    setPopupMessage("");
  };

  // console.log("pages",totalPages)
  const handleRefundSuccess = async (percentage) => {
    try {
      const result = await refundAmount(
        callBackData.id,
        callBackData.userId,
        callBackData.astrologerId,
        callBackData.amount,
        percentage
      );
      setRefundSuccessful(true);
      setSuccessPercentage(percentage);
    } catch (error) {
      console.error("An error occurred: " + error.message);
    }
    closePopup();
  };

  // const handleSuccessMessageDismiss = () => {
  //   setRefundSuccessful(false);
  //   fetchData(); // Reload data after refund
  // };

  // Handle page change
  // const handlePageChange = (page) => {
  //   setCurrentPage(page);
  // };

  const indexOfLastTransaction = currentPage * rowsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - rowsPerPage;
  //const currentTransactions = filteredData.slice(indexOfFirstTransaction, indexOfLastTransaction);

  return (
    <div className="atsto-container">
      <h1 className="atrologers-heading">Wallet Transactions</h1>
      <div className="tabs">
        <button
          className={`tab-button ${activeTab === "users" ? "active" : ""}`}
          onClick={() => handleTabClick("users")}
        >
          Users
        </button>
        <button
          className={`tab-button ${
            activeTab === "astrologers" ? "active" : ""
          }`}
          onClick={() => handleTabClick("astrologers")}
        >
          Astrologers
        </button>
      </div>

      <div className="filters">
        <button
          className={`filter-button ${
            filter === "chat" ? "active-filter" : ""
          }`}
          onClick={() => setFilter("chat")}
        >
          Chat
        </button>
        <button
          className={`filter-button ${
            filter === "call" ? "active-filter" : ""
          }`}
          onClick={() => setFilter("call")}
        >
          Call
        </button>
      </div>

      {activeTab === "users" && (
        <>
          {filter === "chat" && <WalletChatTransactions />}
          {filter === "call" && <WalletCallTransactions />}
        </>
      )}

      {/* {popupVisible && (
        <WalletPopUp
          message={popupMessage}
          onCancel={closePopup}
          onRefundSuccess={handleRefundSuccess}
          callBackData={callBackData}
        />
      )}

      {refundSuccessful && (
        <RefundSuccessPopup
          successPercentage={successPercentage}
          onClose={handleSuccessMessageDismiss}
        />
      )} */}

      {activeTab === "astrologers" && (
        <>
          {filter === "chat" && <AstrologerChatWalletTransaction />}
          {filter === "call" && <AstrologerCallWalletTransaction />}
        </>
      )}
    </div>
  );
};

export default WalletTransactions;
