import {  Routes, Route,Navigate } from "react-router-dom";
import Dashboard from "./Dashboard/Dashboard"
import Login from "./login"
import ProtectedRoute from "./ProtectedRoute";
import "./App.css"

const App = () => (
  <div className="main-container">
    <div className="routes">
      <Routes>
      <Route exact path="/" element={<Login />} />
        <Route path="/dashboard/*" element={<ProtectedRoute />}>
          <Route path="*" element={<Dashboard />} /> 
        </Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </div>
  </div>
);

export default App;


// const response = await fetch(`https://api.astroping.com/api/astrologer/save-astrologer`, {
//   method: 'POST',
//   headers: {
//       'Content-Type': 'application/json',
//   },
//   body: JSON.stringify({
//       email: "vishalraina@superkid.co.in",
//       password: "qg5WpQ8nEYx9",
//       astrologerName: astrologerName,
//       phone_number: phone_number,
//       category: category,
//       known_languages: known_languages,
//       expertIn: expertIn,
//       experience: experience,
//       profile_photo: profile_photo,
//       cost: "",
//       order_bookings: "",
//   })