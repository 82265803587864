import { useState, useEffect, useCallback } from "react";
import "./User.css";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import UserProfileDetails from "../UserProfileDetails/UserProfileDetails";
import UserChatRooms from "../UserChatRooms/UserChatRooms";
import SaveNotification from "../SaveNotificationContent/savenotificationcontent";
import UserWalletTransactions from "./UserWalletTransactions";
import UserCallWalletTransactions from "./UserCallWalletTransactions";
import UserCallHistory from "./UserCallHistory";
import UserTransactions from "./UserTransactions";
import BASE_URL from "../config";
const UserDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { user } = location.state || {};
  const [transactions, setTransactions] = useState([]);
  const [callHistory, setCallHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeTab, setActiveTab] = useState("Profile");
  const [filter, setFilter] = useState("call");
  const rowsPerPage = 10;
  console.log("UserDetails", user);
  const fetchTransactions = useCallback(
    async (controller) => {
      if (!user) return;
      try {
        const response = await fetch(
          `${BASE_URL}/transactions/get-trans/${user._id}`,
          { signal: controller?.signal }
        );
        const result = await response.json();
        if (result.success) {
          const sortedTransactionlHistory = result.transactions.sort(
            (a, b) => new Date(b.date) - new Date(a.date)
          );
          setTransactions(
            Array.isArray(result.transactions) ? sortedTransactionlHistory : []
          );
        } else {
          setTransactions([]);
        }
      } catch (error) {
        console.error("Error fetching transactions:", error);
        setTransactions([]);
      }
    },
    [user]
  );

  const fetchCallHistory = useCallback(
    async (controller) => {
      if (!user) return;
      try {
        const response = await fetch(
          `${BASE_URL}/user/getUserCall-history/${user._id}`,
          { signal: controller?.signal }
        );
        const result = await response.json();
        if (result.success) {
          const sortedCallHistory = result.callHistory.sort(
            (a, b) => new Date(b.date) - new Date(a.date)
          );
          setCallHistory(
            Array.isArray(result.callHistory) ? sortedCallHistory : []
          );
        } else {
          setCallHistory([]);
        }
      } catch (error) {
        setCallHistory([]);
      }
    },
    [user]
  );

  useEffect(() => {
    const controller = new AbortController();
    if (activeTab === "transactions") {
      fetchTransactions(controller);
    } else if (activeTab === "call-history") {
      fetchCallHistory(controller);
    }
    return () => controller.abort();
  }, [activeTab, fetchTransactions, fetchCallHistory]);

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = (
    activeTab === "transactions" ? transactions : callHistory
  ).slice(indexOfFirstRow, indexOfLastRow);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setCurrentPage(1);
  };

  if (!user) {
    return <p>No user selected.</p>;
  }

  return (
    <div className="user-details-container">
      <button className="user-back-button" onClick={() => navigate(-1)}>
        <IoMdArrowRoundBack size={23} className="back-arrow" />
      </button>
      <div className="user-info">
        <h2 className="user-name">
          <strong className="Username">Username: </strong>
          {user.userName}
        </h2>
        <p className="user-phone">
          <strong className="Username">Phone_number: </strong>
          {user.phone_number}
        </p>
      </div>

      <div className="tabs">
        <button
          className={`tab-button ${activeTab === "Profile" ? "active" : ""}`}
          onClick={() => handleTabClick("Profile")}
        >
          Profile
        </button>
        <button
          className={`tab-button ${
            activeTab === "transactions" ? "active" : ""
          }`}
          onClick={() => handleTabClick("transactions")}
        >
          Transactions
        </button>
        <button
          className={`tab-button ${activeTab === "Wallet" ? "active" : ""}`}
          onClick={() => handleTabClick("Wallet")}
        >
          Wallet
        </button>
        <button
          className={`tab-button ${
            activeTab === "call-history" ? "active" : ""
          }`}
          onClick={() => handleTabClick("call-history")}
        >
          Call History
        </button>
        <button
          className={`tab-button ${
            activeTab === "chat-history" ? "active" : ""
          }`}
          onClick={() => handleTabClick("chat-history")}
        >
          Chat History
        </button>
        <button
          className={`tab-button ${
            activeTab === "saveNotification" ? "active" : ""
          }`}
          onClick={() => handleTabClick("saveNotification")}
        >
          Notification
        </button>
      </div>
      {activeTab === "saveNotification" && <SaveNotification user={user._id} />}
      {activeTab === "Wallet" && (
        <>
          {" "}
          <div className="filters">
            <button
              className={`filter-button ${
                filter === "call" ? "active-filter" : ""
              }`}
              onClick={() => setFilter("call")}
            >
              Call
            </button>
            <button
              className={`filter-button ${
                filter === "chat" ? "active-filter" : ""
              }`}
              onClick={() => setFilter("chat")}
            >
              Chat
            </button>
          </div>
          {filter === "chat" && <UserWalletTransactions user={user._id} />}
          {filter === "call" && <UserCallWalletTransactions user={user._id} />}
        </>
      )}

      {activeTab === "transactions" && (
        <UserTransactions userId={user._id} />
      )}

      {activeTab === "Profile" && <UserProfileDetails user={user._id} />}

      {activeTab === "call-history" && (
       
        <UserCallHistory userId={user._id} />
      )}

      {activeTab === "chat-history" && <UserChatRooms userId={user._id} />}
    </div>
  );
};

export default UserDetails;
