import React, { useState } from 'react';
import WalletPopUpRefund from '../WalletPopUpRefund';
import './index.css';
import { ImCancelCircle } from "react-icons/im";

const WalletPopUp = ({ message, onCancel, onRefundSuccess, callBackData }) => {
    const [showRefundPopup, setShowRefundPopup] = useState(false);

    const handleProceedToRefund = () => {
        setShowRefundPopup(true);
    };

    const handleRefundComplete = async (percentage) => {
        await onRefundSuccess(percentage); 
        setShowRefundPopup(false); 
        onCancel();
    };

    return (
        <>
            {showRefundPopup ? (
                <WalletPopUpRefund 
                    onCancel={() => setShowRefundPopup(false)} 
                    onRefundComplete={handleRefundComplete}
                    callBackData={callBackData} 
                />
            ) : (
                <div className="astro-popup-overlay">
                    <div className="astro-popup-content">
                        <div className='cancel-x-container'>
                            <ImCancelCircle size={29} className='cancel-icon-x' onClick={onCancel} />
                        </div>
                        <p>{message}</p>
                        <div className="popup-buttons">
                            <button className="astro-confirm-button" onClick={handleProceedToRefund}>Yes</button>
                            <button className="astro-cancel-button" onClick={onCancel}>No</button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default WalletPopUp;
