import React, { useState } from 'react';
import './index.css';
import { ImCancelCircle } from "react-icons/im";

const WalletPopUpRefund = ({ onCancel, onRefundComplete, callBackData }) => {
    const [activePercentage, setActivePercentage] = useState(null);
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const handleRefundSelection = (percentage) => {
        setActivePercentage(percentage);
        setErrorMessage(""); 
    };

    const handlePasswordSubmit = async () => {
        const correctPassword = "ab12"; 
        
        if (password === correctPassword) {
            try {
                await onRefundComplete(activePercentage); 
                onCancel(); 
            } catch (error) {
                alert("An error occurred: " + error.message); 
            }
        } else {
            setErrorMessage("Password doesn't match"); 
        }
    };

    return (
        <div className="astro-popup-overlay">
            <div className="astro-popup-content">
                <div className='cancel-x-container'>
                    <ImCancelCircle size={29} className='cancel-icon-x' onClick={onCancel} />
                </div>
                <p>Select Refund Percentage</p>
                <div className="percentage-container">
                    <button className={`filter-button ${activePercentage === 50 ? 'active-filter' : ''}`} onClick={() => handleRefundSelection(50)}>50%</button>
                    <button className={`filter-button ${activePercentage === 75 ? 'active-filter' : ''}`} onClick={() => handleRefundSelection(75)}>75%</button>
                    <button className={`filter-button ${activePercentage === 100 ? 'active-filter' : ''}`} onClick={() => handleRefundSelection(100)}>100%</button>
                </div>
                {activePercentage && (
                    <>
                        <p>Refund {activePercentage}%</p>
                        <input
                            type="password"
                            className='input-popup-password'
                            placeholder="Enter Password - ab12"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <button className="astro-confirm-button" onClick={handlePasswordSubmit}>Submit</button>
                        {errorMessage && <p className="error-message">{errorMessage}</p>}
                    </>
                )}
                <button className="astro-cancel-button close" onClick={onCancel}>Close</button>
            </div>
        </div>
    );
};

export default WalletPopUpRefund;
