import React, { useState } from 'react';
import './ChatBubble.css';

const ChatBubble = ({ message, user }) => {
  const isSender = message.senderName === user.userName;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedImage('');
  };

  const renderStatusTicks = () => {
    if (!message.delivered) {
      return <span className="status-tick">✔</span>; // Single tick for sent but not delivered
    }
    if (message.delivered && !message.read) {
      return <span className="status-tick">✔✔</span>; // Double tick for delivered
    }
    if (message.read) {
      return <span className="status-tick read-status">✔✔</span>; // Double tick in blue for read
    }
  };

  return (
    <>
      {message.date !== "" && (
        <div className="date-bubble">
          <p className="message-text-date">{message.date}</p>
        </div>
      )}

      <div className={`chat-bubble ${isSender ? 'sender' : 'receiver'}`}>
        {message.type === "image" ? (
          <img 
            src={message.message} 
            alt="Message Image" 
            className="chat-image" 
            onClick={() => handleImageClick(message.message)}
          />
        ) : (
          <p className="message-text">{message.message}</p>
        )}
        <div className="message-meta">
          <p className="message-time">{new Date(message.createdAt).toLocaleTimeString('en-IN')}</p>
        </div>
        <div className="message-meta">
          <p className="message-time">{new Date(message.updatedAt).toLocaleTimeString('en-IN')}</p>
          {renderStatusTicks()}
        </div>
      </div>
      
      {isModalOpen && (
        <div className="modal" onClick={handleCloseModal}>
          <div className="modal-content">
            <img src={selectedImage} alt="Selected" className="modal-image" />
          </div>
        </div>
      )}
    </>
  );
};

export default ChatBubble;
