import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../config";
const SessionEndChat = () => {
  const [currentLiveChatPage, setCurrentChatPage] = useState(1);
  const [totalLiveChatPages, setTotalLivePages] = useState(null);
  const [liveChatData, setLiveChatData] = useState([]);
  const [totalNumberOfChats, setTotalNumberOfChats] = useState(0);
  const [selectedDate, setSelectedDate] = useState("");
  const [totalPaidChats, setTotalPaidChats] = useState(0);
  const [totalFreeChats, setTotalFreeChats] = useState(0);
  const navigate = useNavigate();

  // Fetch chats when no date is selected
  const getSessionEndChats = async (signal) => {
    const liveChatApiUrl = `${BASE_URL}/astrologer/get-Session-end-chats-data?page=${currentLiveChatPage}`;
    const response = await fetch(liveChatApiUrl, { signal });
    console.log("session end chats", response);
    const data = await response.json();
    const { totalChats, totalPages, chats } = data;
    setTotalLivePages(totalPages);
    setLiveChatData(chats);
    setTotalNumberOfChats(totalChats);
    setTotalFreeChats(data.totalFreeChats);
    setTotalPaidChats(data.totalPaidChats);
  };

  // Fetch chats for the selected date
  const getSessionEndChatsByDate = async (signal) => {
    const apiUrl = `${BASE_URL}/astrologer/get-session-end-chats-by-date?date=${selectedDate}&page=${currentLiveChatPage}`;
    const response = await fetch(apiUrl, { signal });
    const data = await response.json();
    if (data.success) {
      const { totalChats, totalPages, chats } = data;
      setTotalLivePages(totalPages);
      setLiveChatData(chats);
      setTotalNumberOfChats(totalChats);
      setTotalFreeChats(data.totalFreeChats);
      setTotalPaidChats(data.totalPaidChats);
    }
  };

  const handlePageChange = (direction) => {
    if (direction === "prev" && currentLiveChatPage > 1) {
      setCurrentChatPage((prev) => prev - 1);
    } else if (
      direction === "next" &&
      currentLiveChatPage < totalLiveChatPages
    ) {
      setCurrentChatPage((prev) => prev + 1);
    }
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
    setCurrentChatPage(1); // Reset to the first page
  };

  const handleClearDate = () => {
    setSelectedDate("");
    setCurrentChatPage(1); // Reset to the first page
  };

  const SessionEndChatTable = () => (
    <>
      <div className="astro-table-container pymnts-container">
        <table className="astro-table">
          <thead className="astro-he">
            <tr className="astro-bg">
              <th className="he">User Name</th>
              <th className="he">Astrologer Name</th>
              <th className="he">User PhoneNumber</th>
              <th className="he">Astrologer PhoneNumber</th>
              <th className="he">Cost/min</th>
              <th className="he">Time-Duration</th>
              <th className="he">Status</th>
              <th className="he">Date Time</th>
              <th className="he">WebSocketIssueCount</th>
            </tr>
          </thead>
          <tbody>
            {liveChatData?.map((chat) => (
              <tr className="astro-rows" key={chat._id}>
                <td
                  className="he"
                  onClick={() =>
                    navigate(`/dashboard/user/${chat?.userId}`, {
                      state: {
                        user: {
                          _id: chat?.userId,
                          userName: chat?.userName,
                          phone_number: chat?.from,
                        },
                      },
                    })
                  }
                  style={{ color: "blue", cursor: "pointer" }}
                >
                  {chat?.userName || "NA"}
                </td>
                <td
                  className="he"
                  onClick={() =>
                    navigate(
                      `/dashboard/astrologer/${chat?.astrologerId?._id}`,
                      {
                        state: { astrologer: chat?.astrologerId },
                      }
                    )
                  }
                  style={{ color: "blue", cursor: "pointer" }}
                >
                  {chat?.astrologerId?.astrologerName || "NA"}
                </td>
                <td className="he">{chat.from || "NA"}</td>
                <td className="he">{chat.to || "NA"}</td>
                <td className="he">{chat?.cost || "NA"}</td>
                <td className="he">{chat?.time_duration ? `${chat.time_duration} min` : "NA"}</td>
                <td className="he">{chat?.status || "NA"}</td>
                <td className="he">{chat?.updatedAt}</td>
                <td className="he">User: {chat?.websocketIssueCount?.user}, Astrologer: {chat?.websocketIssueCount?.astrologer}</td>

              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination">
        <button
          className="pagination-button"
          onClick={() => handlePageChange("prev")}
          disabled={currentLiveChatPage === 1}
        >
          Prev
        </button>
        <span className="pagination-info">
          Page {currentLiveChatPage} of {totalLiveChatPages}
        </span>
        <button
          className="pagination-button"
          onClick={() => handlePageChange("next")}
          disabled={currentLiveChatPage === totalLiveChatPages}
        >
          Next
        </button>
      </div>
    </>
  );

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (selectedDate) {
      getSessionEndChatsByDate(signal);
    } else {
      getSessionEndChats(signal);
    }
    return () => {
      controller.abort();
    };
  }, [currentLiveChatPage, selectedDate]);

  return (
    <div>
      <div className="date-picker-container">
        <h3 className="date-picker-heading">Select a Date</h3>
        <input
          type="date"
          value={selectedDate}
          onChange={handleDateChange}
          className="date-input"
        />
        <button className="date-clear" onClick={handleClearDate}>
          Clear
        </button>
        <div className="count-of-chats">
          <h3>{`Total Chats: ${totalNumberOfChats}`}</h3>
          <h3>{`Total Free Chats: ${totalFreeChats}`}</h3> 
          <h3>{`Total Paid Chats: ${totalPaidChats}`}</h3>
        </div>
        {selectedDate && (
          <p className="selected-date-text">{`Selected date: ${selectedDate}`}</p>
        )}
      </div>
      {totalNumberOfChats !== 0
        ? SessionEndChatTable()
        : "Currently No Session Ended Chats are there !"}
    </div>
  );
};

export default SessionEndChat;
