import { useState, useEffect } from "react";
import Loader from "../Loader";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../config";
const TransferredChats = () => {
  const [transferredCurrentPage, setTransferredCurrentPage] = useState(1);
  const [transferredData, setTransferredData] = useState([]);
  const [totalTransferred, setTotalTransferred] = useState(null);
  const [totalTransferredCount, setTotalTransferredCount] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const navigate = useNavigate();

  // URLs for the APIs
  const TRANSFER_BASE_URL = `${BASE_URL}/astrologer/transfer-chats`;
  const SEARCH_URL = `${BASE_URL}/astrologer/search-transfer-chats`;
  const DATE_URL = `${BASE_URL}/astrologer/get-transfer-chats-by-date`;

  // Debouncing for search input
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  // Fetch transferred chats with pagination and filters
  const getTransferredChats = async (controller) => {
    try {
      let apiUrl;

      // Use the date API if a date is selected
      if (selectedDate) {
        apiUrl = `${DATE_URL}?date=${selectedDate}&page=${transferredCurrentPage}`;
      } else if (debouncedSearchQuery) {
        // Use the search API if there's a search query
        apiUrl = `${SEARCH_URL}?query=${encodeURIComponent(
          debouncedSearchQuery
        )}&page=${transferredCurrentPage}`;
      } else {
        // Otherwise, use the default API
        apiUrl = `${TRANSFER_BASE_URL}?page=${transferredCurrentPage}`;
      }

      setIsLoading(true);
      const response = await fetch(apiUrl, { signal: controller?.signal });
      const data = await response.json();
      const { transfers, totalPages, totalCount } = data;

      if (response.ok) {
        setTransferredData(transfers);
        setTotalTransferred(totalPages);
        setTotalTransferredCount(totalCount);
      } else {
        console.error("Failed to fetch transferred chats:", data.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching transferred chats:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();

    getTransferredChats(controller);

    return () => {
      controller.abort();
    };
  }, [transferredCurrentPage, debouncedSearchQuery, selectedDate]);

  // Pagination handler (prev/next page)
  const handleTransferredChatsPage = (direction) => {
    if (direction === "prev" && transferredCurrentPage > 1) {
      setTransferredCurrentPage(transferredCurrentPage - 1);
    } else if (
      direction === "next" &&
      transferredCurrentPage < totalTransferred
    ) {
      setTransferredCurrentPage(transferredCurrentPage + 1);
    }
  };

  // Table to display the transferred chats data
  const transferChatDataTable = () => (
    <>
      <div className="astro-table-container pymnts-container">
        <table className="astro-table">
          <thead className="astro-he">
            <tr className="astro-bg">
              <th className="he">User Name</th>
              <th className="he">Astrologer Name</th>
              <th className="he">Astrologer Phone Number</th>
              <th className="he">Time</th>
              <th className="he">Date</th>
            </tr>
          </thead>
          <tbody>
            {transferredData?.map((chat) => {
              const dateObj = new Date(chat.date);
              const isValidDate = !isNaN(dateObj.getTime());

              const formattedDate = isValidDate
                ? dateObj.toLocaleDateString("en-IN")
                : "NA";
              const formattedTime = isValidDate
                ? dateObj.toLocaleTimeString("en-IN", {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  })
                : "NA";
              return (
                <tr className="astro-rows" key={chat._id}>
                  <td
                    className="he"
                    onClick={() =>
                      navigate(`/dashboard/user/${chat?.userId}`, {
                        state: {
                          user: {
                            _id: chat?.userId,
                            userName: chat?.userName,
                            phone_number: chat?.from,
                          },
                        },
                      })
                    }
                    style={{ color: "blue", cursor: "pointer" }}
                  >
                    {chat?.userName || "NA"}
                  </td>
                  <td
                    className="he"
                    onClick={() =>
                      navigate(
                        `/dashboard/astrologer/${chat?.astrologerId?._id}`,
                        {
                          state: { astrologer: chat?.astrologerId },
                        }
                      )
                    }
                    style={{ color: "blue", cursor: "pointer" }}
                  >
                    {chat?.astrologerId?.astrologerName || "NA"}
                  </td>
                  <td className="he">{chat?.Astrologer_phone_number}</td>
                  <td className="he">{formattedTime}</td>
                  <td className="he">{formattedDate}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="pagination">
        <button
          className="pagination-button"
          onClick={() => handleTransferredChatsPage("prev")}
          disabled={transferredCurrentPage === 1}
        >
          Prev
        </button>
        <span className="pagination-info">
          Page {transferredCurrentPage} of {totalTransferred}
        </span>
        <button
          className="pagination-button"
          onClick={() => handleTransferredChatsPage("next")}
          disabled={transferredCurrentPage === totalTransferred}
        >
          Next
        </button>
      </div>
    </>
  );

  return (
    <div>
      <div>
        <input
          type="search"
          placeholder="Search by Username, Astrologer Name, or Phone Number"
          className="search-bar"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button className="btn" onClick={() => getTransferredChats()}>
          {isLoading === true ? "Loading..." : "Reload"}
        </button>
      </div>
      <div className="date-picker-container">
        <h3 className="date-picker-heading">Select a Date</h3>
        <input
          type="date"
          value={selectedDate}
          onChange={(event) => {
            setSelectedDate(event.target.value);
            setTransferredCurrentPage(1);
          }}
          className="date-input"
        />
        <button
          className="date-clear"
          onClick={() => {
            setSelectedDate("");
            setTransferredCurrentPage(1);
          }}
        >
          Clear
        </button>
        {selectedDate && (
          <p className="selected-date-text">{`Selected date: ${selectedDate}`}</p>
        )}
        <h3>{`Total Transferred: ${totalTransferredCount}`}</h3>
      </div>
      {isLoading === true ? <Loader /> : transferChatDataTable()}
    </div>
  );
};

export default TransferredChats;
