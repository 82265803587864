import React, { useState, useEffect } from "react";
import Loader from "../Loader";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import BASE_URL from "../config";

const CallStats = ({ astrologer }) => {
  const navigate = useNavigate();
  const [stats, setStats] = useState(null);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const COLORS = ["#0088FE", "#FF8042"];

  const months = [
    { name: "January", value: "1" },
    { name: "February", value: "2" },
    { name: "March", value: "3" },
    { name: "April", value: "4" },
    { name: "May", value: "5" },
    { name: "June", value: "6" },
    { name: "July", value: "7" },
    { name: "August", value: "8" },
    { name: "September", value: "9" },
    { name: "October", value: "10" },
    { name: "November", value: "11" },
    { name: "December", value: "12" },
  ];

  const fetchCallStats = async (signal) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${BASE_URL}/astrologer/astrologer/call-stats?year=${year}&month=${month}`,
        { astrologerId: astrologer._id },
        { signal }
      );

      if (response.status === 200) {
        const data = response.data;
        console.log("Call stats data", data);
        setStats(data);
        updateChartData(data);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching call stats:", error);
      setIsLoading(false);
    }
  };

  const updateChartData = (data) => {
    if (data) {
      const { completedCalls, failedCalls } = data;
      setChartData([
        { name: "Completed", value: completedCalls },
        { name: "Failed", value: failedCalls },
      ]);
    } else {
      setChartData([]);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    // Fetch data only if both year and month are selected
    if (year && month) {
      fetchCallStats(signal);
    }

    return () => controller.abort();
  }, [year, month]);

  return (
    <>
      <div>
        <button style={styles.backButton} onClick={() => navigate(-1)}>
          Back
        </button>
      </div>
      <div style={styles.container}>
        <h1>Call Statistics:</h1>
        
        <div style={styles.controls}>
          <div>
            <label htmlFor="year">Year:</label>
            <select
              id="year"
              value={year}
              onChange={(e) => setYear(e.target.value)}
              style={styles.select}
            >
              {[2023, 2024, 2025].map((y) => (
                <option key={y} value={y}>
                  {y}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label htmlFor="month">Month:</label>
            <select
              id="month"
              value={month}
              onChange={(e) => setMonth(e.target.value)}
              style={styles.select}
            >
              <option value="">Select Month</option>
              {months.map((m) => (
                <option key={m.value} value={m.value}>
                  {m.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div style={styles.statsSection}>
          <h3>Call Counts:</h3>
          {isLoading ? (
            <Loader />
          ) : stats ? (
            stats.totalCalls > 0 ? (
              <ul style={styles.statsList}>
                <li>Total Calls: {stats.totalCalls}</li>
                <br />
                <li style={{ color: "green" }}>
                  Completed Calls: {stats.completedCalls} ({stats.completedPercentage})
                </li>
                <br />
                <li style={{ color: "red" }}>
                  Failed Calls: {stats.failedCalls} ({stats.failedPercentage})
                </li>
              </ul>
            ) : (
              <p style={styles.noData}>No data available for the selected year and month.</p>
            )
          ) : (
            <p style={styles.noData}>No data available for the selected year and month.</p>
          )}
        </div>
        <div style={styles.chartContainer}>
          {chartData.length > 0 ? (
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={chartData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={100}
                  fill="#8884d8"
                  label
                >
                  {chartData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          ) : (
            <p style={styles.noData}>
              No data available for the selected year and month.
            </p>
          )}
        </div>
      </div>
    </>
  );
};

const styles = {
  container: {
    padding: "20px",
    borderRadius: "10px",
    backgroundColor: "#f4f4f4",
    margin: "20px auto",
    width: "80%",  // Increase width to accommodate both sections
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    display: "flex",  // Use flexbox for side-by-side layout
    justifyContent: "space-between", // Distribute space between the sections
    flexWrap: "wrap",  // Allow sections to wrap on smaller screens
  },
  header: {
    marginBottom: "20px",
  },
  controls: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  select: {
    padding: "8px 12px",
    fontSize: "16px",
    borderRadius: "5px",
    border: "1px solid #ccc",
  },
  statsSection: {
    marginBottom: "20px",
    width: "45%",  // Make stats section take up 45% of the container width
    paddingRight: "20px", // Add some space between sections
  },
  statsList: {
    listStyle: "none",
    padding: 0,
    fontSize: "16px",
  },
  noData: {
    color: "red",
    fontSize: "18px",
    fontStyle: "italic",
  },
  chartContainer: {
    marginTop: "20px",
    width: "45%",  // Make chart section take up 45% of the container width
  },
  backButton: {
    backgroundColor: "#0088FE",
    color: "white",
    border: "none",
    padding: "10px 20px",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "16px",
  },
};


export default CallStats;
