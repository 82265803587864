import React, { useState, useEffect } from "react";
import Loader from "../Loader";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import BASE_URL from "../config";

const AstrologerStats = ({ astrologer }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [stats, setStats] = useState(null);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const COLORS = ["#0088FE", "#FF8042"];

  const months = [
    { name: "January", value: "1" },
    { name: "February", value: "2" },
    { name: "March", value: "3" },
    { name: "April", value: "4" },
    { name: "May", value: "5" },
    { name: "June", value: "6" },
    { name: "July", value: "7" },
    { name: "August", value: "8" },
    { name: "September", value: "9" },
    { name: "October", value: "10" },
    { name: "November", value: "11" },
    { name: "December", value: "12" },
  ];

  const fetchStats = async (signal) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${BASE_URL}/astrologer/astrologer/stats?year=${year}&month=${month}`,
        {
          astrologerId: astrologer._id,
        },
        { signal }
      );
      if (response.data.success) {
        setStats(response.data.data);
        updateChartData(response.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching astrologer stats:", error);
      setIsLoading(false);
    }
  };

  const updateChartData = (data) => {
    if (data && data[year] && data[year][month]) {
      const { completed, failed } = data[year][month];
      setChartData([
        { name: "Completed", value: completed },
        { name: "Failed", value: failed },
      ]);
    } else {
      setChartData([]);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    fetchStats(signal);

    return () => controller.abort();
  }, [year, month]);

  return (
    <>
      <div>
        <button style={styles.backButton} onClick={() => navigate(-1)}>
          Back
        </button>
      </div>
      <div style={styles.container}>
        <h1>Chats Statistics:</h1>
        <div style={styles.controls}>
          <div>
            <label htmlFor="year">Year:</label>
            <select
              id="year"
              value={year}
              onChange={(e) => setYear(e.target.value)}
              style={styles.select}
            >
              {[2023, 2024, 2025].map((y) => (
                <option key={y} value={y}>
                  {y}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label htmlFor="month">Month:</label>
            <select
              id="month"
              value={month}
              onChange={(e) => setMonth(e.target.value)}
              style={styles.select}
            >
              <option value="">All</option>
              {months.map((m) => (
                <option key={m.value} value={m.value}>
                  {m.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div style={styles.statsChartContainer}>
          <div style={styles.statsSection}>
            <h3>Chat Counts:</h3>
            {isLoading ? (
              <Loader />
            ) : stats && stats[year] && stats[year][month] ? (
              <ul style={styles.statsList}>
                <li>
                  Total Chats:{" "}
                  {stats[year][month].completed + stats[year][month].failed}
                  <ul style={{ listStyleType: "none", padding: "0" }}>
                    <li>
                      Total Paid Chats: {stats[year][month].totalPaidChats} (
                      {stats[year][month].totalPaidChatsPercentage}%)
                    </li>
                    <li>
                      Total Free Chats: {stats[year][month].totalFreeChats} (
                      {stats[year][month].totalFreeChatsPercentage}%)
                    </li>
                  </ul>
                </li>
                <br />
                {/* Completed Chats */}
                <li style={{ color: "green" }}>
                  Completed Chats: {stats[year][month].completed} (
                  {stats[year][month].completedPercentage}%)
                </li>
                <li style={{ paddingLeft: "20px" }}>
                  ---- Paid Chats: {stats[year][month].completedPaidChats} (
                  {stats[year][month].completedPaidChatsPercentage}%)
                </li>
                <li style={{ paddingLeft: "20px" }}>
                  ---- Free Chats: {stats[year][month].completedFreeChats} (
                  {stats[year][month].completedFreeChatsPercentage}%)
                </li>

                <br />

                {/* Failed Chats */}
                <li style={{ color: "red" }}>
                  Failed Chats: {stats[year][month].failed} (
                  {stats[year][month].failedPercentage}%)
                </li>
                <li style={{ paddingLeft: "20px" }}>
                  ---- Paid Chats: {stats[year][month].failedPaidChats} (
                  {stats[year][month].failedPaidChatsPercentage}%)
                </li>
                <li style={{ paddingLeft: "20px" }}>
                  ---- Free Chats: {stats[year][month].failedFreeChats} (
                  {stats[year][month].failedFreeChatsPercentage}%)
                </li>
                <br />
                {/* Picked Chats */}
                <li style={{ color: "blue" }}>
                  OrdersPicked :{stats[year][month].pickedChats}
                </li>
                <li style={{ paddingLeft: "20px" }}>
                  ---- Paid pickedChats: {stats[year][month].paidPickedChats} (
                  {stats[year][month].paidPickedChatsPercentage}%)
                </li>
                <li style={{ paddingLeft: "20px" }}>
                  ---- Free pickedChats: {stats[year][month].freePickedChats} (
                  {stats[year][month].freePickedChatsPercentage}%)
                </li>
              </ul>
            ) : (
              <p style={styles.noData}>
                No data available for the selected year and month.
              </p>
            )}
          </div>

          <div style={styles.chartContainer}>
            {chartData.length > 0 ? (
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={chartData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={100}
                    fill="#8884d8"
                    label
                  >
                    {chartData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            ) : (
              <p style={styles.noData}>
                No data available for the selected year and month.
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AstrologerStats;

const styles = {
  container: {
    maxWidth: "900px",
    margin: "0 auto",
    padding: "20px",
    border: "1px solid #ddd",
    borderRadius: "10px",
    boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
    backgroundColor: "#f9f9f9",
  },
  controls: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  select: {
    padding: "5px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    fontSize: "14px",
  },
  statsChartContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  statsSection: {
    width: "48%",
    marginBottom: "20px",
    padding: "10px",
    backgroundColor: "#fff",
    borderRadius: "10px",
    boxShadow: "0px 2px 5px rgba(0,0,0,0.1)",
  },
  statsList: {
    listStyle: "none",
    padding: 0,
  },
  chartContainer: {
    width: "48%",
    marginBottom: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "300px",
  },
  noData: {
    textAlign: "center",
    color: "#888",
  },
  backButton: {
    borderWidth: "0px",
    backgroundColor: "#4248f5",
    padding: "15px",
    borderRadius: "5px",
    color: "#fff",
    cursor: "pointer",
    marginTop: "10px",
  },
};
