import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import ListItemText from "@mui/material/ListItemText";
import OutlinedInput from "@mui/material/OutlinedInput";
import BASE_URL from "../config";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 5,
};
export default function BasicModal({ astrologer, getAstrologerTransaction }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const controller = new AbortController();
  const signal = controller?.signal;
  const expertOptions = ["All", "Astrology", "Horoscope", "Numerology"];
  const languageOptions = ["Telugu", "English", "Hindi", "Others"];

  const [formData, setFormData] = React.useState({
    astrologerName: astrologer.astrologerName || "",
    known_languages: Array.isArray(astrologer.known_languages)
      ? astrologer.known_languages
      : [],
    category: Array.isArray(astrologer.category)
      ? astrologer.category
      : astrologer.category
      ? [astrologer.category]
      : [],
    expertIn: Array.isArray(astrologer.expertIn) ? astrologer.expertIn : [],
    experience: astrologer.experience || "",
    phone_number: astrologer.phone_number || "",
    cost: astrologer.cost || "",
    profile_photo: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCategoryChange = (e) => {
    const value = e.target.value;
    setFormData((prevState) => ({
      ...prevState,
      category: value.split(",").map((cat) => cat.trim()),
    }));
  };

  const handleLanguages = (event) => {
    const { value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      known_languages: typeof value === "string" ? value.split(",") : value,
    }));
  };

  const handleExpertChange = (event) => {
    const { value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      expertIn: typeof value === "string" ? value.split(",") : value,
    }));
  };

  const handleFileChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      profile_photo: e.target.files[0],
    }));
  };

  const handleSubmit = async () => {
    const newFormData = new FormData();
    newFormData.append("astrologerId", astrologer._id);
    newFormData.append("astrologerName", formData.astrologerName);
    newFormData.append("phone_number", formData.phone_number);
    newFormData.append("experience", formData.experience);
    newFormData.append("cost", formData.cost);
    newFormData.append("category", formData.category.join(","));
    newFormData.append("known_languages", formData.known_languages.join(","));
    newFormData.append("expertIn", formData.expertIn.join(","));

    if (formData.profile_photo) {
      newFormData.append("profile_photo", formData.profile_photo);
    }

    try {
      const updateApi = `${BASE_URL}/astrologer/updateAstrologerProfile`;
      const response = await fetch(updateApi, {
        method: "POST",
        body: newFormData,
        headers: {
          signal: signal,
        },
      });
      const data = await response.json();
      if (response.ok) {
        getAstrologerTransaction();
        handleClose();
      } else {
        console.error("Error updating astrologer profile:", data);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  React.useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  return (
    <div>
      <Button onClick={handleOpen} sx={{ background: "none", padding: 0 }}>
        Edit Details
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Fill the Details
          </Typography>
          <Box
            component="form"
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: 2,
              "& .MuiTextField-root": { width: "100%" },
              "& .MuiFormControl-root": { width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="name"
              label="Name"
              name="astrologerName"
              value={formData.astrologerName}
              onChange={handleChange}
              type="text"
              placeholder="e.g., Arya"
            />

            <TextField
              id="phone_number"
              label="Phone Number"
              name="phone_number"
              value={formData.phone_number}
              onChange={handleChange}
              type="tel"
              placeholder="e.g., +91 00000"
            />

            <TextField
              id="category"
              label="Category"
              name="category"
              value={formData.category.join(", ")}
              onChange={handleCategoryChange}
              placeholder="e.g., Love, Marriage"
            />

            <FormControl fullWidth>
              <InputLabel shrink id="language-label">
                Known Languages
              </InputLabel>
              <Select
                labelId="language-label"
                id="language"
                name="known_languages"
                multiple
                value={formData.known_languages}
                onChange={handleLanguages}
                input={<OutlinedInput notched label="Languages" />}
                renderValue={(selected) => selected.join(", ")}
              >
                {languageOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    <Checkbox checked={formData.known_languages.includes(option)} />
                    <ListItemText primary={option} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel shrink id="expert-label">
                Expert In
              </InputLabel>
              <Select
                labelId="expert-label"
                id="expert"
                name="expertIn"
                multiple
                value={formData.expertIn}
                onChange={handleExpertChange}
                input={<OutlinedInput notched label="Expert In" />}
                renderValue={(selected) => selected.join(", ")}
              >
                {expertOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    <Checkbox checked={formData.expertIn.includes(option)} />
                    <ListItemText primary={option} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              id="experience"
              label="Experience"
              name="experience"
              value={formData.experience}
              onChange={handleChange}
              type="text"
              placeholder="e.g., 5"
            />

            <TextField
              id="cost"
              label="Cost per Minute"
              name="cost"
              value={formData.cost}
              onChange={handleChange}
              type="number"
              placeholder="e.g., 50 Years"
            />
            <div>
                UpdatePhoto
                <input
                  accept="image/*"
                  type="file"
                  onChange={handleFileChange}

                />
            </div>
          </Box>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 15,
            }}
          >
            <Button variant="outlined" color="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Save Changes
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
