import React, { useState, useEffect } from "react";
import "./savenotificationcontent.css";
import BASE_URL from "../config";
const SaveNotification = ({ user }) => {
  const [titles, setTitles] = useState([""]);
  const [messages, setMessages] = useState([""]);
  const [titleErrors, setTitleErrors] = useState([]);
  const [messageErrors, setMessageErrors] = useState([]);
  const [userType, setUserType] = useState("all");
  const [isLoading, setIsLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [statusMessage, setStatusMessage] = useState("");

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [currentNotification, setCurrentNotification] = useState(null);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller?.signal;

    const fetchNotifications = async () => {
      try {
        const response = await fetch(
          `${BASE_URL}/notification/get-notifications?userId=${user}`,
          { signal }
        );
        console.log("Response status:", response.status);
        const data = await response.json();
        console.log("Response data:", data);

        if (data.success && Array.isArray(data.data)) {
          setNotifications(data.data);
        } else {
          setNotifications([]);
          setStatusMessage("No notifications found.");
        }
      } catch (error) {
        console.error("Error fetching notifications:", error);
        setNotifications([]);
        setStatusMessage("An error occurred while fetching notifications.");
      }
    };

    fetchNotifications();

    return () => {
      controller.abort();
    };
  }, [user, userType]);

  const validateInput = (index, field, value) => {
    if (field === "title") {
      const errors = [...titleErrors];
      errors[index] =
        value.length > 50 ? "Title must not exceed 50 characters." : "";
      setTitleErrors(errors);
    } else if (field === "message") {
      const errors = [...messageErrors];
      errors[index] =
        value.length > 200 ? "Message must not exceed 200 characters." : "";
      setMessageErrors(errors);
    }
  };

  const handleInputChange = (index, field, value) => {
    if (field === "title") {
      const updatedTitles = [...titles];
      updatedTitles[index] = value;
      setTitles(updatedTitles);
      validateInput(index, "title", value);
    } else if (field === "message") {
      const updatedMessages = [...messages];
      updatedMessages[index] = value;
      setMessages(updatedMessages);
      validateInput(index, "message", value);
    }
  };

  const handleAddNotification = () => {
    setTitles([...titles, ""]);
    setMessages([...messages, ""]);
    setTitleErrors([...titleErrors, ""]);
    setMessageErrors([...messageErrors, ""]);
  };

  const handleRemoveNotification = (index) => {
    const updatedTitles = titles.filter((_, i) => i !== index);
    const updatedMessages = messages.filter((_, i) => i !== index);
    const updatedTitleErrors = titleErrors.filter((_, i) => i !== index);
    const updatedMessageErrors = messageErrors.filter((_, i) => i !== index);
    setTitles(updatedTitles);
    setMessages(updatedMessages);
    setTitleErrors(updatedTitleErrors);
    setMessageErrors(updatedMessageErrors);
  };

  const handleSaveNotification = async () => {
    if (
      titles.every((t) => t) &&
      messages.every((m) => m) &&
      titleErrors.every((e) => !e) &&
      messageErrors.every((e) => !e)
    ) {
      setIsLoading(true);
      const controller = new AbortController();
      const signal = controller?.signal;
      const requestPayload = {
        titles,
        messages,
        userType,
        userId: user,
      };
      try {
        const response = await fetch(
          `${BASE_URL}/notification/save-notificationContent`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(requestPayload),
            signal,
          }
        );

        setIsLoading(false);
        if (response.ok) {
          setStatusMessage("Notifications saved successfully!");
          setTitles([""]);
          setMessages([""]);
          setUserType("all");
          const savedNotifications = await response.json();
          setNotifications(savedNotifications);
        } else {
          const errorResponse = await response.json();
          console.error("Failed to save notifications:", errorResponse);
          setStatusMessage(`Error: ${errorResponse.message}`);
        }
      } catch (error) {
        setIsLoading(false);
        console.error("Error sending notifications:", error);
        setStatusMessage("An error occurred. Please try again.");
      }
      return () => {
        controller.abort();
      };
    } else {
      setStatusMessage(
        "Please fill in all Title and Message fields and fix errors."
      );
    }
  };

  const handleDeleteNotification = async (id) => {
    const controller = new AbortController();
    const signal = controller?.signal;

    try {
      const response = await fetch(
        `${BASE_URL}/notification/delete-notification?id=${id}`,
        {
          method: "DELETE",
          signal,
        }
      );

      if (response.ok) {
        const result = await response.json();
        if (result.success) {
          setStatusMessage(result.message);
          setNotifications((prevNotifications) =>
            prevNotifications.filter((notification) => notification._id !== id)
          );
        } else {
          setStatusMessage(result.message);
        }
      } else {
        const errorResponse = await response.json();
        console.error("Failed to delete notification:", errorResponse);
        setStatusMessage(`Error: ${errorResponse.message}`);
      }
    } catch (error) {
      console.error("Error deleting notification:", error);
      setStatusMessage("An error occurred while deleting the notification.");
    }
  };

  const handleEditNotification = (notification) => {
    setCurrentNotification({
      ...notification,
    });
    setIsPopupOpen(true);
  };

  const handleUpdateNotification = async () => {
    if (
      !currentNotification.title ||
      !currentNotification.content ||
      !currentNotification._id
    ) {
      setStatusMessage(
        "Please fill in all fields and ensure the notification has an ID."
      );
      return;
    }

    const abortController = new AbortController();
    try {
      const response = await fetch(
        `${BASE_URL}/notification/update-notification`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            id: currentNotification._id, // Correctly pass the ID
            title: currentNotification.title,
            content: currentNotification.content,
            type: currentNotification.type,
          }),
          signal: abortController?.signal,
        }
      );

      if (response.ok) {
        const updatedNotification = await response.json();
        setNotifications((prevNotifications) =>
          prevNotifications.map((n) =>
            n._id === updatedNotification.data._id
              ? updatedNotification.data
              : n
          )
        );
        setStatusMessage("Notification updated successfully!");
        setIsPopupOpen(false);
        setCurrentNotification(null); // Reset the state
      } else {
        const errorResponse = await response.json();
        console.error("Failed to update notification:", errorResponse);
        setStatusMessage(`Error: ${errorResponse.message}`);
      }
    } catch (error) {
      console.error("Error updating notification:", error);
      setStatusMessage("An error occurred. Please try again.");
    }
  };

  return (
    <div className="notification-main-wrapper">
      <div className="save-notification-section">
        <h2 className="save-notification-header">Save Notifications</h2>

        {statusMessage && <p className="status-message">{statusMessage}</p>}

        {titles.map((title, index) => (
          <div key={index} className="notification-form-group">
            <label className="notification-label">Title {index + 1}:</label>
            <input
              type="text"
              value={title}
              onChange={(e) =>
                handleInputChange(index, "title", e.target.value)
              }
              placeholder="Enter notification title"
              className="notification-input"
            />
            {titleErrors[index] && (
              <span className="error-message">{titleErrors[index]}</span>
            )}

            <label className="notification-label">Message {index + 1}:</label>
            <textarea
              value={messages[index]}
              onChange={(e) =>
                handleInputChange(index, "message", e.target.value)
              }
              placeholder="Enter notification message"
              className="notification-textarea"
            />
            {messageErrors[index] && (
              <span className="error-message">{messageErrors[index]}</span>
            )}

            {index > 0 && (
              <button
                onClick={() => handleRemoveNotification(index)}
                className="remove-notification-button"
              >
                Remove
              </button>
            )}
          </div>
        ))}
        <button
          onClick={handleAddNotification}
          className="add-notification-button"
        >
          Add Notification
        </button>
        <div className="user-type-selector">
          <label className="notification-label">User Type:</label>
          <select
            value={userType}
            onChange={(e) => setUserType(e.target.value)}
            className="notification-dropdown"
          >
            <option value="all">All</option>
            <option value="marriage">Marriage</option>
            <option value="love">Love</option>
            <option value="career">Career</option>
            <option value="education">Education</option>
            <option value="future">Future</option>
          </select>
        </div>

        <button
          onClick={handleSaveNotification}
          className="save-notification-button"
          disabled={isLoading}
        >
          {isLoading ? "Saving..." : "Save Notifications"}
        </button>
      </div>

      <div className="notifications-list-section">
        <h3 className="notifications-list-header">Saved Notifications List</h3>

        {notifications.length > 0 ? (
          <ul className="notifications-list">
            {notifications.map((notification) => (
              <li key={notification._id} className="notification-item">
                <h4>{notification.title}</h4>
                <p>{notification.content}</p>
                <button
                  onClick={() => handleDeleteNotification(notification._id)}
                  className="delete-notification-button"
                >
                  Delete
                </button>
                <button
                  onClick={() => handleEditNotification(notification)}
                  className="edit-notification-button"
                >
                  Update
                </button>
              </li>
            ))}
          </ul>
        ) : (
          <p className="no-notifications-message">No notifications found.</p>
        )}
      </div>

      {isPopupOpen && (
        <div className="popup-wrapper">
          <div className="popup-content">
            <h3>Edit Notification</h3>
            <label>Title:</label>
            <input
              type="text"
              value={currentNotification.title}
              onChange={(e) =>
                setCurrentNotification({
                  ...currentNotification,
                  title: e.target.value,
                })
              }
            />
            <label>Message:</label>
            <textarea
              value={currentNotification.content}
              onChange={(e) =>
                setCurrentNotification({
                  ...currentNotification,
                  content: e.target.value,
                })
              }
            ></textarea>
            <button onClick={handleUpdateNotification}>Save Changes</button>
            <button onClick={() => setIsPopupOpen(false)}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SaveNotification;
