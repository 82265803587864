const BASE_URL = "${BASE_URL}/wallet";

export const refundAmount = async (
  id,
  userId,
  astrologerId,
  amount,
  percentage
) => {
  try {
    const response = await fetch(
      `${BASE_URL}/refund/${userId}/${astrologerId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          amount: amount,
          percentage: percentage,
          transactionId: id,
        }),
      }
    );

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(errorText);
    }

    return await response.json();
  } catch (error) {
    throw new Error(error.message);
  }
};
