import './index.css';
import { useContext } from 'react';

import { ActiveSectionContext } from '../ActiveSectionContext';

import PendingChats from '../PendingChats/PendingChats';
import CompletedChats from '../CompletedChats/CompletedChats';
import FailedChats from '../FailedChats/FailedChats';
import TransferredChats from '../TransferredChats/TransferredChats';
import ChatHistory from '../ChatHistory/ChatHistory';
import PickedChats from '../PickedChats/PickedChats';
import CancelChats from '../cancelChat';
import CompletedFailedChats from '../CompletedFailedChats/completedFailedChat';

const LiveChatsSection = () => {
    const { currentActiveSection, setActiveCurrentSection } = useContext(ActiveSectionContext); 
    return (
        <div>
            <h1 className='chats-main-heading'>Chats</h1>
            <div className="Status-panel-container">
                <button
                    className={`btn ${currentActiveSection === "Pending Chats" ? "activeTabSection" : ""}`}
                    onClick={() => setActiveCurrentSection("Pending Chats")}
                >
                    Pending 
                </button>
                <button
                    className={`btn ${currentActiveSection === "Picked" ? "activeTabSection" : ""}`}
                    onClick={() => setActiveCurrentSection("Picked")}
                >
                    Picked  
                </button>
                <button
                    className={`btn ${currentActiveSection === "Completed Chats" ? "activeTabSection" : ""}`}
                    onClick={() => setActiveCurrentSection("Completed Chats")}
                >
                    Completed 
                </button>
                <button
                    className={`btn ${currentActiveSection === "CompletedFailed Chats" ? "activeTabSection" : ""}`}
                    onClick={() => setActiveCurrentSection("CompletedFailed Chats")}
                >
                    Failed 
                </button>
                <button
                    className={`btn ${currentActiveSection === "Failed Chats" ? "activeTabSection" : ""}`}
                    onClick={() => setActiveCurrentSection("Failed Chats")}
                >
                    Expired 
                </button>
                <button
                    className={`btn ${currentActiveSection === "Cancel Chats" ? "activeTabSection" : ""}`}
                    onClick={() => setActiveCurrentSection("Cancel Chats")}
                >
                    Cancelled 
                </button>
                <button
                    className={`btn ${currentActiveSection === "Transfer Chats" ? "activeTabSection" : ""}`}
                    onClick={() => setActiveCurrentSection("Transfer Chats")}
                >
                    Transfered
                </button>
                <button
                    className={`btn ${currentActiveSection === "Chat History" ? "activeTabSection" : ""}`}
                    onClick={() => {
                        setActiveCurrentSection("Chat History");
                       
                    }}
                >
                   Chat History
                </button>
            </div>
          
          
           
            {currentActiveSection === "Pending Chats" && (
                <>
                   
                    {<PendingChats/>}
                </>
            )}

         
            {currentActiveSection === "Completed Chats" && (
                <>
                    
                    {<CompletedChats/>}
                </>
            )}

            {currentActiveSection === "CompletedFailed Chats" && (
                <>
                    
                    {<CompletedFailedChats/>}
                </>
            )}

           
            {currentActiveSection === "Failed Chats" && (
                <>
                   
                   {<FailedChats/>}
                </>
            )}
             {currentActiveSection === "Cancel Chats" && (
                <>
                   
                   {<CancelChats/>}
                </>
            )}

        

            {currentActiveSection === "Transfer Chats" && (
                <>
                    
                {<TransferredChats/>}
                </>
            )}

            {currentActiveSection === "Chat History" && (
                <>
                
                    {<ChatHistory/>}
                </>
            )}
             {currentActiveSection === "Picked" && (
                <>
                
                    {<PickedChats/>}
                </>
            )}
           
        </div>
    );
};

export default LiveChatsSection;