import { useEffect, useState } from "react";
import BASE_URL from "./config";
import Loader from "./Loader";
import { useNavigate } from "react-router-dom";

const CancelChats = () => {
  const [currentCancelPage, setCurrentCancelPage] = useState(1);
  const [totalCancelPages, setTotalCancelPages] = useState(null);
  const [cancelChatsData, setCancelChatsData] = useState([]);
  const [totalCancelChatsCount, setCancelChatsCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(searchQuery);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const navigate = useNavigate();

  const handlePageChange = (direction) => {
    if (direction === "prev" && currentCancelPage > 1) {
      setCurrentCancelPage(currentCancelPage - 1);
    } else if (direction === "next" && currentCancelPage < totalCancelPages) {
      setCurrentCancelPage(currentCancelPage + 1);
    }
  };

  const cancelChatDataTable = () => (
    <>
      <div className="astro-table-container pymnts-container">
        <table className="astro-table">
          <thead className="astro-he">
            <tr className="astro-bg">
              <th className="he">User Name</th>
              <th className="he">Astrologer Name</th>
              <th className="he">User PhoneNumber</th>
              <th className="he">Astrologer PhoneNumber</th>
              <th className="he">Cost/min</th>
              <th className="he">Date Time</th>
            </tr>
          </thead>
          <tbody>
            {cancelChatsData?.map((chat) => (
              <tr className="astro-rows" key={chat._id}>
                <td
                  className="he"
                  onClick={() =>
                    navigate(`/dashboard/user/${chat?.userId}`, {
                      state: {
                        user: {
                          _id: chat?.userId,
                          userName: chat?.userName,
                          phone_number: chat?.from,
                        },
                      },
                    })
                  }
                  style={{ color: "blue", cursor: "pointer" }}
                >
                  {chat?.userName || "NA"}
                </td>
                <td
                  className="he"
                  onClick={() =>
                    navigate(`/dashboard/astrologer/${chat?._id}`, {
                      state: { astrologer: chat.astrologer },
                    })
                  }
                  style={{ color: "blue", cursor: "pointer" }}
                >
                  {chat?.astrologer?.astrologerName || "NA"}
                </td>
                <td className="he">{chat.from || "NA"}</td>
                <td className="he">{chat.to || "NA"}</td>
                <td className="he">{chat?.cost || "NA"}</td>
                <td className="he">{chat?.updatedAt}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination">
        <button
          className="pagination-button"
          onClick={() => handlePageChange("prev")}
          disabled={currentCancelPage === 1}
        >
          Prev
        </button>
        <span className="pagination-info">
          Page {currentCancelPage} of {totalCancelPages}
        </span>
        <button
          className="pagination-button"
          onClick={() => handlePageChange("next")}
          disabled={currentCancelPage === totalCancelPages}
        >
          Next
        </button>
      </div>
    </>
  );

  const getCancelChats = async (signal) => {
    const apiUrl = `${BASE_URL}/user/get-cancelled-chats?page=${currentCancelPage}`;
    setIsLoading(true);
    try {
      const response = await fetch(apiUrl, { signal });
      const data = await response.json();
      if (response.ok) {
        const { cancelChatsList, total, totalPages } = data;
        setCancelChatsData(cancelChatsList);
        setCancelChatsCount(total);
        setTotalCancelPages(totalPages);
      }
    } catch (error) {
      if (error.name !== "AbortError") {
        console.error("Failed to fetch cancel chats:", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCancelChatsByDate = async (signal) => {
    const apiUrl = `${BASE_URL}/user/get-cancelled-chats-by-date?date=${selectedDate}&page=${currentCancelPage}`;
    setIsLoading(true);
    try {
      const response = await fetch(apiUrl, { signal });
      const data = await response.json();
      if (response.ok) {
        const { cancelChatsList, total, totalPages } = data;
        setCancelChatsData(cancelChatsList);
        setCancelChatsCount(total);
        setTotalCancelPages(totalPages);
      }
    } catch (error) {
      if (error.name !== "AbortError") {
        console.error("Cancelled to fetch cancel chats by date:", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const searchCancelChats = async (signal) => {
    const apiUrl = `${BASE_URL}/user/search-cancelled-chats?search=${debouncedSearchQuery}`;
    setIsLoading(true);
    try {
      const response = await fetch(apiUrl, { signal });
      const data = await response.json();
      if (response.ok) {
        setCancelChatsData(data.chats || []);
      }
    } catch (error) {
      if (error.name !== "AbortError") {
        console.error("Failed to search cancel chats:", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 500);
    return () => clearTimeout(timer);
  }, [searchQuery]);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    if (debouncedSearchQuery) {
      searchCancelChats(signal);
    } else if (selectedDate) {
      fetchCancelChatsByDate(signal);
    } else {
      getCancelChats(signal);
    }

    return () => controller.abort();
  }, [currentCancelPage, debouncedSearchQuery, selectedDate]);

  return (
    <div>
      <div>
        <input
          type="search"
          placeholder="Search by Username, Astrologer Name, or Phone Number"
          className="search-bar"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button className="btn" onClick={() => getCancelChats()}>
          {isLoading ? "Loading..." : "Reload"}
        </button>
      </div>
      <div className="date-picker-container">
        <h3 className="date-picker-heading">Select a Date</h3>
        <input
          type="date"
          value={selectedDate}
          onChange={(event) => {
            setCurrentCancelPage(1); // Reset to the first page on date change
            setSelectedDate(event.target.value);
          }}
          className="date-input"
        />
        <button
          className="date-clear"
          onClick={() => {
            setSelectedDate("");
            getCancelChats();
          }}
        >
          Clear
        </button>
        {selectedDate && (
          <p className="selected-date-text">{`Selected date: ${selectedDate}`}</p>
        )}
        <h3>{`Total Cancelled Chats: ${totalCancelChatsCount}`}</h3>
      </div>

      {isLoading ? <Loader /> : cancelChatDataTable()}
    </div>
  );
};

export default CancelChats;
