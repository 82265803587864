import React from 'react';
import { FaXmark } from "react-icons/fa6";
import "./onBooardAstrologerPopUp.css";
import Loader from '../Loader';

const onBooardAstrologerPopUp = ({ loading, message, close }) => (
    <div className="popup-container">
        <div className="popup-container-display">
            <button onClick={close} className="close-btn">
                <FaXmark />
            </button>
            {loading ? (
                <p>Loading...please wait!</p>
            ) : (
                <div className='confirm-details-onboard'>
                    <p>{message}</p>
                    <button
                        type="button"
                        className="confirm-button"
                        onClick={close} 
                    >
                        Okay
                    </button>
                </div>
            )}
        </div>
    </div>
);

export default onBooardAstrologerPopUp;
