export const festivals = [
    {
        date: "2024-11-01",
        title: "Diwali Offer",
        desc: "Illuminate your Diwali celebrations with extra rewards! Invest ₹150 and receive a bonus of ₹10 in your account.",
        image: "https://credmarg-kyc-images.s3.ap-south-1.amazonaws.com/coupons/diwali+(1).jpg",
        backgroundColor: "Red",
    },
    {
        date: "2024-11-02",
        title: "Govardhan Puja Special",
        desc: "Mark Govardhan Puja with added blessings! Invest ₹150 or more and receive a bonus of ₹10 as our gift to you.",
        image: "https://credmarg-kyc-images.s3.ap-south-1.amazonaws.com/coupons/govardhan+(1).jpg",
        backgroundColor: "Green",
    },
    {
        date: "2024-11-03",
        title: "Bhai Dooj Special",
        desc: "Celebrate the bond of love on Bhai Dooj! Invest ₹150 or more and receive a bonus of ₹10 in your account.",
        image: "https://credmarg-kyc-images.s3.ap-south-1.amazonaws.com/coupons/bhai-dooj+(1).jpg",
        backgroundColor: "Purple",
    },
    {
        date: "2024-11-07",
        title: "Chhath Puja Offer",
        desc: "Observe Chhath Puja with added blessings! Invest ₹150 and receive a bonus of ₹10 as our gift to you.",
        image: "https://credmarg-kyc-images.s3.ap-south-1.amazonaws.com/coupons/chhat-puja+(1).jpg",
        backgroundColor: "Blue",
    },
    {
        date: "2024-11-15",
        title: "Guru Nanak Jayanti Reward",
        desc: "On Guru Nanak Jayanti, invest ₹150 or more and receive a bonus of ₹10 in your account as a token of blessings.",
        image: "https://credmarg-kyc-images.s3.ap-south-1.amazonaws.com/coupons/guru-nanak+jayanti+(1).jpg",
        backgroundColor: "Yellow",
    },
    {
        date: "2024-12-25",
        title: "Christmas Cheer",
        desc: "Spread the joy of Christmas with extra rewards! Invest ₹150 and receive a bonus of ₹10 in your account as our gift to you.",
        image: "https://credmarg-kyc-images.s3.ap-south-1.amazonaws.com/coupons/christmas+(1).jpg",
        backgroundColor: "Red",
    },
    {
        date: "2025-01-01",
        title: "New Year Celebration",
        desc: "Ring in the New Year with a special reward! Invest ₹150 and receive a bonus of ₹10 as a new beginning gift.",
        image: "https://credmarg-kyc-images.s3.ap-south-1.amazonaws.com/coupons/diwali+(1).jpg",
        backgroundColor: "Gold",
    },
    {
        date: "2025-01-14",
        title: "Sankranti Festive Bonus",
        desc: "Celebrate Sankranti with special rewards! Invest ₹150 and receive a bonus of ₹10 to mark the festival.",
        image: "https://credmarg-kyc-images.s3.ap-south-1.amazonaws.com/coupons/diwali+(1).jpg",
        backgroundColor: "Orange",
    },
    {
        date: "2025-01-26",
        title: "Republic Day Special",
        desc: "Celebrate Republic Day with a bonus! Invest ₹150 or more and receive an additional ₹10 in your account.",
        image: "https://credmarg-kyc-images.s3.ap-south-1.amazonaws.com/coupons/independence-day+(1).jpg",
        backgroundColor: "DarkBlue",
    },
    {
        date: "2025-02-14",
        title: "Valentine's Day",
        desc: "Spread love this Valentine's Day! Invest ₹150 and get a bonus of ₹10 in your account.",
        image: "https://www.drikpanchang.com/images/festivals/180x120/happy_valentines_day.jpg",
        backgroundColor: "Pink",
    },
    {
        date: "2025-02-26",
        title: "Maha Shivaratri Special",
        desc: "On Maha Shivaratri, receive added blessings! Invest ₹150 and get a bonus of ₹10 in your account.",
        image: "https://credmarg-kyc-images.s3.ap-south-1.amazonaws.com/coupons/diwali+(1).jpg",
        backgroundColor: "Gray",
    },
    {
        date: "2025-03-14",
        title: "Holi Rewards",
        desc: "Celebrate Holi with extra benefits! Invest ₹150 and receive a bonus of ₹10 in your account.",
        image: "https://credmarg-kyc-images.s3.ap-south-1.amazonaws.com/coupons/diwali+(1).jpg",
        backgroundColor: "Multicolor",
    },
    {
        date: "2025-03-30",
        title: "Ugadi Rewards",
        desc: "Celebrate Ugadi with extra benefits! Invest ₹150 and receive a bonus of ₹10 in your account.",
        image: "https://credmarg-kyc-images.s3.ap-south-1.amazonaws.com/coupons/diwali+(1).jpg",
        backgroundColor: "LightGreen",
    },
    {
        date: "2025-06-07",
        title: "Eid al-Adha Special",
        desc: "Celebrate Eid al-Adha with us! Invest ₹150 or more and receive a bonus of ₹10 in your account.",
        image: "https://credmarg-kyc-images.s3.ap-south-1.amazonaws.com/coupons/eid+(1).jpg",
        backgroundColor: "Green",
    },
    {
        date: "2025-07-10",
        title: "Guru Purnima Celebration",
        desc: "On Guru Purnima, express gratitude to your gurus for guiding you on the path of wisdom.",
        image: "https://credmarg-kyc-images.s3.ap-south-1.amazonaws.com/coupons/guru-purnima+(1).jpg",
        backgroundColor: "LightYellow",
    },
    {
        date: "2025-08-09",
        title: "Raksha Bandhan Offer",
        desc: "Tie the bond of Raksha Bandhan with extra rewards! Invest ₹150 and receive a bonus of ₹10 as our gift to you.",
        image: "https://credmarg-kyc-images.s3.ap-south-1.amazonaws.com/coupons/raksha-bandhan+(1).jpg",
        backgroundColor: "Violet",
    },
    {
        date: "2025-08-15",
        title: "Independence Day Special",
        desc: "Celebrate Independence Day with a bonus! Invest ₹150 or more and receive an additional ₹10 in your account.",
        image: "https://credmarg-kyc-images.s3.ap-south-1.amazonaws.com/coupons/independence-day+(1).jpg",
        backgroundColor: "SkyBlue",
    },
    {
        date: "2025-08-16",
        title: "Janmashtami Bonus",
        desc: "Celebrate Janmashtami with added joy! Invest ₹150 or more and receive a ₹10 bonus in your account.",
        image: "https://credmarg-kyc-images.s3.ap-south-1.amazonaws.com/coupons/janmashtami+(1).jpg",
        backgroundColor: "Golden",
    },
    {
        date: "2025-08-27",
        title: "Ganesh Chaturthi Special",
        desc: "Wishing you prosperity on Ganesh Chaturthi! Invest ₹150 and receive a bonus of ₹10 as a token of our blessings.",
        image: "https://credmarg-kyc-images.s3.ap-south-1.amazonaws.com/coupons/ganesh-chaturthi+(1).jpg",
        backgroundColor: "Pink",
    },
    {
        date: "2025-09-05",
        title: "Onam Offer",
        desc: "Make your Onam celebrations even more rewarding! Invest ₹150 or more and receive an extra ₹10 in your account.",
        image: "https://credmarg-kyc-images.s3.ap-south-1.amazonaws.com/coupons/onam+(1).jpg",
        backgroundColor: "Orange",
    },
    {
        date: "2025-09-22",
        title: "Navratri Bonus",
        desc: "Begin Navratri with an auspicious offer! Invest ₹150 or more and receive a bonus of ₹10 in your account.",
        image: "https://credmarg-kyc-images.s3.ap-south-1.amazonaws.com/coupons/durga-pooja+(1).jpg",
        backgroundColor: "BrightYellow",
    },
    {
        date: "2025-10-02",
        title: "Gandhi Jayanti Reward",
        desc: "Join us in commemorating Gandhi Jayanti! Recharge with ₹150 and receive a bonus of ₹10 as a gesture of peace.",
        image: "https://credmarg-kyc-images.s3.ap-south-1.amazonaws.com/coupons/gandhi-jayanti+(1).jpg",
        backgroundColor: "White",
    },
    {
    date: "2025-09-22",
    title: "Navratri Bonus",
    desc: "Begin Navratri with an auspicious offer! Invest ₹150 or more and receive a bonus of ₹10 in your account.",
    image:
        "https://credmarg-kyc-images.s3.ap-south-1.amazonaws.com/coupons/durga-pooja+(1).jpg",
    backgroundColor: "Green",
    },
    {
    date: "2025-10-02",
    title: "Dussehra Delight",
    desc: "Celebrate the victory of good over evil with added rewards! Invest ₹150 and receive a bonus of ₹10 in your account.",
    image: "https://credmarg-kyc-images.s3.ap-south-1.amazonaws.com/coupons/dussehra+(1).jpg",
    backgroundColor: "Red",
    },
    {
    date: "2025-10-15",
    title: "Eid-e-Milad Bonus",
    desc: "On the occasion of Eid-e-Milad, invest ₹150 and receive a bonus of ₹10 in your account",
    image: "https://credmarg-kyc-images.s3.ap-south-1.amazonaws.com/coupons/eid+(1).jpg",
    backgroundColor: "Blue",
    },
    {
    date: "2025-10-31",
    title: "Karva Chauth Reward",
    desc: "Celebrate Karva Chauth with added rewards! Invest ₹150 or more and receive a bonus of ₹10 in your account",
    image:"https://credmarg-kyc-images.s3.ap-south-1.amazonaws.com/coupons/karwa-chauth+(1).jpg",
    backgroundColor: "Green",
    },
    {
      date: "2025-10-20",
      title: "Diwali Offer 2025",
      desc: "Celebrate Diwali 2025 with added rewards! Invest ₹150 and receive a bonus of ₹10 in your account to light up the festive season.",
      image: "https://credmarg-kyc-images.s3.ap-south-1.amazonaws.com/coupons/diwali+(1).jpg",
      backgroundColor: "Purple",
    },

]