import React, { useState, useCallback, useRef, useEffect } from "react";
import "./Coupons.css";
import FreeChat from "../AstrologerEditPopup/FreeChat/freeChat";
import { festivals } from "../FestivalList/index";
import AstroPopUp from "../Astrologers/AstroPopUp";
import EditCouponPopUp from "./EditCouponPopUp";
import BASE_URL from "../config";
const Coupons = () => {
  const [coupon, setCoupon] = useState({
    title: "",
    amount: "",
    minAmount: "",
    image: "",
    expireAfterDay: "",
  });

  const [popup, setPopup] = useState({ message: "", type: "" });
  const [loading, setLoading] = useState(false);
  const [amountValid, setAmountValid] = useState("");
  const [minAmountValid, setMinAmountValid] = useState("");
  const [titleValid, setTitleValid] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [popupVisible, setPopupVisible] = useState(false);
  const [editCouponId, setEditCoupon] = useState("");

  const [statusSection, setStatusSection] = useState("Create Coupon");

  const [coupons, setCoupons] = useState({
    current: [],
    nearestFestival: [],
  });

  const abortController = useRef(new AbortController());

  const onChangeStatusSection = (statusValue) => {
    setStatusSection(statusValue);
  };

  const fileInputRef = useRef(null);

  const cancelFetch = () => {
    abortController.current.abort();
    abortController.current = new AbortController();
  };

  const getToken = () => {
    return localStorage.getItem("token");
  };

  const validateTitle = (value) => {
    return value.length <= 14;
  };

  const validateAmount = (value) => {
    return value >= 5 && value <= 30;
  };

  const validateMinAmount = (value) => {
    return value >= 150;
  };

  const handleCouponChange = (e) => {
    const { name, value } = e.target;

    if (
      name === "amount" ||
      name === "minAmount" ||
      name === "expireAfterDay"
    ) {
      if (parseFloat(value) < 0) {
        setCoupon((prevCoupon) => ({ ...prevCoupon, [name]: "" }));
        return;
      }
    }

    setCoupon((prevCoupon) => ({ ...prevCoupon, [name]: value }));

    if (name === "title") {
      if (!validateTitle(value)) {
        setTitleValid("Title must be 14 characters or less.");
      } else {
        setTitleValid("");
      }
    }

    if (name === "amount") {
      const numValue = parseFloat(value);
      if (!validateAmount(numValue)) {
        setAmountValid("Amount must be between 5 and 30.");
      } else {
        setAmountValid("");
      }
    }

    if (name === "minAmount") {
      const numValue = parseFloat(value);
      if (!validateMinAmount(numValue)) {
        setMinAmountValid("Minimum Amount must be at least 150.");
      } else {
        setMinAmountValid("");
      }
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];

    if (!file) {
      setPopup({ message: "No file selected.", type: "error" });
      return;
    }

    if (file.size > 1048576) {
      setPopup({
        message: "Image size must be less than 1 MB.",
        type: "error",
      });
      return;
    }

    const img = new Image();
    img.onload = () => {
      if (img.width > 200 || img.height > 200) {
        setPopup({
          message:
            "Image dimensions must be less than or equal to 200x200 pixels.",
          type: "error",
        });
      } else {
        setSelectedFile(file);
        setPopup({ message: "", type: "" });
      }
    };
    img.onerror = () => {
      setPopup({ message: "Invalid image file.", type: "error" });
    };
    img.src = URL.createObjectURL(file);
  };

  const uploadImage = async () => {
    if (!selectedFile) {
      return null;
    }

    const formData = new FormData();
    formData.append("image", selectedFile);

    try {
      const response = await fetch(`${BASE_URL}/coupon/upload-image`, {
        method: "POST",
        body: formData,
        signal: abortController.current.signal,
      });

      if (!response.ok) {
        throw new Error("Image upload failed");
      }

      const data = await response.json();
      return data.imageUrl;
    } catch (error) {
      console.error(error.message);
      setPopup({ message: "Error while uploading the image", type: "error" });
      return null;
    }
  };
  const closePopup = () => {
    setPopupVisible(false);
  };
  const createCoupon = useCallback(async () => {
    let isValid = true;

    if (!validateTitle(coupon.title)) {
      setPopup({
        message: "Title must be 14 characters or less.",
        type: "error",
      });
      isValid = false;
    }

    if (!validateAmount(parseFloat(coupon.amount))) {
      setPopup({ message: "Amount must be between 5 and 30.", type: "error" });
      isValid = false;
    }

    if (!validateMinAmount(parseFloat(coupon.minAmount))) {
      setPopup({
        message: "Minimum Amount must be at least 150.",
        type: "error",
      });
      isValid = false;
    }

    if (isValid) {
      setLoading(true);

      try {
        const imageUrl = await uploadImage();
        if (!imageUrl) {
          setLoading(false);
          return;
        }

        const body = {
          title: coupon.title,
          amount: coupon.amount,
          minAmount: coupon.minAmount,
          imageUrl: imageUrl,
          expireAfterDay: coupon.expireAfterDay,
        };
        const authToken = getToken();

        const response = await fetch(`${BASE_URL}/coupon/coupon-festivals`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify(body),
        });

        if (response.status === 200) {
          setPopup({
            message: "Coupon added successfully ✔️",
            type: "success",
          });
          setCoupon({
            title: "",
            amount: "",
            minAmount: "",
            image: "",
            expireAfterDay: "",
          });
          setSelectedFile(null);
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
        } else {
          const data = await response.json();
          setPopup({
            message: data.message || "Error while adding the coupon",
            type: "error",
          });
        }
      } catch (error) {
        console.error(error.message);
        setPopup({ message: "Error while adding the coupon", type: "error" });
      } finally {
        setLoading(false);
      }
    }
  }, [coupon, selectedFile]);

  const fetchCoupons = async () => {
    try {
      const response = await fetch(`${BASE_URL}/coupon/see-AllCoupons`, {
        signal: abortController.current.signal,
      });

      const data = await response.json();

      categorizeCoupons(data.Coupons);
    } catch (error) {
      console.error("Error fetching coupons:", error);
    }
  };

  const categorizeCoupons = (allCoupons) => {
    const now = new Date();
    const current = [];
    const upcoming = [];

    allCoupons.forEach((coupon) => {
      const expirationDate = new Date(coupon.expirationDate);
      const createdAt = new Date(coupon.createdAt);

      if (
        createdAt.toDateString() === now.toDateString() &&
        (coupon.status === "unClaimed" || coupon.status === "claimed") &&
        coupon.type === "global"
      ) {
        current.push(coupon);
      }
    });

    const upcomingFestivals = festivals
      .filter((festival) => new Date(festival.date) > now)
      .sort((a, b) => new Date(a.date) - new Date(b.date));

    const nearestUpcomingFestival =
      upcomingFestivals.length > 0 ? upcomingFestivals[0] : null;

    const todayCurrentCoupon = current.length > 0 ? current[0] : null;

    setCoupons({
      current: todayCurrentCoupon ? [todayCurrentCoupon] : [],
      nearestFestival: nearestUpcomingFestival ? [nearestUpcomingFestival] : [],
    });
  };

  const expireOffer = async (couponId) => {
    const response = await fetch(
      `${BASE_URL}/coupon/expiredCoupons/${couponId}`,
      {
        method: "POST",
      }
    );
    if (response.ok) {
      fetchCoupons();
    }
  };
  const editCouponDetails = async (couponId) => {
    try {
      setPopupVisible(true);
      setEditCoupon(couponId);
    } catch (error) {}
  };

  const onSumbitEditCoupon = () => {
    fetchCoupons();
  };
  useEffect(() => {
    fetchCoupons();
    return () => cancelFetch();
  }, []);

  const getCouponCode = () => (
    <div className="offer-main-container">
      <div className="offer-list">
        <h1 className="festivals-c-head">Festival Coupons</h1>
        <h1 className="expire-coupons-current-copuons-heading today-c">
          Today's Current Coupon
        </h1>
        {coupons.current.length > 0 ? (
          coupons.current.map((coupon, index) => (
            <div key={index} className="today-coupon-offer-card">
              <h4>{coupon.title}</h4>
              <p>Amount: {coupon.amount}</p>
              <p>
                Expires On:{" "}
                {new Date(coupon.expirationDate).toLocaleDateString()}
              </p>
              {/* {(coupon.status === "unClaimed" ||
                coupon.status === "claimed") && ( */}
              <div className="buttons-coupons-c">
                <div>
                  <button
                    onClick={() => expireOffer(coupon._id)}
                    className="button expire-button"
                  >
                    Force Stop
                  </button>
                </div>
                <div>
                  <button
                    className="trigger-button"
                    onClick={() => editCouponDetails(coupon._id)}
                  >
                    Edit
                  </button>
                </div>
              </div>
              {popupVisible && (
                <EditCouponPopUp
                  onCancel={closePopup}
                  couponId={editCouponId}
                  onClick={onSumbitEditCoupon}
                />
              )}
              {/* )} */}
            </div>
          ))
        ) : (
          <p>No current coupons available.</p>
        )}

        <h1 className="expire-coupons-current-copuons-heading nxt-coupon">
          Next Upcoming Coupon
        </h1>
        {coupons.nearestFestival.map((coupon, index) => (
          <div key={index} className="upcoming-coupon-offer-card">
            <h4>Festival Coupon</h4>
            <p>festival: {coupon.title}</p>
            <p>Date: {coupon.date}</p>
          </div>
        ))}
      </div>

      <div className="coupon-container">
        <h1 className="form-title">Create Coupon</h1>
        <form
          className="coupon-form"
          onSubmit={(e) => {
            e.preventDefault();
            createCoupon();
          }}
        >
          <div className="form-group">
            <label>Title:</label>
            <input
              type="text"
              className="form-input"
              name="title"
              value={coupon.title}
              onChange={handleCouponChange}
            />
            {titleValid && <p className="error-message">*{titleValid}</p>}
          </div>
          <div className="form-group">
            <label>Amount:</label>
            <input
              type="number"
              className="form-input"
              name="amount"
              value={coupon.amount}
              onChange={handleCouponChange}
            />
            {amountValid && <p className="error-message">*{amountValid}</p>}
          </div>
          <div className="form-group">
            <label>Minimum Amount:</label>
            <input
              type="number"
              className="form-input"
              name="minAmount"
              value={coupon.minAmount}
              onChange={handleCouponChange}
            />
            {minAmountValid && (
              <p className="error-message">*{minAmountValid}</p>
            )}
          </div>
          <div className="form-group">
            <label>Image:</label>
            <input
              type="file"
              className="form-input"
              onChange={handleImageUpload}
              ref={fileInputRef}
            />
            <p className="note"> (Max size: 1MB, Max dimensions: 200x200)</p>
          </div>
          <div className="form-group">
            <label>Expire After Days:</label>
            <input
              type="number"
              className="form-input"
              name="expireAfterDay"
              value={coupon.expireAfterDay}
              onChange={handleCouponChange}
            />
          </div>
          <div className="form-group">
            <button className="submit-button" type="submit" disabled={loading}>
              {loading ? <div className="spinner">.Loading...</div> : "Create"}
            </button>
          </div>
        </form>
        {popup.message && (
          <div className={`popup ${popup.type}`}>{popup.message}</div>
        )}
      </div>
    </div>
  );

  return (
    <div>
      <div className="Status-panel-container">
        <button
          className={`btn ${
            statusSection === "Create Coupon" ? "activeTabSection" : ""
          }`}
          onClick={() => onChangeStatusSection("Create Coupon")}
        >
          Create Coupon
        </button>
        <button
          className={`btn ${
            statusSection === "Free Chat" ? "activeTabSection" : ""
          }`}
          onClick={() => onChangeStatusSection("Free Chat")}
        >
          Free Chat
        </button>
      </div>

      {statusSection === "Create Coupon" ? getCouponCode() : <FreeChat />}
    </div>
  );
};

export default Coupons;
