import React from 'react';
import './index.css'; 


const AstroPopUp = ({ percentage, onClose }) => {
    return (
        <div className="astro-popup-overlay">
            <div className="astro-popup-content">
                <p>{percentage}% Refund Successful!</p>
                <button className="astro-confirm-button" onClick={onClose}>Okay</button>
            </div>
        </div>
    );
};

export default AstroPopUp;


