import { useState } from "react";
import { useNavigate ,useLocation} from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import AstrologerPendingChats from "../AstrologerPendingChats/AstrologerPendingChats";
import AstrologerAcceptedChats from "../AstrologerAcceptedChats/AstrologerAcceptedChats";
import AstrologerSessionEndChat from "../AstrologerSesstionEndChats/AstrologerSessionEndChats";

const AstrologerWaitingListView=()=>{
    const location = useLocation();
    const { astrologer } = location.state || {};
    const [activeTab, setActiveTab] = useState("Queue");
    

    console.log("astrologer waiting list data state value",astrologer)

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        
      };
      const navigate=useNavigate()
    return (
        <>
        <div className="main-bar-sec">
        <button className="astro-back-button" onClick={() => navigate(-1)}>
              <IoMdArrowRoundBack size={23} className="back-arrow" />
        </button>
         <h1 className="atrologers-heading">Waiting List</h1>
        </div>
       
        <div className="atsto-container">
     

    <div className="astrologer-details-waiting-view">
        <h1>Astrologer Name:</h1>
        <h1>{astrologer.astrologerName}</h1>
    </div>
    <div className="astrologer-details-waiting-view">
        <h2>Phone Number:</h2>
        <h2>{astrologer.phone_number}</h2>
    </div>
    
      <div className="tabs">
        <button
          className={`tab-button ${activeTab === "Queue" ? "active" : ""}`}
          onClick={() => handleTabClick("Queue")}
        >
          Queue 
        </button>
        <button
          className={`tab-button ${
            activeTab === "Accepted" ? "active" : ""
          }`}
          onClick={() => handleTabClick("Accepted")}
        >
          Accepted
        </button>
        <button
          className={`tab-button ${
            activeTab === "Session End" ? "active" : ""
          }`}
          onClick={() => handleTabClick("Session End")}
        >
            Session End
        </button>
      </div>

      <div className="astro-search-container">
        <input
          type="text"
          placeholder="Search by Name"
          className="astro-search-input"
          
         
        />
      </div>
      {activeTab==="Queue" && <AstrologerPendingChats astrologerId={astrologer._id}/>}
      {activeTab==="Accepted" && <AstrologerAcceptedChats astrologerId={astrologer._id}/>}
      {activeTab==="Session End" && <AstrologerSessionEndChat astrologerId={astrologer._id}/>  }
    </div>
    </>
    )
}

export default AstrologerWaitingListView