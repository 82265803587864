import React from 'react';
import Popup from 'reactjs-popup';
import { FaXmark } from "react-icons/fa6";
import { IoMdCloseCircle } from "react-icons/io";
import "./index.css";

const RejectPopup = ({ astrologerId, onConfirm }) => (
  <div className="popup-container">
    <Popup
      modal
      trigger={
        <button type="button" className="trigger-button-reject">
          Reject
        </button>
      }
    >
      {close => (
        <div className="popup-container-display">
          <div className='button-container'>
            <button onClick={() => { close() }} className='close-btn'>
              <FaXmark />
            </button>
          </div>
          <div className="confirm-details">
            <IoMdCloseCircle className="rejectMark" />
            <p>Astrologer Registration Rejected</p>
          </div>
          <button
            type="button"
            className="confirm-button"
            onClick={() => {
              onConfirm(astrologerId, 'rejected'); // Pass astrologer ID and status 'rejected'
              close(); // Close the popup
            }}
          >
            Confirm
          </button>
        </div>
      )}
    </Popup>
  </div>
);

export default RejectPopup;
