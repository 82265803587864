import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import BASE_URL from "./config";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = () => {
  const [depositsMonthly, setDepositsMonthly] = useState(new Array(12).fill(0));
  // const year = 2024;
  const year = new Date().getFullYear();

  const getDepositsData = async (signal) => {
    try {
      const depositApiUrl = `${BASE_URL}/transactions/get-transactions-stats?year=${year}`;
      const response = await fetch(depositApiUrl, { signal });
      const data = await response.json();
      console.log("transactions graph data", data);
      if (data.success) {
        setDepositsMonthly(data.monthlyCounts);
      } else {
        console.error("Error fetching deposit data:", data.message);
      }
    } catch (error) {
      console.error("Error fetching deposit data:", error);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller?.signal;
    getDepositsData(signal);
    return () => {
      controller?.abort();
    };
  }, []);

  const data = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Deposits",
        data: depositsMonthly,
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: `Monthly Deposits (${year})`,
      },
    },
    scales: {
      y: {
        ticks: {
          stepSize: 20,
        },
        min: 0,
        max: 500,
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default BarChart;
