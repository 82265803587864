import { useState } from "react";
import LiveChats from "../LiveChats/LiveChats";
import Accepted from "../Accepted/Accepted";
import SessionEndChat from "../SessionEndChat/SessionEndChat";
import IssuePercentageContainer from "../WebSocketIssues/WebsocketIssuePercentage";
const PickedChats = () => {
  const [currentActiveSection, setActiveCurrentSection] = useState("Live");
  return (
    <div>
      <div className="Status-panel-container">
        <button
          className={`btn ${
            currentActiveSection === "Live" ? "activeTabSectionTwo" : ""
          }`}
          onClick={() => setActiveCurrentSection("Live")}
        >
          Live
        </button>
        <button
          className={`btn ${
            currentActiveSection === "Completed Session"
              ? "activeTabSectionTwo"
              : ""
          }`}
          onClick={() => setActiveCurrentSection("Completed Session")}
        >
          Completed
        </button>
        <button
          className={`btn ${
            currentActiveSection === "Accepted" ? "activeTabSectionTwo" : ""
          }`}
          onClick={() => setActiveCurrentSection("Accepted")}
        >
          Accepted
        </button>
        <button
          className={`btn ${
            currentActiveSection === "IssuePercentageContainer" ? "activeTabSectionTwo" : ""
          }`}
          onClick={() => setActiveCurrentSection("IssuePercentageContainer")}
        >
          WebsocketIssue
        </button>
      </div>
      {currentActiveSection === "Live" && <>{<LiveChats />}</>}
      {currentActiveSection === "Accepted" && <>{<Accepted />}</>}
      {currentActiveSection === "IssuePercentageContainer" && <>{<IssuePercentageContainer />}</>}
      {currentActiveSection === "Completed Session" && (
        <>{<SessionEndChat />}</>
      )}
    </div>
  );
};

export default PickedChats;
