import { Link, useNavigate, Route, Routes } from "react-router-dom";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { RxDashboard } from "react-icons/rx";
import { FiUser } from "react-icons/fi";
import { BiMoneyWithdraw } from "react-icons/bi";
import { BsChatSquareDots } from "react-icons/bs";
import { RiSecurePaymentFill } from "react-icons/ri";
import { IoCallOutline } from "react-icons/io5";
import { CiLogout } from "react-icons/ci";
import { ImUserPlus } from "react-icons/im";
import "./Dashboard.css";
import ProtectedRoute from "../ProtectedRoute";
import Astrologers from "../Astrologers/Astrologers";
import AstrologerRegister from "../AstrologerRegister";
import DetailedAstrologer from "../DetailedAstrologer";
import LiveCallsSection from "../LiveCallsSection";
import AstrologerDetails from "../Astrologers/AstrologerDetails";
import { RiCoupon5Line } from "react-icons/ri";
import Users from "../Users/Users";
import LiveChatsSection from "../LiveChatsSection";
import UserDetails from "../User/User";
import Coupons from "../Coupons/Coupons";
import { TfiWallet } from "react-icons/tfi";
import SuperAstrologers from "../SuperAstrologers/superAStrologer";
import Payments from "../Payments";
import DashboardHome from "../DashboardHome/dashboardHome";
import WalletTransactions from "../WalletTransactions";
import ActiveSectionProvider from "../ActiveSectionContext";
import ChatRoomMessages from "../ChatRoomMessages/ChatRoomMessages";
import PendingChats from "../PendingChats/PendingChats";
import CompletedChats from "../CompletedChats/CompletedChats";
import FailedChats from "../FailedChats/FailedChats";
import TransferredChats from "../TransferredChats/TransferredChats";
import ChatHistory from "../ChatHistory/ChatHistory";
import UserProfileDetails from "../UserProfileDetails/UserProfileDetails";
import PickedChats from "../PickedChats/PickedChats";
import LiveChats from "../LiveChats/LiveChats";
import SessionEndChat from "../SessionEndChat/SessionEndChat";
import Accepted from "../Accepted/Accepted";
import WalletChatTransactions from "../WalletChatTransactions/WalletChatTransactions";
import WalletCallTransactions from "../WalletCallTransactions/WalletCallTransactions";
import AstrologerChatWalletTransaction from "../AstrologerChatWalletTransaction/AstrologerChatWalletTransaction";
import AstrologerCallWalletTransaction from "../AstrologerCallWalletTransaction/AstrologerCallWalletTransaction";
import CreatedUserPayments from "../UserPayments/CreatedUserPayments";
import FailureUserPayments from "../UserPayments/FailureUserPayments";
import SuccessfullUserPayments from "../UserPayments/SuccessfullUserPayments";
import UserChatRooms from "../UserChatRooms/UserChatRooms";
import AstrologerWaitingListView from "../AstrologerWaitingListView/AstrologerWaitingListView";
import { MdNotificationsActive } from "react-icons/md";
import SaveNotification from "../SaveNotificationContent/savenotificationcontent";
import Notification from "../Notification/notification";
import AstrologerStats from "../AstrologerStats/AstrologerStats";
import UserEngagement from "../Engagement/engagement";
import { IoNotificationsOff } from "react-icons/io5";
import OnboardAstrologers from "../OnBoardAsrtologers/onBoardAstrologers";
import { IoMdPersonAdd } from "react-icons/io";
import AstrologerStatsFig from "../AstrologerStatsFig/AstrologerStatsFig";
import CategorizedAstrologers from "../Astrologers/CategoriseAstrologers";
import AstrologerRepeatedUsers from "../Astrologers/AstrologerRepeatedUsers";
import PreferredTimeTable from "../PreferedTime";
import CancelChats from "../cancelChat";
import IssuePercentageContainer from "../WebSocketIssues/WebsocketIssuePercentage";
import CompletedFailedChats from "../CompletedFailedChats/completedFailedChat";
const Dashboard = () => {
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState("/dashboard/dashboardHome");
  const [activeSection, setActiveSection] = useState("Dashboard");

  const handleLinkClick = (path) => {
    setActiveLink(path);
    navigate(path);
  };

  const handleActiveSection = (activeItem) => {
    setActiveSection(activeItem);
  };

  const onClickToLogut = () => {
    Cookies.remove("jwt_token");
    navigate("/");
  };

  return (
    <div class="main-container">
      <div className="app-dashboard">
        <div class="main-con">
          <div class="header">
            <p class="person-logo">V</p>
            <div class="vis-con">
              <p class="vis">Vishal Raina</p>
              <p class="mail">vishal@gmail.com</p>
            </div>
          </div>

          <div
            className={`side1 link ${
              activeLink === "dashboard" ? "active" : ""
            }`}
            onClick={() => {
              handleLinkClick("dashboardHome");
              handleActiveSection("dashboardHome");
            }}
          >
            <RxDashboard class="user1" />
            <p class="user11">Dashboard</p>
          </div>

          <div
            className={`side1 link ${activeLink === "users" ? "active" : ""}`}
            onClick={() => {
              handleLinkClick("users");
              handleActiveSection("Users");
            }}
          >
            <FiUser class="user1" />
            <p class="user11">Users</p>
          </div>

          <div
            className={`side1 link ${
              activeLink === "astrologers" ? "active" : ""
            }`}
            onClick={() => {
              handleLinkClick("astrologers");
              handleActiveSection("Astrologers");
            }}
          >
            <BiMoneyWithdraw class="user1" />
            <p class="user11">Astrologers</p>
          </div>

          <div
            className={`side1 link ${
              activeLink === "onboard-astrologers" ? "active" : ""
            }`}
            onClick={() => {
              handleLinkClick("onboard-astrologers");
              handleActiveSection("onBoard Astrologers");
            }}
          >
            <IoMdPersonAdd  class="user1"/>
            <p class="user11">OnBoard Astrologers</p>
          </div>

          <div
            className={`side1 link ${
              activeLink === "payments" ? "active" : ""
            }`}
            onClick={() => handleLinkClick("payments")}
          >
            <RiSecurePaymentFill
              class="user1"
              onClick={() => handleActiveSection("Payments")}
            />
            <p class="user11">Payments</p>
          </div>

          <div
            className={`side1 link ${activeLink === "wallet" ? "active" : ""}`}
            onClick={() => handleLinkClick("wallet")}
          >
            <TfiWallet
              class="user1"
              onClick={() => handleActiveSection("wallet")}
            />
            <p class="user11">Wallet Transactions</p>
          </div>

          <div
            className={`side1 link ${
              activeLink === "liveChats" ? "active" : ""
            }`}
            onClick={() => handleLinkClick("liveChats")}
          >
            <BsChatSquareDots
              class="user1"
              onClick={() => handleActiveSection("Live chats")}
            />
            <p class="user11">Chats</p>
          </div>

          <div
            className={`side1 link ${
              activeLink === "live-calls" ? "active" : ""
            }`}
            onClick={() => handleLinkClick("live-calls")}
          >
            <IoCallOutline
              class="user1"
              onClick={() => handleActiveSection("Live calls")}
            />
            <p class="user11">Calls</p>
          </div>

          <div
            className={`side1 link ${
              activeLink === "astrologers-Register" ? "active" : ""
            }`}
            onClick={() => {
              handleLinkClick("astrologers-Register");
              handleActiveSection("Astrologer Register");
            }}
          >
            <ImUserPlus class="user1" />
            <p class="user11">Astrologer Register</p>
          </div>

          <div
            className={`side1 link ${activeLink === "coupons" ? "active" : ""}`}
            onClick={() => {
              handleLinkClick("coupons");
              handleActiveSection("coupons");
            }}
          >
            <RiCoupon5Line class="user1" />
            <p class="user11">Offers</p>
          </div>

          <div
            className={`side1 link ${
              activeLink === "notification" ? "active" : ""
            }`}
            onClick={() => {
              handleLinkClick("notification");
              handleActiveSection("notification");
            }}
          >
            <MdNotificationsActive class="user1" />
            <p className="user11">Notification</p>
          </div>
          <div
            className={`side1 link ${
              activeLink === "engagement" ? "active" : ""
            }`}
            onClick={() => {
              handleLinkClick("engagement");
              handleActiveSection("engagement");
            }}
          >
            <IoNotificationsOff class="user1" />
            <p className="user11">Engagement</p>
          </div>

          <div className="side1" onClick={onClickToLogut}>
            <CiLogout class="user1" />
            <p class="user11">Logout</p>
          </div>
        </div>
      </div>
      <div className="routes">
        <ActiveSectionProvider>
          <Routes element={<ProtectedRoute />}>
            <Route index element={<DashboardHome />} />
            <Route path="users" element={<Users />} />
            <Route path="user/:id" element={<UserDetails />} />
            <Route path="astrologers" element={<Astrologers />} />
            <Route path="onboard-astrologers" element={<OnboardAstrologers />} />
            <Route
              path="astrologers-register"
              element={<AstrologerRegister />}
            />
            <Route path="detailedAstrologer" element={<DetailedAstrologer />} />
            <Route path="payments" element={<Payments />} />
            <Route
              path="/super-astrologers/:chatId"
              element={<SuperAstrologers />}
            />
            <Route
              path="/assign-super-astrologer/waiting-call/:callId"
              element={<SuperAstrologers />}
            />
            <Route path="coupons" element={<Coupons />} />
            <Route path="wallet" element={<WalletTransactions />} />
            <Route path="dashboardHome" element={<DashboardHome />} />
            <Route path="liveChats" element={<LiveChatsSection />} />
            <Route path="live-calls" element={<LiveCallsSection />} />
            <Route path="astrologer/:id" element={<AstrologerDetails />} />
            <Route
              path="/getRoomChat/:chatroomId"
              element={<ChatRoomMessages />}
            />
            <Route path="/pending-chats" element={<PendingChats />} />
            <Route path="/completed-chats" element={<CompletedChats />} />
            <Route path="/failed-chats" element={<FailedChats />} />
            <Route path="/cancel-chats" element={<CancelChats />} />
            <Route path="/transferred-chats" element={<TransferredChats />} />
            <Route path="/chat-history" element={<ChatHistory />} />
            <Route path="/user-profile" element={<UserProfileDetails />} />
            <Route path="/picked-chats" element={<PickedChats />} />
            <Route path="/live-chats" element={<LiveChats />} />
            <Route path="/session-end-chat" element={<SessionEndChat />} />
            <Route path="/accepted-chats" element={<Accepted />} />
            <Route path="/completeFailed-chats" element={<CompletedFailedChats />} />
            <Route
              path="/chat-wallet-transactions"
              element={<WalletChatTransactions />}
            />
            <Route
              path="/call-wallet-transactions"
              element={<WalletCallTransactions />}
            />
            <Route
              path="/astrologer-chat-wallet-transaction"
              element={<AstrologerChatWalletTransaction />}
            />
            <Route
              path="/astrologer-call-wallet-transaction"
              element={<AstrologerCallWalletTransaction />}
            />
            <Route
              path="/user-create-payments"
              element={<CreatedUserPayments />}
            />
            <Route
              path="/user-failure-payments"
              element={<FailureUserPayments />}
            />
            <Route
              path="/user-successfull-payments"
              element={<SuccessfullUserPayments />}
            />
            <Route path="/user-chat-room-data" element={<UserChatRooms />} />
            <Route
              path="/astrologer-waitingList"
              element={<AstrologerWaitingListView />}
            />
            <Route path="notification" element={<Notification />} />
            <Route path="saveNotification" element={<SaveNotification />} />
            <Route path="engagement" element={<UserEngagement />} />

            <Route path="/astrologer-stats" element={<AstrologerStats />} />
            <Route path="/astro-stats-fig" element={<AstrologerStatsFig />} />
            <Route path="/categorizedAstrologer" element={<CategorizedAstrologers/>} />
            <Route path="/AstrologerRepeatedUsers" element={<AstrologerRepeatedUsers/>}/>
            <Route path="/AstrologerPreferredTime" element={<PreferredTimeTable/>}/>
            <Route path="/IssuePercentageContainers" element={<IssuePercentageContainer/>}/>
          </Routes>
        </ActiveSectionProvider>
      </div>
    </div>
  );
};

export default Dashboard;
