import { useEffect, useState } from "react";
import BASE_URL from "../config";
const UserCallHistory = ({ userId }) => {
  const [callHistoryData, setCallHistoryData] = useState([]);
  const [callHistoryTotalPages, setCallHistoryTotalPages] = useState(1);
  const [currentCallHistoryPage, setCurrentCallHistoryPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getUserCallHistory = async () => {
    setLoading(true);
    setError(null); // Reset error state before new fetch
    const controller = new AbortController();
    const signal = controller.signal;

    const apiUrl = `${BASE_URL}/user/getUserCall-history-data/${userId}?page=${currentCallHistoryPage}`;

    try {
      const response = await fetch(apiUrl, { signal });
      if (!response.ok) {
        throw new Error(`Failed to fetch data: ${response.statusText}`);
      }

      const data = await response.json();
      const { callHistory, pagination, success } = data;

      if (success) {
        const { totalPages, total } = pagination;
        setCallHistoryData(callHistory);
        setCallHistoryTotalPages(totalPages);
      } else {
        setError("No call history data available for this user.");
      }
    } catch (err) {
      if (err.name === "AbortError") {
        console.log("Fetch request was aborted");
      } else {
        setError(err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserCallHistory();
  }, [currentCallHistoryPage]);

  const handlePageChange = (direction) => {
    if (direction === "prev" && currentCallHistoryPage > 1) {
      setCurrentCallHistoryPage(currentCallHistoryPage - 1);
    } else if (
      direction === "next" &&
      currentCallHistoryPage < callHistoryTotalPages
    ) {
      setCurrentCallHistoryPage(currentCallHistoryPage + 1);
    }
  };

  const renderCallHistoryTable = () => (
    <>
      <div className="astro-table-container pymnts-container">
        <table className="data-table">
          <thead>
            <tr className="user-rows">
              <th>User Name</th>
              <th>Astrologer Name</th>
              <th>From</th>
              <th>To</th>
              <th>Date & Time</th>
              <th>Status</th>
              <th>Recording URL</th>
            </tr>
          </thead>
          <tbody>
            {callHistoryData.map((call, index) => (
              <tr className="astro-rows" key={index}>
                <td>{call.userName}</td>
                <td>{call.astrologerName}</td>
                <td>{call.from}</td>
                <td>{call.to}</td>
                <td>
                  {new Date(call.date).toLocaleString(undefined, {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  })}
                </td>
                <td>{call.status}</td>
                <td>
                  <audio className="user-audio-container" controls>
                    <source src={call.recordingUrl} type="audio/mp3" />
                  </audio>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination">
        <button
          className="pagination-button"
          onClick={() => handlePageChange("prev")}
          disabled={currentCallHistoryPage === 1}
        >
          Prev
        </button>
        <span className="pagination-info">
          Page {currentCallHistoryPage} of {callHistoryTotalPages}
        </span>
        <button
          className="pagination-button"
          onClick={() => handlePageChange("next")}
          disabled={currentCallHistoryPage === callHistoryTotalPages}
        >
          Next
        </button>
      </div>
    </>
  );

  return (
    <div>
      {loading && <p>Loading...</p>}

      {callHistoryData.length > 0 ? (
        renderCallHistoryTable()
      ) : (
        <h1>No Call History Data Found</h1>
      )}
    </div>
  );
};

export default UserCallHistory;
