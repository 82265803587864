import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";
import BASE_URL from "../config";
const PendingCalls = () => {
  const navigate = useNavigate();

  const [currentPendingPage, setCurrentPendingPage] = useState(1);
  const [pendingChatsData, setPendingChatsData] = useState([]);
  const [totalPendingChatsPages, setPendingChatsPages] = useState(null);
  const [totalChats, setTotalNumberOfChats] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoadingRefresh, setIsLoadingRefresh] = useState(false);

  const controller = new AbortController();

  const getPendingChats = async () => {
    setIsLoading(true);

    const apiUrl = `${BASE_URL}/astrologer/get-allwaiting-calls-data?page=${currentPendingPage}`;
    const response = await fetch(apiUrl, { signal: controller?.signal });
    const data = await response.json();

    if (response.ok === true) {
      const { totalChats, totalPages, chats } = data;
      setPendingChatsData(chats);
      setPendingChatsPages(totalPages);
      setTotalNumberOfChats(totalChats);
    }
    setIsLoading(false);
  };

  const handlePendingChatsPage = (direction) => {
    if (direction === "prev" && currentPendingPage > 1) {
      setCurrentPendingPage(currentPendingPage - 1);
    } else if (
      direction === "next" &&
      currentPendingPage < totalPendingChatsPages
    ) {
      setCurrentPendingPage(currentPendingPage + 1);
    }
  };

  const getDurationFromUpdatedAt = (updatedAt) => {
    if (!updatedAt) return "NA";

    const updatedDateTime = new Date(updatedAt);
    const now = new Date();
    const diffMs = now - updatedDateTime;

    if (diffMs < 0) return "0 min";

    const diffMinutes = Math.floor(diffMs / (1000 * 60));
    const diffHours = Math.floor(diffMinutes / 60);
    const diffDays = Math.floor(diffHours / 24);

    const remainingMinutes = diffMinutes % 60;
    const remainingHours = diffHours % 24;

    let duration = "";
    if (diffDays > 0) duration += `${diffDays} day${diffDays > 1 ? "s" : ""} `;
    if (diffHours > 0 || diffDays > 0)
      duration += `${remainingHours} hr${remainingHours > 1 ? "s" : ""} `;
    duration += `${remainingMinutes} min${remainingMinutes > 1 ? "s" : ""}`;

    return duration.trim();
  };

  const handleMove = (chatId) => {
    navigate(`/dashboard/super-astrologers/${chatId}`);
  };

  const regularChatDataTable = (data) => (
    <div className="astro-table-container pymnts-container">
      <table className="astro-table">
        <thead className="astro-he">
          <tr className="astro-bg">
            <th className="he">User Name</th>
            <th className="he">Place of Birth</th>
            <th className="he">Astrologer Name</th>
            <th className="he">Astrologer Status</th>
            <th className="he">Previous Astrologer</th>
            <th className="he">User PhoneNumber</th>
            <th className="he">Astrologer PhoneNumber</th>
            <th className="he">Cost/min</th>
            <th className="he">Waiting Duration (min)</th>
            <th className="he">Move</th>
          </tr>
        </thead>
        <tbody>
          {data.map((chat) => (
            <tr className="astro-rows" key={chat._id}>
              <td className="he">{chat?.userName || "NA"}</td>
              <td className="he">{chat?.place_of_birth}</td>
              <td className="he">{chat?.astrologerId.astrologerName}</td>
              <td className="he">{chat?.astrologerId.status}</td>
              <td className="he">{chat?.prevAstrologerId || "NA"}</td>
              <td className="he">{chat?.from || "NA"}</td>
              <td className="he">{chat?.to || "NA"}</td>
              <td className="he">{chat?.cost || "NA"}</td>
              <td className="he">{getDurationFromUpdatedAt(chat.updatedAt)}</td>
              <td className="he">
                <button
                  className="move-button"
                  onClick={() => handleMove(chat._id)}
                >
                  Move
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  useEffect(() => {
    getPendingChats();
    return () => controller.abort();
  }, [currentPendingPage]);

  const reloadPendingChats = () => {
    const controller = new AbortController();
    getPendingChats(controller);
  };

  return (
    <div>
      <div>
        <input
          type="search"
          placeholder="Search by Username, Astrologer Name, or Phone Number"
          className="search-bar"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button className="btn" onClick={() => reloadPendingChats()}>
          {isLoading === true ? "Loading..." : "Reload"}
        </button>
      </div>

      {isLoading ? (
        <Loader />
      ) : pendingChatsData.length > 0 ? (
        regularChatDataTable(pendingChatsData)
      ) : (
        <h1>Currently No Pending Requests are there!</h1>
      )}

      <div className="pagination">
        <button
          className="pagination-button"
          onClick={() => handlePendingChatsPage("prev")}
          disabled={currentPendingPage === 1}
        >
          Prev
        </button>
        <span className="pagination-info">
          Page {currentPendingPage} of {totalPendingChatsPages}
        </span>
        <button
          className="pagination-button"
          onClick={() => handlePendingChatsPage("next")}
          disabled={currentPendingPage === totalPendingChatsPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default PendingCalls;
