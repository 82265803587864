import { useState, useEffect } from "react";
import "./index.css";
import { TfiReload } from "react-icons/tfi";
import axios from "axios";
import Loader from "../Loader";
import PaymentsViewdetailsPopUp from "../PaymentsViewdetailsPopUp";
import SuccessfullUserPayments from "../UserPayments/SuccessfullUserPayments";
import CreatedUserPayments from "../UserPayments/CreatedUserPayments";
import FailureUserPayments from "../UserPayments/FailureUserPayments";
import FailurePayments from "../AstrologerPayments/FailurePayments";
import AstrologerSuccessfulPayments from "../AstrologerPayments/SuccessfullPayemts";
const Payments = () => {
  const [activeTab, setActiveTab] = useState("users");
  const [transactions, setTransactions] = useState([]);
  const [filter, setFilter] = useState("success");
  // const [totalAmount, setTotalAmount] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 50;
  const [searchTerm, setSearchTerm] = useState("");
  // const [loading, setLoading] = useState(true);

  // const [popupVisible, setPopupVisible] = useState(false);
  // const [popupMessage, setPopupMessage] = useState("");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // const fetchAllTransactions = async (tab) => {
  //   setLoading(true);
  //   try {
  //     const response = await fetch(
  //       "${BASE_URL}/transactions/get-transactions",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           type: "credit",
  //           filter: filter,
  //         }),
  //       }
  //     );

  //     const data = await response.json();

  //     if (data.success) {
  //       const sortedTransactions = data.transactions.sort(
  //         (a, b) => new Date(b.date) - new Date(a.date)
  //       );
  //       setTransactions(sortedTransactions);
  //       setTotalAmount(data.totalAmount);
  //     } else {
  //       setTransactions([]);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching transactions:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchAllTransactions(activeTab);
  // }, [activeTab, filter]);

  const filteredTransactions = transactions
    .filter((transaction) => {
      if (filter === "all") return true;
      return filter === transaction.status.toLowerCase();
    })
    .filter((transaction) => {
      if (activeTab === "users") {
        return transaction.user?.userName
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      } else {
        return transaction.astrologer?.astrologerName
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      }
    });

  // const totalPages = Math.ceil(filteredTransactions.length / rowsPerPage);

  // const paginatedData = filteredTransactions.slice(
  //   (currentPage - 1) * rowsPerPage,
  //   currentPage * rowsPerPage
  // );

  // const handleNextPage = () => {
  //   setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  // };

  // const handlePreviousPage = () => {
  //   setCurrentPage((prev) => Math.max(prev - 1, 1));
  // };

  // const handleToReload = () => {
  //   fetchAllTransactions(activeTab);
  // };

  // const closePopup = () => {
  //   setPopupVisible(false);
  //   setPopupMessage("");
  //   fetchAllTransactions(activeTab);
  // };

  // const handleToOrderdetails = async (userId, orderId, amount, paidStatus) => {
  //   try {
  //     const response = await fetch(
  //       "${BASE_URL}/transactions/updateOrder",
  //       {
  //         method: "PUT",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           orderId: orderId,
  //           userId: userId,
  //           amountPaid: amount,
  //           statusPaid: paidStatus,
  //         }),
  //       }
  //     );

  //     return await response.json();
  //   } catch (error) {
  //     console.error("Error in handleToOrderdetails:", error.message);
  //     throw error;
  //   }
  // };

  // const handleToViewDetails = async (userId, orderId) => {
  //   setPopupVisible(true);
  //   try {
  //     const response = await axios.post(
  //       `https://api.astroping.com/api/razorpay/fetchOrderId/${userId}/${orderId}`
  //     );
  //     // console.log(response)

  //     const { amount, status } = response.data.orderDetails;

  //     if (status == "paid") {
  //       setPopupMessage("Payment Successful");
  //     } else {
  //       setPopupMessage("Order status not paid");
  //     }

  //     await handleToOrderdetails(userId, orderId, amount / 100, status);

  //     return response.data;
  //   } catch (error) {
  //     console.error("An error fetching orderId details:", error.message);
  //   }
  // };

  return (
    <div className="atsto-container">
      <h1 className="atrologers-heading">Payments</h1>

      <div className="tabs">
        <button
          className={`tab-button ${activeTab === "users" ? "active" : ""}`}
          onClick={() => handleTabClick("users")}
        >
          Users
        </button>
        <button
          className={`tab-button ${
            activeTab === "astrologers" ? "active" : ""
          }`}
          onClick={() => handleTabClick("astrologers")}
        >
          Astrologers
        </button>
      </div>

      <div className="filter-container-row margin-fliter">
        <div className="filters">
          <button
            className={`filter-button ${
              filter === "success" ? "active-filter" : ""
            }`}
            onClick={() => setFilter("success")}
          >
            Success
          </button>
          <button
            className={`filter-button ${
              filter === "failure" ? "active-filter" : ""
            }`}
            onClick={() => setFilter("failure")}
          >
            Failure
          </button>
          {activeTab === "users" && (
            <button
              className={`filter-button ${
                filter === "created" ? "active-filter" : ""
              }`}
              onClick={() => setFilter("created")}
            >
              Created
            </button>
          )}
        </div>
      </div>

      {activeTab === "users" && (
        <div className="astro-table-container pymnts-container">
          {filter === "success" && <SuccessfullUserPayments />}
          {filter === "created" && <CreatedUserPayments />}
          {filter === "failure" && <FailureUserPayments />}
        </div>
      )}

      {activeTab === "astrologers" && (
        <div className="astro-table-container pymnts-container">
          {filter === "failure" && <FailurePayments />}
          {filter === "success" && <AstrologerSuccessfulPayments />}
        </div>
      )}
    </div>
  );
};

export default Payments;
