import React from "react";
import { Oval } from "react-loader-spinner";
import './index.css'

const Loader = () => {
    return (
        <div className="loader-container">
            <Oval 
                height={50}
                width={50}
                color="#343434"
                ariaLabel="loading"
                visible={true}
            />
        </div>
    );
};

export default Loader;
