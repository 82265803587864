import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import axios from "axios";
import BASE_URL from "../config";
import "./AstrologerChatStats.css";

const AstrologerChatStats = ({ astrologerId }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear().toString());
  const [years, setYears] = useState([]);
  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const startYear = 2020; 
    const generatedYears = [];
    for (let year = currentYear; year >= startYear; year--) {
      generatedYears.push(year.toString());
    }
    setYears(generatedYears);
  }, []);
  useEffect(() => {
    if (astrologerId) {
      setLoading(true);
      axios
        .post(
          `${BASE_URL}/astrologer/astrologer-yearOfMonths/stats?year=${selectedYear}`,
          { astrologerId }
        )
        .then((response) => {
          const transformedData = transformData(response.data.data);
          setData(transformedData);
          setLoading(false);
        })
        .catch(() => {
          setError("Failed to fetch data.");
          setLoading(false);
        });
    }
  }, [astrologerId, selectedYear]);

  // Transform raw data for the chart
  const transformData = (rawData) => {
    return Object.keys(rawData).flatMap((year) =>
      Object.keys(rawData[year]).map((month) => {
        const monthData = rawData[year][month];
        return {
          month: getMonthName(month),
          completedPaidChats: monthData.completedPaidChats,
          completedFreeChats: monthData.completedFreeChats,
          failedPaidChats: monthData.failedPaidChats,
          failedFreeChats: monthData.failedFreeChats,
        };
      })
    );
  };

  // Convert month number to name
  const getMonthName = (month) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return months[month - 1];
  };

  // Handle year selection change
  const handleYearChange = (e) => setSelectedYear(e.target.value);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  // Custom Tooltip to display all chat types with percentages
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const {
        completedPaidChats,
        completedFreeChats,
        failedPaidChats,
        failedFreeChats,
      } = payload[0].payload;

      const totalChats =
        completedPaidChats +
        completedFreeChats +
        failedPaidChats +
        failedFreeChats;

      const getPercentage = (count) =>
        totalChats > 0 ? ((count / totalChats) * 100).toFixed(2) : 0;

      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            padding: "10px",
          }}
        >
          <p>
            <strong>{label}</strong>
          </p>
          <p>
            <strong>Total Chats: {totalChats}</strong>
          </p>

          {/* Display counts with color indicators and percentages */}
          <div style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
            <span
              style={{
                display: "inline-block",
                width: "12px",
                height: "12px",
                backgroundColor: "#82ca9d",
                marginRight: "8px",
                borderRadius: "2px",
              }}
            ></span>
            Completed Paid Chats: {completedPaidChats} (
            {getPercentage(completedPaidChats)}%)
          </div>
          <div style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
            <span
              style={{
                display: "inline-block",
                width: "12px",
                height: "12px",
                backgroundColor: "#8884d8",
                marginRight: "8px",
                borderRadius: "2px",
              }}
            ></span>
            Completed Free Chats: {completedFreeChats} (
            {getPercentage(completedFreeChats)}%)
          </div>
          <div style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
            <span
              style={{
                display: "inline-block",
                width: "12px",
                height: "12px",
                backgroundColor: "#ff7300",
                marginRight: "8px",
                borderRadius: "2px",
              }}
            ></span>
            Failed Paid Chats: {failedPaidChats} (
            {getPercentage(failedPaidChats)}%)
          </div>
          <div style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
            <span
              style={{
                display: "inline-block",
                width: "12px",
                height: "12px",
                backgroundColor: "#ff0000",
                marginRight: "8px",
                borderRadius: "2px",
              }}
            ></span>
            Failed Free Chats: {failedFreeChats} (
            {getPercentage(failedFreeChats)}%)
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="astrologer-stats">
      <h2>Astrologer Chat Stats</h2>
      <div>
        <label>Select Year: </label>
        <select value={selectedYear} onChange={handleYearChange}>
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>

      {/* Stacked Bar Chart */}
      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={data} barCategoryGap={12}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend />

          {/* Stacked Bars for Each Category */}
          <Bar
            dataKey="completedPaidChats"
            stackId="chats"
            fill="#82ca9d"
            name="Completed Paid Chats"
          />
          <Bar
            dataKey="completedFreeChats"
            stackId="chats"
            fill="#8884d8"
            name="Completed Free Chats"
          />
          <Bar
            dataKey="failedPaidChats"
            stackId="chats"
            fill="#ff7300"
            name="Failed Paid Chats"
          />
          <Bar
            dataKey="failedFreeChats"
            stackId="chats"
            fill="#ff0000"
            name="Failed Free Chats"
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AstrologerChatStats;
