// import { useEffect, useState } from "react";
// import { resolvePath, useNavigate } from "react-router-dom";
// import Loader from "../Loader";
// import PaymentsViewdetailsPopUp from "../PaymentsViewdetailsPopUp";
// import { TfiReload } from "react-icons/tfi";

// const FailurePayments = () => {
//   const [currentSuccessfulPage, setCurrentSuccessfulPage] = useState(1);
//   const [successfulData, setSuccessfulData] = useState([]);
//   const [totalSuccessPaysPages, setTotalSuccessPaysPages] = useState(null);
//   const [totalSuccessPaysCount, setTotalSuccessPaysCount] = useState(null);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [searchResults, setSearchResults] = useState([]);
//   const [isSearching, setIsSearching] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const [selectedDate, setSelectedDate] = useState("");
//   const [popupVisible, setPopupVisible] = useState(false);
//   const [popupMessage, setPopupMessage] = useState("");

//   const getSuccessfulPayments = async () => {
//     const response = await fetch(
//       `${BASE_URL}/transactions/get-transactions-info?page=${currentSuccessfulPage}`,
//       {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           type: "debit",
//           filter: "failure",
//         }),
//       }
//     );
//     const data = await response.json();
//     const { totalAmount, transactions, totalPages } = data;
//     setSuccessfulData(transactions);
//     setTotalSuccessPaysPages(totalPages);
//     setTotalSuccessPaysCount(totalAmount);
//     console.log("successful payments", data);
//   };

//   const closePopup = () => {
//     setPopupVisible(false);
//     setPopupMessage("");
//     getSuccessfulPayments();
//   };

//   const handleToReload = () => {
//     getSuccessfulPayments();
//   };

//   const handleSuccessfulPaymentsPage = (direction) => {
//     if (direction === "prev" && currentSuccessfulPage > 1) {
//       setCurrentSuccessfulPage(currentSuccessfulPage - 1);
//     } else if (
//       direction === "next" &&
//       currentSuccessfulPage < totalSuccessPaysPages
//     ) {
//       setCurrentSuccessfulPage(currentSuccessfulPage + 1);
//     }
//   };

// const successfulPaymentsDataTable = () => (
//   <div className="astro-table-container pymnts-container">
//     {isLoading ? (
//       <Loader />
//     ) : (
//       <table className="astro-table">
//         <thead className="astro-he">
//           <tr className="astro-bg">
//             <th className="he">Order Id</th>
//             <th className="he">Astrologer Name</th>
//             <th className="he">Astrologer Phone Number</th>
//             <th className="he">Date & Time</th>
//             <th className="he">Amount</th>
//             <th className="he">Status</th>
//           </tr>
//         </thead>
//         <tbody>
//           {successfulData.map((transaction) => (
//             <tr key={transaction._id} className="astro-rows">
//               <td className="he">
//                 {transaction.paymentId || "N/A"}
//               </td>
//               <td className="he">
//                 {transaction.astrologer?.astrologerName || "N/A"}
//               </td>
//               <td className="he">
//                 {transaction?.astrologerPhoneNumber || "N/A"}
//               </td>
//               <td className="he">{transaction.date}</td>
//               <td className="he">{transaction.amount}</td>
//               <td className="he">{transaction.status}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     )}
//   </div>
// );

//   useEffect(() => {
//     if (isSearching) return;
//     getSuccessfulPayments();
//   }, [currentSuccessfulPage]);

//   //   useEffect(() => {
//   //     const debounceTimer = setTimeout(() => {
//   //       searchChats();
//   //     }, 300); // Debounce time: 300ms
//   //     return () => clearTimeout(debounceTimer);
//   //   }, [searchQuery]);

//   return (
//     <div>
//       <div className="astro-search-container">
//         <input
//           type="text"
//           placeholder="Search by Astrologer Name or Phone number"
//           className="astro-search-input"
//           value={searchTerm}
//           onChange={(e) => setSearchTerm(e.target.value)}
//         />
//       </div>

//       <div className="total-and-reload-cnt">
//         <h1 className="total-amount-payments">
//           Total Amount: {totalSuccessPaysCount}
//         </h1>

//         <TfiReload onClick={handleToReload} className="reload-icon" size={30} />
//       </div>
//       <div className="date-picker-container">
//         <h3 className="date-picker-heading">Select a Date</h3>
//         <input
//           type="date"
//           value={selectedDate}
//           onChange={(event) => setSelectedDate(event.target.value)}
//           className="date-input"
//         />
//         <button className="date-clear" onClick={() => setSelectedDate("")}>
//           Clear
//         </button>
//         {selectedDate && (
//           <p className="selected-date-text">{`Selected date: ${selectedDate}`}</p>
//         )}
//       </div>
//       {successfulPaymentsDataTable()}

//       <div className="pagination">
//         <button
//           className="pagination-button"
//           onClick={() => handleSuccessfulPaymentsPage("prev")}
//           disabled={currentSuccessfulPage === 1}
//         >
//           Prev
//         </button>
//         <span className="pagination-info">
//           Page {currentSuccessfulPage} of {totalSuccessPaysPages}
//         </span>
//         <button
//           className="pagination-button"
//           onClick={() => handleSuccessfulPaymentsPage("next")}
//           disabled={currentSuccessfulPage === totalSuccessPaysPages}
//         >
//           Next
//         </button>
//       </div>
//     </div>
//   );
// };

// export default FailurePayments;

import { useEffect, useState } from "react";
import Loader from "../Loader";
import PaymentsViewdetailsPopUp from "../PaymentsViewdetailsPopUp";
import { TfiReload } from "react-icons/tfi";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../config";
const FailurePayments = () => {
  const [currentSuccessfulPage, setCurrentSuccessfulPage] = useState(1);
  const [successfulData, setSuccessfulData] = useState([]);
  const [totalSuccessPaysPages, setTotalSuccessPaysPages] = useState(null);
  const [totalSuccessPaysCount, setTotalSuccessPaysCount] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const navigate = useNavigate();

  const [abortController, setAbortController] = useState(null);

  const [popupVisible, setPopupVisible] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  // Debouncer for search
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300); // Debounce time: 300ms
    return () => clearTimeout(timer);
  }, [searchTerm]);

  const fetchPayments = async (isSearch = false) => {
    if (abortController) abortController.abort();

    const controller = new AbortController();
    setAbortController(controller);

    setIsLoading(true);
    try {
      const endpoint = isSearch
        ? `${BASE_URL}/transactions/search-transactions`
        : `${BASE_URL}/transactions/get-transactions-info`;
      const body = isSearch
        ? {
            search: debouncedSearchTerm,
            type: "debit",
            filter: "failure",
          }
        : {
            type: "debit",
            filter: "failure",
          };
      const response = await fetch(
        `${endpoint}?page=${currentSuccessfulPage}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
          signal: controller?.signal,
        }
      );
      const data = await response.json();
      const { totalAmount, transactions, totalPages } = data;
      console.log("successful transactions", transactions);
      setSuccessfulData(transactions);
      setTotalSuccessPaysPages(totalPages);
      setTotalSuccessPaysCount(totalAmount);
    } catch (error) {
      console.error("Error fetching payments:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPaymentsByDate = async () => {
    if (!selectedDate) return;
    setIsLoading(true);
    try {
      const response = await fetch(
        `${BASE_URL}/transactions/get-payments-by-date?page=${currentSuccessfulPage}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            type: "debit",
            filter: "failure",
            date: selectedDate,
          }),
        }
      );
      const data = await response.json();
      const { totalAmount, transactions, totalPages } = data;

      setSuccessfulData(transactions);
      setTotalSuccessPaysPages(totalPages);
      setTotalSuccessPaysCount(totalAmount);
    } catch (error) {
      console.error("Error fetching payments by date:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleToReload = () => {
    setSearchTerm("");
    setDebouncedSearchTerm("");
    setSelectedDate(""); // Clear date on reload
    setCurrentSuccessfulPage(1);
    fetchPayments();
  };

  const closePopup = () => {
    setPopupVisible(false);
    setPopupMessage("");
    fetchPayments();
  };

  const handleSuccessfulPaymentsPage = (direction) => {
    if (direction === "prev" && currentSuccessfulPage > 1) {
      setCurrentSuccessfulPage((prev) => prev - 1);
    } else if (
      direction === "next" &&
      currentSuccessfulPage < totalSuccessPaysPages
    ) {
      setCurrentSuccessfulPage((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (selectedDate) {
      fetchPaymentsByDate();
    } else {
      fetchPayments(!!debouncedSearchTerm);
    }

    return () => {
      if (abortController) abortController.abort();
    };
  }, [debouncedSearchTerm, currentSuccessfulPage, selectedDate]);

  const successfulPaymentsDataTable = () => (
    <div className="astro-table-container pymnts-container">
      {isLoading ? (
        <Loader />
      ) : (
        <table className="astro-table">
          <thead className="astro-he">
            <tr className="astro-bg">
              <th className="he">Order Id</th>
              <th className="he">Astrologer Name</th>
              <th className="he">Astrologer Phone Number</th>
              <th className="he">Date & Time</th>
              <th className="he">Amount</th>
              <th className="he">Status</th>
            </tr>
          </thead>
          <tbody>
            {successfulData.map((transaction) => (
              <tr key={transaction._id} className="astro-rows">
                <td className="he">{transaction.paymentId || "N/A"}</td>
                <td
                  className="he"
                  onClick={() =>
                    navigate(
                      `/dashboard/astrologer/${transaction?.astrologer?._id}`,
                      {
                        state: { astrologer: transaction?.astrologer },
                      }
                    )
                  }
                  style={{ color: "blue", cursor: "pointer" }}
                >
                  {transaction?.astrologer?.astrologerName || "NA"}
                </td>
                <td className="he">
                  {transaction?.astrologerPhoneNumber || "N/A"}
                </td>
                <td className="he">{transaction.date}</td>
                <td className="he">{transaction.amount}</td>
                <td className="he">{transaction.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );

  return (
    <div>
      <div className="astro-search-container">
        <input
          type="text"
          placeholder="Search by Name"
          className="astro-search-input"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <div className="total-and-reload-cnt">
        <h1 className="total-amount-payments">
          Total Amount: {totalSuccessPaysCount}
        </h1>
        <TfiReload onClick={handleToReload} className="reload-icon" size={30} />
      </div>
      <div className="date-picker-container">
        <h3 className="date-picker-heading">Select a Date</h3>
        <input
          type="date"
          value={selectedDate}
          onChange={(event) => setSelectedDate(event.target.value)}
          className="date-input"
        />
        <button className="date-clear" onClick={() => setSelectedDate("")}>
          Clear
        </button>
        {selectedDate && (
          <p className="selected-date-text">{`Selected date: ${selectedDate}`}</p>
        )}
      </div>
      {successfulPaymentsDataTable()}

      <div className="pagination">
        <button
          className="pagination-button"
          onClick={() => handleSuccessfulPaymentsPage("prev")}
          disabled={currentSuccessfulPage === 1}
        >
          Prev
        </button>
        <span className="pagination-info">
          Page {currentSuccessfulPage} of {totalSuccessPaysPages}
        </span>
        <button
          className="pagination-button"
          onClick={() => handleSuccessfulPaymentsPage("next")}
          disabled={currentSuccessfulPage === totalSuccessPaysPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default FailurePayments;
