import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../config";
const FailedCalls = () => {
  const [currentCompletedCallPage, setCurrentCallPage] = useState(1);
  const [totalCompletedCalls, setTotalCompletedCalls] = useState(0);
  const [completedCallsData, setCompletedCallsData] = useState([]);
  const [totalCompletedCallPages, setTotalCompletedCallPages] = useState(0);
  const [isLoadingRefresh, setIsLoadingRefresh] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [dateFilteredData, setDateFilteredData] = useState([]);
  const navigate = useNavigate();

  // Debounce logic for search
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 300); // Adjust the debounce delay as needed
    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  const getCompletedCalls = async (signal) => {
    setIsLoadingRefresh(true);

    const completedCallsApiUrl = debouncedSearchQuery
      ? `${BASE_URL}/user/search-exotel-callbacks?search=${debouncedSearchQuery}&page=${currentCompletedCallPage}`
      : `${BASE_URL}/user/get-failed-exotel-callbacks-data?page=${currentCompletedCallPage}`;

    const requestBody = {
      status: "busy",
    };

    try {
      let response;

      if (debouncedSearchQuery) {
        // POST request for search with query
        response = await fetch(completedCallsApiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
          signal,
        });
      } else {
        // GET request for regular data
        response = await fetch(completedCallsApiUrl, signal);
      }

      const data = await response.json();
      console.log("Completed calls: ", data);

      if (data.success) {
        const { totalItems, callbackHistory, totalPages } = data;
        setTotalCompletedCalls(totalItems || data.totalResults);
        setCompletedCallsData(callbackHistory || data.CompletedChats);
        setTotalCompletedCallPages(
          totalPages || Math.ceil(data.totalResults / 10)
        );
      }
    } catch (error) {
      console.error("Error fetching completed calls: ", error);
    }

    setIsLoadingRefresh(false);
  };

  const getFilteredCallsByDate = async (signal) => {
    if (!selectedDate) {
      alert("Please select a date.");
      return;
    }

    setIsLoadingRefresh(true);
    const apiUrl = `${BASE_URL}/user/get-callbacks-by-date?page=${currentCompletedCallPage}`;

    const requestBody = {
      date: selectedDate,
      status: "busy",
    };

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
        signal,
      });

      const data = await response.json();
      console.log("Filtered calls by date: ", data);

      if (data.success) {
        const { totalItems, callbackHistory, totalPages } = data;
        setDateFilteredData(callbackHistory);
        setTotalCompletedCalls(totalItems);
        setTotalCompletedCallPages(totalPages);
      }
    } catch (error) {
      console.error("Error fetching filtered calls by date: ", error);
    }

    setIsLoadingRefresh(false);
  };

  const handlePageChange = (direction) => {
    if (direction === "prev" && currentCompletedCallPage > 1) {
      setCurrentCallPage(currentCompletedCallPage - 1);
    } else if (
      direction === "next" &&
      currentCompletedCallPage < totalCompletedCallPages
    ) {
      setCurrentCallPage(currentCompletedCallPage + 1);
    }
  };

  const convertTo12Hour = (time) => {
    if (!time) return "NA";
    let [hours, minutes] = time.split(":");
    hours = parseInt(hours, 10);

    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;

    return `${hours}:${minutes} ${ampm}`;
  };

  const renderCallTable = (data) => (
    <div className="astro-table-container pymnts-container">
      <table className="astro-table">
        <thead className="astro-he">
          <tr className="astro-bg">
            <th className="he">User Name</th>
            <th className="he">User Phone Number</th>
            <th className="he">Astrologer Name</th>
            <th className="he">Astrologer Phone Number</th>
            <th className="he">Date/Time</th>
            <th className="he">Cost/min</th>
            <th className="he">Recording</th>
          </tr>
        </thead>
        <tbody>
          {data.map((call) => {
            const updatedAt = new Date(call.updatedDate).toLocaleString();
            const formattedDate = new Date(
              call.createdAt || call.date
            ).toLocaleDateString("en-IN");
            const formattedTime = call.time
              ? convertTo12Hour(call.time)
              : new Date(call.createdAt || call.date).toLocaleTimeString(
                  "en-IN",
                  { hour: "numeric", minute: "numeric", hour12: true }
                );

            return (
              <tr className="livecalls-table-rows" key={call._id || call.id}>
                <td
                  className="he"
                  onClick={() =>
                    navigate(`/dashboard/user/${call?.userId}`, {
                      state: {
                        user: {
                          _id: call?.userId,
                          userName: call?.userName,
                          phone_number: call?.from,
                        },
                      },
                    })
                  }
                  style={{ color: "blue", cursor: "pointer" }}
                >
                  {call?.userName || "NA"}
                </td>

                <td className="he">{call.from || "NA"}</td>
                <td
                  className="he"
                  onClick={() =>
                    navigate(`/dashboard/astrologer/${call?.userId}`, {
                      state: {
                        astrologer: {
                          _id: call?.astrologerId,
                          astrologerName: call?.astrologerName,
                          status: call?.status,
                          profile_photo: call?.profile_photo,
                          category: call?.category,
                          known_languages: call?.known_languages,
                          expertIn: call?.expertIn,
                          experience: call?.experience,
                          order_bookings: call?.order_bookings,
                          cost: call?.cost,
                          wallet: call?.wallet,
                          createdAt: call?.createdAt,
                        },
                      },
                    })
                  }
                  style={{ color: "blue", cursor: "pointer" }}
                >
                  {call?.astrologerName || "NA"}
                </td>
                <td className="he">{call.to || "NA"}</td>
                <td className="he">
                  {formattedDate} {formattedTime}
                </td>
                <td className="he">{call.cost || call.callCost || "NA"}</td>
                <td className="he">
                  <audio className="audio-container" controls>
                    <source src={call.recordingUrl} type="audio/mp3" />
                  </audio>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination">
        <button
          className="pagination-button"
          onClick={() => handlePageChange("prev")}
          disabled={currentCompletedCallPage === 1}
        >
          Previous
        </button>
        <span className="pagination-info">
          Page {currentCompletedCallPage} of {totalCompletedCallPages}
        </span>
        <button
          className="pagination-button"
          onClick={() => handlePageChange("next")}
          disabled={currentCompletedCallPage === totalCompletedCallPages}
        >
          Next
        </button>
      </div>
    </div>
  );

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller?.signal;

    if (selectedDate) {
      getFilteredCallsByDate(signal);
    } else {
      getCompletedCalls(signal);
    }
  }, [currentCompletedCallPage, debouncedSearchQuery, selectedDate]);

  return (
    <div>
      <div>
        <input
          type="search"
          placeholder="Search by Username, Astrologer Name, or Phone Number"
          className="search-bar"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button className="btn" onClick={getCompletedCalls}>
          {isLoadingRefresh ? "Loading..." : "Reload"}
        </button>
      </div>
      <h2>{`Total: ${totalCompletedCalls}`}</h2>
      <div className="date-picker-container">
        <h3 className="date-picker-heading">Select a Date</h3>
        <input
          type="date"
          value={selectedDate}
          onChange={(event) => {
            setSelectedDate(event.target.value);
          }}
          className="date-input"
        />
        <button
          className="date-clear"
          onClick={() => {
            setSelectedDate("");
            setDateFilteredData([]);
          }}
        >
          Clear
        </button>
        {selectedDate && (
          <p className="selected-date-text">{`Selected date: ${selectedDate}`}</p>
        )}
      </div>
      {selectedDate
        ? renderCallTable(dateFilteredData)
        : renderCallTable(completedCallsData)}
    </div>
  );
};

export default FailedCalls;

// const completedCallsApiUrl = debouncedSearchQuery
//       ? `${BASE_URL}/user/search-exotel-callbacks?search=${debouncedSearchQuery}&page=${currentCompletedCallPage}`
//       : `${BASE_URL}/user/get-failed-exotel-callbacks-data?page=${currentCompletedCallPage}`;
