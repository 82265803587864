import React, { useState, useEffect } from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import BASE_URL from "./config";
ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = () => {
  const [withdrawals, setWithdrawals] = useState(new Array(12).fill(0)); // Default values for all months

  // Function to fetch withdrawals data
  const getWithdrawalsData = async (signal) => {
    const year = 2024; // Set the year here
    const withdrawalsApi = `${BASE_URL}/transactions/get-withdrawals?year=${year}`;
    try {
      const response = await fetch(withdrawalsApi, { signal });
      if (!response.ok) {
        throw new Error("Failed to fetch withdrawals data");
      }
      const data = await response.json();
      const { monthlyWithdrawals } = data;
      setWithdrawals(monthlyWithdrawals);
    } catch (error) {
      console.error("Error fetching withdrawals data:", error.message);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller?.signal;
    getWithdrawalsData(signal);
  }, []);

  const data = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Withdrawals",
        data: withdrawals,
        backgroundColor: [
          "#264653",
          "#2A9D8F",
          "#E9C46A",
          "#F4A261",
          "#E76F51",
          "#D62828",
          "#023047",
          "#8D99AE",
          "#F77F00",
          "#06D6A0",
          "#118AB2",
          "#EF476F",
        ],
        borderColor: "#f0f0f0",
        borderWidth: 1,
        hoverOffset: 12,
        hoverBorderColor: "#333",
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "right",
        labels: {
          usePointStyle: true,
          padding: 24,
          font: {
            size: 12,
            family: "Helvetica",
          },
        },
      },
      tooltip: {
        backgroundColor: "#fff",
        titleColor: "#333",
        bodyColor: "#333",
        borderWidth: 1,
        borderColor: "#ccc",
        padding: 13,
        callbacks: {
          label: function (tooltipItem) {
            return `Withdrawals: ${tooltipItem.raw}`;
          },
        },
      },
    },
  };

  return (
    <div
      style={{
        width: "300px",
        height: "280px",
        margin: "10px auto",
        padding: "30px",
        backgroundColor: "#fff",
        borderRadius: "16px",
        boxShadow: "0 4px 16px rgba(0, 0, 0, 0.15)",
      }}
    >
      <h3
        style={{
          textAlign: "center",
          marginBottom: "10px",
          color: "#264653",
          fontSize: "16px",
          fontWeight: "bold",
          letterSpacing: "0.5px",
        }}
      >
        Monthly Withdrawals of 2024
      </h3>
      <Pie data={data} options={options} />
    </div>
  );
};

export default PieChart;
