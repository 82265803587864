import { useEffect, useState } from "react";
import BASE_URL from "../config";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";
const AstrologerRepeatedUsers = ({ astrologer }) => {
  const navigate = useNavigate();
  const [repeatedUsers, setRepeatedUsers] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalNumberOfTransactions, setTotalNumberOfTransactions] = useState(0);
  const [usersCount, setUsersCount] = useState(0);
  const [repeatedRate, setRepeatedRate] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const getRepeatedUsers = async () => {
    try {
      setIsLoading(true);
      const repeatedUsersApiUrl = `${BASE_URL}/astrologer/transactions-by-astrologer/${astrologerId}?page=${currentPage}`;
      const response = await fetch(repeatedUsersApiUrl);
      const data = await response.json();
      console.log("astrologer repeated users", data);
      if (!response.ok) {
        throw new Error(
          data.message || "Failed to fetch astrologer repeated users."
        );
        setIsLoading(false);
      } else {
        setIsLoading(false);
        const {
          totalPages,
          repeatedUsers,
          totalTransactions,
          repeatedUserCount,
          repeatedRate,
        } = data;
        setTotalPages(totalPages);
        setRepeatedUsers(repeatedUsers);
        setTotalNumberOfTransactions(totalTransactions);
        setUsersCount(repeatedUserCount);
        setRepeatedRate(repeatedRate);
      }
    } catch (error) {
      console.log("Error fetching astrologer repeated users", error);
      setIsLoading(false);
    }
  };

  const handlePageChange = (direction) => {
    if (direction === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (direction === "next" && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const renderRepeatedUsersTable = () => (
    <>
     <h3>{`Repeat Rate:${repeatedRate.toFixed(2)}%`}</h3>
      <div className="astro-table-container pymnts-container">
      
        <table className="astro-table">
          <thead className="astro-he">
            <tr className="astro-bg">
              <th className="he">User Name</th>

              <th className="he">User PhoneNumber</th>

              <th className="he">Repeated Chat Count</th>
            </tr>
          </thead>
          <tbody>
            {repeatedUsers?.map((chat) => (
              <tr className="astro-rows" key={chat._id}>
                <td
                  className="he"
                  onClick={() =>
                    navigate(`/dashboard/user/${chat.user?._id}`, {
                      state: { user: chat.user },
                    })
                  }
                  style={{ color: "blue", cursor: "pointer" }}
                >
                  {chat?.name || "NA"}
                </td>
                <td className="he">{chat?.phoneNumber || "NA"}</td>
                <td className="he">{chat.transactionCount || "NA"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination">
        <button
          className="pagination-button"
          onClick={() => handlePageChange("prev")}
          disabled={currentPage === 1}
        >
          Prev
        </button>
        <span className="pagination-info">
          Page {currentPage} of {totalPages}
        </span>
        <button
          className="pagination-button"
          onClick={() => handlePageChange("next")}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </>
  );

  useEffect(() => {
    getRepeatedUsers();
  }, [currentPage]);
  const astrologerId = astrologer._id;
  console.log("astrologer value of repeated", astrologer);
  return <div>{isLoading ? <Loader /> : renderRepeatedUsersTable()}</div>;
};
export default AstrologerRepeatedUsers;
