import { useEffect, useState } from "react";
import { IoCheckmarkDoneCircle } from "react-icons/io5";
import { refundAmount } from "../api/walletApi";
import WalletPopUp from "../WalletPopUp";
import RefundSuccessPopup from "../RefundSuccessPopup";
import "./WalletChatTransactions.css";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../config";
const WalletChatTransactions = () => {
  const [currentAllTransactionsPage, setAllTransactionsCurrentPage] =
    useState(1);
  const [totalAllTransactionsPages, setAllTotalTransactionsPages] =
    useState(null);
  const [allTransactionsData, setAllTransactionsData] = useState([]);
  const [totalNumberOfTransactions, setTotalNumberOfTransactions] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [callBackData, setCallBackData] = useState({});
  const [popupMessage, setPopupMessage] = useState("");
  const [popupVisible, setPopupVisible] = useState(false);
  const [refundSuccessful, setRefundSuccessful] = useState(false);
  const [successPercentage, setSuccessPercentage] = useState();
  const [selectedDate, setSelectedDate] = useState(""); // State for date picker
  const navigate = useNavigate();

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500); // Debounce time for search

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller?.signal;
    getWalletAllTransactions(signal);
    return () => {
      controller.abort();
    };
  }, [currentAllTransactionsPage, debouncedSearchTerm, selectedDate]);

  const getWalletAllTransactions = async (signal) => {
    try {
      const baseUrl = `${BASE_URL}/wallet`;
      let url;

      // Construct the URL based on search and date filters
      if (debouncedSearchTerm) {
        url = `${baseUrl}/search-chat-transactions?search=${debouncedSearchTerm}`;
      } else if (selectedDate) {
        url = `${baseUrl}/chat-transactions-by-date?date=${selectedDate}&page=${currentAllTransactionsPage}`;
      } else {
        url = `${baseUrl}/chat-transactions?page=${currentAllTransactionsPage}`;
      }

      const response = await fetch(url, { signal });
      const data = await response.json();
      const { transactions, pagination, success } = data;

      if (success) {
        if (pagination) {
          const { totalPages, totalTransactions } = pagination;
          setTotalNumberOfTransactions(totalTransactions);
          setAllTotalTransactionsPages(totalPages);
        }
        setAllTransactionsData(transactions);
      }
    } catch (error) {
      console.error("Error fetching transactions:", error);
    }
  };

  const handleToChangeEdit = (data) => {
    setCallBackData(data);
    setPopupMessage(`Do you want to Refund this Amount?`);
    setPopupVisible(true);
  };

  const closePopup = () => {
    setPopupVisible(false);
    setPopupMessage("");
  };

  const handleRefundSuccess = async (percentage) => {
    try {
      const result = await refundAmount(
        callBackData.id,
        callBackData.userId,
        callBackData.astrologerId,
        callBackData.amount,
        percentage
      );
      setRefundSuccessful(true);
      setSuccessPercentage(percentage);
    } catch (error) {
      console.error("An error occurred: " + error.message);
    }
    closePopup();
  };

  const handleSuccessMessageDismiss = () => {
    setRefundSuccessful(false);
    getWalletAllTransactions();
  };

  console.log("");

  const handlePageChange = (direction) => {
    if (direction === "prev" && currentAllTransactionsPage > 1) {
      setAllTransactionsCurrentPage(currentAllTransactionsPage - 1);
    } else if (
      direction === "next" &&
      currentAllTransactionsPage < totalAllTransactionsPages
    ) {
      setAllTransactionsCurrentPage(currentAllTransactionsPage + 1);
    }
  };

  const WalletAllTransactionsTable = () => (
    <>
      <div className="astro-table-container pymnts-container">
        <table className="astro-table">
          <thead className="astro-he">
            <tr className="astro-bg">
              <th className="he">Username</th>
              <th className="he">User Number</th>
              <th className="he">Astrologer Name</th>
              <th className="he">Pre-consultation Wallet Balance</th>
              <th className="he">Post-consultation Wallet Balance</th>
              <th className="he">Type</th>
              <th className="he">Date-Time</th>
              <th className="he">Session Amount</th>
              <th className="he">Session Time(MIN)</th>
              <th className="he">Refund</th>
            </tr>
          </thead>
          <tbody>
            {allTransactionsData.map((callBackData) => (
              <tr className="astro-rows" key={callBackData.id}>
                <td
                  className="he"
                  onClick={() =>
                    navigate(`/dashboard/user/${callBackData?.userId}`, {
                      state: {
                        user: {
                          _id: callBackData?.userId,
                          userName: callBackData?.userName,
                          phone_number: callBackData?.userNumber,
                        },
                      },
                    })
                  }
                  style={{ color: "blue", cursor: "pointer" }}
                >
                  {callBackData?.userName || "Loading..."}
                </td>
                <td className="he">{callBackData.userNumber}</td>
                <td
                  className="he"
                  onClick={() => {
                    navigate(
                      `/dashboard/astrologer/${callBackData.astrologerId}`,
                      {
                        state: { astrologer: callBackData.astrologer },
                      }
                    );
                  }}
                  style={{ color: "blue", cursor: "pointer" }}
                >
                  {callBackData?.astrologerName || "NA"}
                </td>
                <td className="he">{callBackData.beforeUserWalletBalance}</td>
                <td className="he">{callBackData.afterUserWalletBalance}</td>
                <td className="he">{callBackData.type}</td>
                <td className="he wallet-date-time">
                  {new Date(callBackData.date).toLocaleString()}
                </td>
                <td className="he">{callBackData.amount}</td>
                <td className="he">{callBackData.session_time}</td>
                <td className="he">
                  {callBackData.refund === false ? (
                    new Date() - new Date(callBackData.date) <=
                    48 * 60 * 60 * 1000 ? (
                      <button
                        className="edit-button"
                        onClick={() => handleToChangeEdit(callBackData)}
                      >
                        Edit
                      </button>
                    ) : (
                      <p className="not-available-paragraph">
                        Not available for refund
                      </p>
                    )
                  ) : (
                    <p className="refund-paragraph">
                      {callBackData.refundPercentage || "0"}% Refund{" "}
                      <IoCheckmarkDoneCircle />
                    </p>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination">
        <button
          className="pagination-button"
          onClick={() => handlePageChange("prev")}
          disabled={currentAllTransactionsPage === 1}
        >
          Prev
        </button>
        <span className="pagination-info">
          Page {currentAllTransactionsPage} of {totalAllTransactionsPages}
        </span>
        <button
          className="pagination-button"
          onClick={() => handlePageChange("next")}
          disabled={currentAllTransactionsPage === totalAllTransactionsPages}
        >
          Next
        </button>
      </div>
      {popupVisible && (
        <WalletPopUp
          message={popupMessage}
          onCancel={closePopup}
          onRefundSuccess={handleRefundSuccess}
          callBackData={callBackData}
        />
      )}
      {refundSuccessful && (
        <RefundSuccessPopup
          successPercentage={successPercentage}
          onClose={handleSuccessMessageDismiss}
        />
      )}
    </>
  );

  return (
    <>
      <div className="search-container">
        <input
          type="text"
          placeholder="Search by Username, User Number, or Astrologer Name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="astro-search-input wlt-input"
        />
      </div>
      <h3>{`Total:${totalNumberOfTransactions}`}</h3>
      <div className="date-picker-container-wallet">
        <div>
          <h4 className="">Select a Date</h4>{" "}
        </div>
        <div className="date-picker-inputs">
          <input
            type="date"
            value={selectedDate}
            onChange={(event) => setSelectedDate(event.target.value)}
            className="date-input"
          />
          <div>
            <button className="date-clear" onClick={() => setSelectedDate("")}>
              Clear
            </button>
          </div>
        </div>
        {selectedDate && (
          <p className="selected-date-text">{`Selected date: ${selectedDate}`}</p>
        )}
      </div>
      <div>{WalletAllTransactionsTable()}</div>
    </>
  );
};

export default WalletChatTransactions;
