import { useContext, useState } from "react";
import TodayLoginTime from "../TodayLoginTime/TodayLoginTime";
import AstrologerLoginHistory from "../AstrologerLoginHistory/AstrologerLoginHistory";
import AstrologerChatDuration from "../Astrologers/AstrologerChatDuration";
import AstrologerStats from "../AstrologerStats/AstrologerStats";
import CallStats from "../AstrologerStats/AstrologerCallStats";
import AstrologerCallDuration from "../Astrologers/AstrologerCallDuration";
import AstrologerRepeatedUsers from "../Astrologers/AstrologerRepeatedUsers";
import AstrologerChatStats from "../Astrologers/AstrologerChatStats";
const AstrologerStatsFig = ({ astrologer }) => {
  const [currentActiveSection, setActiveCurrentSection] =
    useState("Chat Duration");

  const [fromSelectedDate, setSelectedFromDate] = useState("");
  const [toSelectedDate,setToSelectedDate] = useState("")
  const [filter, setFilter] = useState("chat");
  const [defaultDate,setDefaultDatePickerDate] = useState("")
  const [defaultToDate,setDefaultToDatePickerDate] = useState("")
  const [dateRange,setDateRange] = useState({from: "", to: ""})
  const handleToSubmitDateRange = (fromSelectedDate, toSelectedDate) => {
    setDateRange({from: fromSelectedDate, to: toSelectedDate})
  }
  console.log("global selected date", dateRange);

  const handleSelectedFromDateFormat = (date) => {
    setDefaultDatePickerDate(date)
    if (!date) return "";
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = d.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    setSelectedFromDate(formattedDate)
  };

  const handleSelectedToDateFormat = (date) => {
    setDefaultToDatePickerDate(date)
    if (!date) return "";
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = d.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    setToSelectedDate(formattedDate)
  };

  const handleToClearDate = () => {
    setSelectedFromDate("");
    setDefaultDatePickerDate("")
    setDefaultToDatePickerDate("")
    setDateRange({from: "", to: ""})
  };

  return (
    <div>
      <div className="date-picker-container-wallet">
        <div>
          <h4 className="">Select a Range of Date</h4>{" "}
        </div>
        <div className="date-picker-inputs-container">
          <h3>From:</h3>
          <input
            type="date"
            value={defaultDate}
            onChange={(event) => handleSelectedFromDateFormat(event.target.value)}
            className="date-input-picker"
          />
          <h3>To:</h3>
          <input
            type="date"
            value={defaultToDate}
            onChange={(event) => handleSelectedToDateFormat(event.target.value)}
            className="date-input-picker"
          />
          <div>
            <button className="date-clear-button" onClick={() => handleToClearDate("")}>
              Clear
            </button>
            <button className="date-clear-button" onClick={() => handleToSubmitDateRange(fromSelectedDate, toSelectedDate)}>
              Apply Filter
            </button>
          </div>
        </div>
        {dateRange.from  && dateRange.to &&  (
          <p className="selected-date-text">{`Selected date range: From:- ${dateRange.from} To:- ${dateRange.to}`}</p>
        )}
      </div>
      <div className="Status-panel-container">
       
        <button
          className={`btn ${
            currentActiveSection === "Chat Duration" ? "activeTabSection" : ""
          }`}
          onClick={() => setActiveCurrentSection("Chat Duration")}
        >
          Chat Duration
        </button>
        <button
          className={`btn ${
            currentActiveSection === "Call Duration" ? "activeTabSection" : ""
          }`}
          onClick={() => setActiveCurrentSection("Call Duration")}
        >
          Call Duration
        </button>
        <button
          className={`btn ${
            currentActiveSection === "Login History" ? "activeTabSection" : ""
          }`}
          onClick={() => setActiveCurrentSection("Login History")}
        >
          Login History
        </button>
        <button
          className={`btn ${
            currentActiveSection === "Chat/Call Stats" ? "activeTabSection" : ""
          }`}
          onClick={() => setActiveCurrentSection("Chat/Call Stats")}
        >
          Chat/Call Stats
        </button>
        <button
          className={`btn ${
            currentActiveSection === "Chats stats" ? "activeTabSection" : ""
          }`}
          onClick={() => setActiveCurrentSection("Chats stats")}
        >
          Chats stats
        </button>
        <button
          className={`btn ${
            currentActiveSection === "Repeated Users" ? "activeTabSection" : ""
          }`}
          onClick={() => setActiveCurrentSection("Repeated Users")}
        >
          Repeated Users
        </button>
      </div>
      <div>
        <div>
          {currentActiveSection === "Login History" && (
            <AstrologerLoginHistory astrologerId={astrologer._id} dateRange={dateRange}/>
          )}
          {currentActiveSection === "Chat Duration" && (

            <AstrologerChatDuration astrologerId={astrologer._id} dateRange={dateRange} />
          )}
          {currentActiveSection === "Call Duration" && (
            <AstrologerCallDuration astrologerId={astrologer._id} dateRange={dateRange} />
          )}
          {currentActiveSection === "Repeated Users" && (
            <AstrologerRepeatedUsers astrologer={astrologer} />
          )}
          {currentActiveSection === "Chats stats" && (
            <AstrologerChatStats astrologerId={astrologer._id} />
          )}
          {currentActiveSection === "Chat/Call Stats" && (
            <>
              <div className="filters">
                <button
                  className={`filter-button ${
                    filter === "chat" ? "active-filter" : ""
                  }`}
                  onClick={() => setFilter("chat")}
                >
                  Chat
                </button>
                <button
                  className={`filter-button ${
                    filter === "call" ? "active-filter" : ""
                  }`}
                  onClick={() => setFilter("call")}
                >
                  Call
                </button>
              </div>
              {filter === "chat" && <AstrologerStats astrologer={astrologer} />}
              {filter === "call" && <CallStats astrologer={astrologer} />}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default AstrologerStatsFig;
