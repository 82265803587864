import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";
import "./Users.css";
import BASE_URL from "../config";
const Users = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 50;
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();
  const [debounceTimer, setDebounceTimer] = useState(null);
  const [isGlobalSearch, setGlobalSearch] = useState(false);

  // Fetch data for pagination
  const fetchPaginatedData = async (page = 1, controller) => {
    setLoading(true);
    try {
      const response = await fetch(`${BASE_URL}/user/get-users/?page=${page}`, {
        signal: controller?.signal,
      });
      const data = await response.json();
      if (response.ok) {
        setData(data.data);
        setFilteredData(data.data);
        setTotalUsers(data.pagination.totalUsers);
        setTotalPages(data.pagination.totalPages);
      } else {
        console.error("Unable to fetch paginated data");
      }
    } catch (error) {
      console.error("Error in fetching paginated data", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch data for global search
  const fetchSearchData = async (searchTerm, controller) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${BASE_URL}/user/search-users/?query=${searchTerm}`,
        { signal: controller?.signal }
      );
      const data = await response.json();
      if (response.ok) {
        setFilteredData(data.data); // Assume the API returns matching data directly
        setTotalUsers(data.data.length); // Adjust user count for search results
        setTotalPages(1); // Only one page for search results
      } else {
        console.error("Unable to fetch search data");
      }
    } catch (error) {
      console.error("Error in fetching search data", error);
    } finally {
      setLoading(false);
    }
  };

  // UseEffect to handle pagination and search conditions
  useEffect(() => {
    const controller = new AbortController();
    if (isGlobalSearch && searchTerm.trim()) {
      fetchSearchData(searchTerm, controller);
    } else {
      fetchPaginatedData(currentPage, controller);
    }
    return () => {
      controller.abort();
    };
  }, [currentPage, isGlobalSearch]);

  // Search input change handler with debounce
  const handleSearchTermChange = (value) => {
    setSearchTerm(value);

    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    const newTimer = setTimeout(() => {
      setCurrentPage(1); // Reset to the first page on new search
      if (value.trim()) {
        setGlobalSearch(true); // Switch to global search mode
        fetchSearchData(value); // Fetch global search data
      } else {
        setGlobalSearch(false); // Switch back to paginated mode
        fetchPaginatedData(1); // Fetch paginated data without search
      }
    }, 2000);

    setDebounceTimer(newTimer);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  return (
    <div className="atsto-container">
      <h1 className="atrologers-heading">Users</h1>
      <div className="atsro-section1">
        <div className="astro-search-container">
          <input
            type="text"
            placeholder="Search by User name or phone number"
            className="astro-search-input"
            value={searchTerm}
            onChange={(e) => handleSearchTermChange(e.target.value)}
          />
        </div>
        <p className="length">Total Users: {totalUsers}</p>
      </div>
      <div className="astro-table-container">
        {loading ? (
          <Loader />
        ) : (
          <table className="astro-table">
            <thead className="astro-he">
              <tr className="astro-bg">
                <th className="he">Username</th>
                <th className="he">Phone Number</th>
                <th className="he">Wallet balance</th>
                <th className="he">Total Payments</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((user) => (
                <tr
                  key={user._id}
                  className="astro-rows"
                  onClick={() =>
                    navigate(`/dashboard/user/${user._id}`, { state: { user } })
                  }
                >
                  <td className="he">{user.userName}</td>
                  <td className="he">{user.phone_number}</td>
                  <td className="he">{user.wallet}</td>
                  <td className="he">{user.totalPayment}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {!isGlobalSearch && (
          <div className="pagination">
            <button
              className="pagination-button"
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="pagination-info">
              Page {currentPage} of {totalPages}
            </span>
            <button
              className="pagination-button"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Users;
