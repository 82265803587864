import React, { useState, useEffect } from "react";
import axios from "axios";
import BASE_URL from "../config";
import "./AstrologerChatDuration.css";

const monthsData = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const AstrologerChatDuration = ({ astrologerId, dateRange }) => {
  const [calendarData, setCalendarData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(
    (new Date().getMonth() + 1).toString().padStart(2, "0")
  );
  const [loading, setLoading] = useState(false);

  console.log("Fetched calendar data:", calendarData);

  useEffect(() => {
    if (astrologerId) {
      fetchCalendarData();
    }
  }, [astrologerId]);

  useEffect(() => {
    // Filter data based on dateRange whenever it changes
    applyDateRangeFilter();
  }, [calendarData, dateRange]);

  const applyDateRangeFilter = () => {
    if (dateRange?.from && dateRange?.to) {
      const fromDate = new Date(
        dateRange.from.split("-").reverse().join("-")
      ).getTime();
      const toDate = new Date(
        dateRange.to.split("-").reverse().join("-")
      ).getTime();

      const filtered = calendarData.filter((data) => {
        const dataDate = new Date(data.date.split("-").reverse().join("-"))
          .getTime();
        return dataDate >= fromDate && dataDate <= toDate;
      });

      setFilteredData(filtered);
    } else {
      // If no dateRange is provided, show the full data
      setFilteredData(calendarData);
    }
  };

  const fetchCalendarData = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/astrologer/getAstrologerChatDurationCalendar-data`,
        {
          astrologerId,
        }
      );
      const fetchedData = response.data;
      setCalendarData(fetchedData);
    } catch (error) {
      console.error("Error fetching call duration data:", error);
    } finally {
      setLoading(false);
    }
  };

  const totalChatsOfMonth=()=>{
    const totalDuration = filteredData.reduce(
      (acc, data) => acc + data.chats_count,
      0
    );
    return totalDuration
  }

  const formatDuration = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}h ${remainingMinutes.toFixed(0)}m`;
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const calculateTotalDurationForMonth = () => {
    const monthData = filteredData.filter((data) => {
      const [day, month, year] = data.date.split("-");
      return month === selectedMonth && year === selectedYear.toString();
    });

    const totalDuration = monthData.reduce(
      (acc, data) => acc + data.total_chats_duration,
      0
    );

    return totalDuration;
  };

  const calculateDaysInMonth = () => {
    const monthData = filteredData.filter((data) => {
      const [day, month, year] = data.date.split("-");
      return month === selectedMonth && year === selectedYear.toString();
    });

    return monthData.length;
  };

  const calculateAverageDurationForMonth = () => {
    const totalDuration = calculateTotalDurationForMonth();
    const daysInMonth = calculateDaysInMonth() || new Date(
      selectedYear,
      parseInt(selectedMonth),
      0
    ).getDate(); // Default to the actual days of the month

    return daysInMonth ? totalDuration / daysInMonth : 0;
  };

  const renderDayTable = () => {
    if (!filteredData.length) {
      return <p>No data available for this range.</p>;
    }

    return (
      <table className="day-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Total Duration</th>
            <th>Total Chats</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((dayData) => {
            return (
              <tr id="d-rows" key={dayData.date}>
                <td id="td1">{dayData.date}</td>
                <td id="td2">{formatDuration(dayData.total_chats_duration)}</td>
                <td id="td3">{dayData.chats_count}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <div className="astrologer-chat-duration">
      <h2>Astrologer Chat Duration</h2>

      {loading ? (
        <div className="loader">Loading...</div>
      ) : (
        <>
          <div className="selectors">
            <div>
              <label>Year:</label>
              <select value={selectedYear} onChange={handleYearChange}>
                {[...new Set(calendarData.map((data) => data.date.split("-")[2]))].map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label>Month:</label>
              <select value={selectedMonth} onChange={handleMonthChange}>
                {monthsData.map((month, index) => (
                  <option key={index} value={String(index + 1).padStart(2, "0")}>
                    {month}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="month-summary">
            <p>
              <strong>Total Chats Duration:</strong> {formatDuration(calculateTotalDurationForMonth())}
            </p>
            <p>
              <strong>Average Chat Duration:</strong> {formatDuration(calculateAverageDurationForMonth().toFixed(2))}
            </p>
            <p>
              <strong>Total Chats:</strong> {totalChatsOfMonth()}
            </p>
                
          </div>

          <div className="calendar">
            <h3>Daily Details:</h3>
            {renderDayTable()}
          </div>
        </>
      )}
    </div>
  );
};

export default AstrologerChatDuration;
