import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SiGooglemessages } from "react-icons/si";
import Loader from "../Loader";
import BASE_URL from "../config";
const AstrologerChatRooms = (astrologerId) => {
  const navigate = useNavigate();
  const [chatHistoryRoomsData, setChatHistoryRoomsData] = useState([]);
  const [totalChatHistoryPages, setTotalChatHistoryPages] = useState(null);
  const [totalChatHistoryCount, setTotalChatHistoryCount] = useState(null);
  const [currentChatHistoryPage, setCurrentChatHistoryPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  console.log("specific astrologer", astrologerId.astrologerId);

  const controller = new AbortController();

  const getChatHistoryDataOfSpecificUser = async () => {
    setIsLoading(true);
    const chatHistoryApiUrl = `${BASE_URL}/chatRoute/get-chat-rooms-by-astrologer?page=${currentChatHistoryPage}`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ astrologerId: astrologerId.astrologerId }),
      signal: controller?.signal,
    };
    const response = await fetch(chatHistoryApiUrl, options);
    const data = await response.json();
    if (response.ok === true) {
      const { chatRooms, totalChatRooms, totalPages } = data;
      setChatHistoryRoomsData(chatRooms);
      setTotalChatHistoryPages(totalPages);
      setTotalChatHistoryCount(totalChatRooms);
      setIsLoading(false);
      console.log("specific user chat room list", chatRooms);
    } else {
      // console.log(response.ok)
    }
  };

  const getSearchResults = async () => {
    const searchApiUrl = `${BASE_URL}/chatRoute/search-chat-rooms?search=${searchQuery}`;
    const response = await fetch(searchApiUrl, { signal: controller?.signal });
    const data = await response.json();
    const { chatRooms } = data;
    setChatHistoryRoomsData(chatRooms);
    setIsSearchActive(true);
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    if (value.trim() === "") {
      setIsSearchActive(false);
      getChatHistoryDataOfSpecificUser();
    } else {
      getSearchResults();
    }
  };

  const handleViewChat = (chat) => {
    const { _id: roomId, user, astrologer, updatedAt } = chat;
    navigate(`/dashboard/getRoomChat/${roomId}`, {
      state: { user, astrologer, updatedAt },
    });
  };

  const handleChatRoomsPage = (direction) => {
    if (direction === "prev" && currentChatHistoryPage > 1) {
      setCurrentChatHistoryPage(currentChatHistoryPage - 1);
    } else if (
      direction === "next" &&
      currentChatHistoryPage < totalChatHistoryPages
    ) {
      setCurrentChatHistoryPage(currentChatHistoryPage + 1);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    if (!isSearchActive) {
      getChatHistoryDataOfSpecificUser();
    }
    return () => {
      controller.abort();
    };
  }, [currentChatHistoryPage]);

  const renderChatHistoryTable = () => (
    <div>
      <div className="astro-table-container pymnts-container">
        <table className="astro-table">
          <thead className="astro-he">
            <tr className="astro-bg">
              <th className="he">User Name</th>
              <th className="he">User Phone_number</th>
              <th className="he">Date/Time</th>
              <th className="he">View Chat</th>
            </tr>
          </thead>
          <tbody>
            {chatHistoryRoomsData.map((chat) => (
              <tr className="astro-rows" key={chat._id}>
                <td
                  className="he"
                  onClick={() =>
                    navigate(`/dashboard/user/${chat?.user?.userId}`, {
                      state: {
                        user: {
                          _id: chat?.user?.userId,
                          userName: chat?.user?.userName,
                          phone_number: chat?.user?.phone_number,
                        },
                      },
                    })
                  }
                  style={{ color: "blue", cursor: "pointer" }}
                >
                  {chat?.user?.userName || "NA"}
                </td>
                <td className="he">{chat?.user?.phone_number || "NA"}</td>

                <td className="he">{chat?.updatedAt}</td>
                <td className="he">
                  {chat?.messageCount > 0 ? (
                    <SiGooglemessages
                      className="messeges-google-icon"
                      onClick={() => handleViewChat(chat)}
                      size={35}
                    />
                  ) : (
                    "No Chat History Found"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {!isSearchActive && (
        <div className="pagination">
          <button
            className="pagination-button"
            onClick={() => handleChatRoomsPage("prev")}
            disabled={currentChatHistoryPage === 1}
          >
            Prev
          </button>
          <span className="pagination-info">
            Page {currentChatHistoryPage} of {totalChatHistoryPages}
          </span>
          <button
            className="pagination-button"
            onClick={() => handleChatRoomsPage("next")}
            disabled={currentChatHistoryPage === totalChatHistoryPages}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );

  return (
    <div>
      <div>
        <input
          type="search"
          placeholder="Search by Username, Astrologer Name, or Phone Number"
          className="search-bar"
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <button
          className="btn"
          onClick={() => getChatHistoryDataOfSpecificUser()}
        >
          {isLoading === true ? "Loading..." : "Reload"}
        </button>
      </div>
    <h3>{`Total Chats:${totalChatHistoryCount}`}</h3>
      {isLoading ? (
        <Loader />
      ) : chatHistoryRoomsData.length > 0 ? (
        renderChatHistoryTable()
      ) : (
        "Currently No Chat History Available on these User !"
      )}
    </div>
  );
};

export default AstrologerChatRooms;
