import "./dashboardHome.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";
import "./dashboardHome.css";
import BarChart from "../Barchart";
import PieChart from "../PieChart";
import BASE_URL from "../config";
const DashboardHome = () => {
  const [usersData, setUsersData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalUsers, setTotalUsers] = useState(0);
  const [astrologersData, setAstrologersData] = useState([]);
  const [totalAstrologers, setTotalAstrologers] = useState(0);
  const navigate = useNavigate();

  const onClickToNavigateToSeeAllUsers = () => {
    navigate("/dashboard/users");
  };

  const onClickToNavigateToSeeAllAstrologers = () => {
    navigate("/dashboard/astrologers");
  };

  const fetchUsersData = async (controllerSignal) => {
    setIsLoading(true);
    try {
      let response = await fetch(`${BASE_URL}/user/get-users/`, {
        signal: controllerSignal,
      });
      response = await response.json();
      console.log("dashboard home get users data", response);
      const slicedUsersData = response.data.slice(0, 3);
      //// console.log(slicedUsersData)
      setUsersData(slicedUsersData);
      setTotalUsers(response.pagination.totalUsers);
      //setTotalUsers(response.data.length);
      if (response.status === 200) {
        // console.log("Successful");
      } else {
        // console.log("Unable to fetch data");
      }
    } catch (error) {
      console.error("Error in fetching", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAstrologersData = async (controllerSignal) => {
    setIsLoading(true);
    try {
      let response = await fetch(`${BASE_URL}/astrologer/get-astrologers/`, {
        signal: controllerSignal,
      });
      response = await response.json();
      const { astrologers } = response;
      setTotalAstrologers(response.totalAstrologers);
      const slicedAstrologers = astrologers.slice(0, 3);
      setAstrologersData(slicedAstrologers);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    const fetchAllData = async () => {
      await fetchAstrologersData(signal);
      await fetchUsersData(signal);
    };
    fetchAllData();
    //Promise.all([fetchUsersData(), fetchAstrologersData()])

    return () => {
      controller.abort();
    };
  }, []);

  // console.log(astrologersData)

  return (
    <div className="Dashbord-table-container">
      <div className="dashboard-home-container">
        <div className="dashbord-home-1">
          <div className="head-section">
            <p>Total Users</p>
            <p>{totalUsers}</p>
          </div>
          <div className="header-section-1">
            <p>Users</p>
            <div>
              <button
                onClick={onClickToNavigateToSeeAllUsers}
                className="see-all-btn"
              >
                See All
              </button>
            </div>
          </div>
          <hr className="horizontal-divider" />
          <div className="users-astro-table-container">
            {isLoading ? (
              <div className="loader-content">
                <Loader />
              </div>
            ) : (
              <table className="users-astro-table">
                <thead className="users-astro-he">
                  <tr className="users-astro-bg">
                    <th className="he">Username</th>
                    <th className="he">Phone Number</th>
                    <th className="he">Wallet balance</th>
                  </tr>
                </thead>
                <tbody>
                  {usersData.map((user) => (
                    <tr
                      key={user._id}
                      className="users-astro-rows"
                      onClick={() =>
                        navigate(`/dashboard/user/${user._id}`, {
                          state: { user },
                        })
                      }
                    >
                      <td className="he">{user.userName}</td>
                      <td className="he">{user.phone_number}</td>
                      <td className="he">{user.wallet}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <div className="dashbord-home-1">
          <div className="head-section">
            <p>Total Money Deposited</p>
          </div>
          <BarChart />
        </div>
        <div className="dashbord-home-1">
          <div className="head-section">
            <p>Total Astrologers</p>
            <p>{totalAstrologers}</p>
          </div>
          <div className="header-section-1">
            <p>Astrologers</p>
            <div>
              <button
                onClick={onClickToNavigateToSeeAllAstrologers}
                className="see-all-btn"
              >
                See All
              </button>
            </div>
          </div>
          <hr className="horizontal-divider" />
          <div className="users-astro-table-container">
            {isLoading ? (
              <div className="loader-content">
                <Loader />
              </div>
            ) : (
              <table className="users-astro-table">
                <thead className="users-astro-he">
                  <tr className="users-astro-bg">
                    <th className="he">Name</th>
                    <th className="he">Phone Number</th>
                    <th className="he">Wallet balance</th>
                  </tr>
                </thead>
                <tbody>
                  {astrologersData.map((eachAstrologer) => {
                    return (
                      <tr key={eachAstrologer._id} className="users-astro-rows">
                        <td className="he hehe">
                          {eachAstrologer.astrologerName}
                        </td>
                        <td className="he hehe">
                          {eachAstrologer.phone_number}
                        </td>
                        <td className="he hehe">
                          {eachAstrologer.wallet || "0"}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <div className="dashbord-home-1">
          <div className="head-section">
            <p>Total Money Withdrawn</p>
          </div>
          <PieChart chartHeight={200} />
        </div>
      </div>
    </div>
  );
};

export default DashboardHome;
