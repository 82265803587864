import { useEffect, useState } from "react";
import BASE_URL from "../config";
const AstrologerSessionEndChat = ({ astrologerId }) => {
  const [currentLiveChatPage, setCurrentChatPage] = useState(1);
  const [totalLiveChatPages, setTotalLivePages] = useState(null);
  const [liveChatData, setLiveChatData] = useState([]);
  const [totalNumberOfChats, setTotalNumberOfChats] = useState(0);

  // Fetch chats for the astrologer
  const getSessionEndChats = async (controller) => {
    try {
      const apiUrl = `${BASE_URL}/astrologer/get-astrologer-session-end-chats?page=${currentLiveChatPage}`;
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ astrologerId }),
        signal: controller?.signal,
      });
      const data = await response.json();

      if (data.success) {
        const { totalChats, totalPages, chats } = data;
        setTotalLivePages(totalPages);
        setLiveChatData(chats);
        setTotalNumberOfChats(totalChats);
      } else {
        console.error(data.message);
      }
    } catch (error) {
      console.error("Error fetching session end chats:", error);
    }
  };

  const handlePageChange = (direction) => {
    if (direction === "prev" && currentLiveChatPage > 1) {
      setCurrentChatPage((prev) => prev - 1);
    } else if (
      direction === "next" &&
      currentLiveChatPage < totalLiveChatPages
    ) {
      setCurrentChatPage((prev) => prev + 1);
    }
  };

  const SessionEndChatTable = () => (
    <>
      <div className="astro-table-container pymnts-container">
        <table className="astro-table">
          <thead className="astro-he">
            <tr className="astro-bg">
              <th className="he">User Name</th>

              <th className="he">User PhoneNumber</th>

              <th className="he">Cost/min</th>
              <th className="he">Status</th>
              <th className="he">Date Time</th>
            </tr>
          </thead>
          <tbody>
            {liveChatData?.map((chat) => (
              <tr className="astro-rows" key={chat._id}>
                <td className="he">{chat?.userName || "NA"}</td>

                <td className="he">{chat.from || "NA"}</td>

                <td className="he">{chat?.cost || "NA"}</td>
                <td className="he">{chat?.status || "NA"}</td>
                <td className="he">{chat?.updatedAt}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination">
        <button
          className="pagination-button"
          onClick={() => handlePageChange("prev")}
          disabled={currentLiveChatPage === 1}
        >
          Prev
        </button>
        <span className="pagination-info">
          Page {currentLiveChatPage} of {totalLiveChatPages}
        </span>
        <button
          className="pagination-button"
          onClick={() => handlePageChange("next")}
          disabled={currentLiveChatPage === totalLiveChatPages}
        >
          Next
        </button>
      </div>
    </>
  );

  useEffect(() => {
    const controller = new AbortController();

    if (astrologerId) {
      getSessionEndChats(controller);
    }

    return () => {
      controller?.abort();
    };
  }, [currentLiveChatPage, astrologerId]);

  return (
    <div>
      {totalNumberOfChats !== 0 ? (
        SessionEndChatTable()
      ) : (
        <h1 className="empty-text">Currently No Session Chats are there !</h1>
      )}
    </div>
  );
};

export default AstrologerSessionEndChat;
