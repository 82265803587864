import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./index.css";
import { IoMdArrowRoundBack } from "react-icons/io";
import RegisterDetailedAstrologerPopUp from "../RegisterDetailedAstrologerPopUp";
import BASE_URL from "../config";
const DetailedAstrologer = () => {
  const location = useLocation();
  const { astrologerId } = location.state || {};
  const [astrologerDetailed, setAstrologerDetailed] = useState({});
  const [status, setStatus] = useState("");
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [confirmAction, setConfirmAction] = useState(null);

  const navigate = useNavigate();

  const getSpecificAstologerDetails = async (signal) => {
    const apiUrl = `${BASE_URL}/register/astrologer/${astrologerId}`;
    const response = await fetch(apiUrl, { signal });
    const data = await response.json();
    // console.log(data);
    const { astrologer } = data;
    if (response.ok === true) {
      setAstrologerDetailed(astrologer);
      setStatus(astrologer.status);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller?.signal;

    getSpecificAstologerDetails(signal);

    return () => {
      controller.abort();
    };
  }, []);

  const updateStatusRegisterAstro = async (newStatus) => {
    const controller = new AbortController();
    const signal = controller?.signal;

    try {
      const apiUrl = `${BASE_URL}/register/update-astrologer-status/${astrologerId}`;
      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ status: newStatus }),
        signal,
      });

      const data = await response.json();
      if (response.ok) {
        setStatus(newStatus);
        if (newStatus === "accepted") {
          await addRegisterAstrologerToAstrologer(astrologerId);
        }
      } else {
        console.error("Failed to update status:", data.message);
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
    return () => {
      controller.abort();
    };
  };

  const addRegisterAstrologerToAstrologer = async (id) => {
    const controller = new AbortController();
    const signal = controller?.signal;

    try {
      const checkResponse = await fetch(
        `${BASE_URL}/register/checkAstrologer/${id}`,
        { signal }
      );
      const checkData = await checkResponse.json();

      if (checkResponse.ok && checkData.exists) {
        return null;
      }

      const response = await fetch(
        `${BASE_URL}/register/addToAstrologer/${id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          signal,
        }
      );

      const data = await response.json();

      if (response.ok) {
        // console.log('Astrologer added successfully:', data.astrologer);
        return data;
      } else {
        // console.log('Failed to add astrologer:', data.message);
        return null;
      }
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Add astrologer request was aborted.");
      } else {
        console.error("Error adding astrologer:", error);
      }
    }

    return () => {
      controller.abort();
    };
  };

  const handleStatusChange = (newStatus) => {
    setPopupMessage(
      `Are you sure you want to ${
        newStatus === "accepted" ? "accept" : "reject"
      } the astrologer?`
    );
    setConfirmAction(() => () => updateStatusRegisterAstro(newStatus));
    setPopupVisible(true);
  };

  const closePopup = () => {
    setPopupVisible(false);
    setPopupMessage("");
    setConfirmAction(null);
  };

  const confirmActionHandler = () => {
    if (confirmAction) {
      confirmAction();
    }
    closePopup();
  };

  return (
    <div className="detailed-table-container">
      <button className="astro-back-button" onClick={() => navigate(-1)}>
        <IoMdArrowRoundBack size={23} className="back-arrow" />
      </button>
      <table className="register-astro-details-table">
        <tbody>
          <tr className="single-astro-rows">
            <td>
              <strong className="astro-properties">Name</strong>
            </td>
            <td>{astrologerDetailed.astrologerName}</td>
          </tr>
          <tr className="single-astro-rows">
            <td>
              <strong className="astro-properties">Phone Number</strong>
            </td>
            <td>{astrologerDetailed.phone_number}</td>
          </tr>
          <tr className="single-astro-rows">
            <td>
              <strong className="astro-properties">Category</strong>
            </td>
            <td>{astrologerDetailed.category}</td>
          </tr>
          <tr className="single-astro-rows">
            <td>
              <strong className="astro-properties">Experience</strong>
            </td>
            <td>
              {astrologerDetailed.experience > 1
                ? `${astrologerDetailed.experience} years`
                : `${astrologerDetailed.experience} year`}
            </td>
          </tr>
          <tr className="single-astro-rows">
            <td>
              <strong className="astro-properties">Known Languages</strong>
            </td>
            {Array.isArray(astrologerDetailed.known_languages) &&
            astrologerDetailed.known_languages.length > 0 ? (
              astrologerDetailed.known_languages.map((eachLanguage, index) => (
                <td key={index}>{eachLanguage}</td>
              ))
            ) : (
              <td>No languages available</td>
            )}
          </tr>
          <tr className="single-astro-rows">
            <td>
              <strong className="astro-properties">Expert In</strong>
            </td>
            <td>{astrologerDetailed.expertIn}</td>
          </tr>
          <tr className="single-astro-rows">
            <td>
              <strong className="astro-properties">Status</strong>
            </td>
            <td>{status}</td>
          </tr>
        </tbody>
      </table>

      <div className="buttons-active-inactive">
        <button
          className="active-button"
          onClick={() => handleStatusChange("accepted")}
          disabled={status === "accepted"}
        >
          Accepted
        </button>
        <button
          className="inactive-button"
          onClick={() => handleStatusChange("rejected")}
          disabled={status === "rejected"}
        >
          Rejected
        </button>
        {popupVisible && (
          <RegisterDetailedAstrologerPopUp
            message={popupMessage}
            onConfirm={confirmActionHandler}
            onCancel={closePopup}
          />
        )}
      </div>
    </div>
  );
};

export default DetailedAstrologer;
