import React, { useEffect, useState } from "react";
import BASE_URL from "./config";

const PreferredTimeTable = ({ astrologer }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const perPage = 10;

  // Fetch all preferred times
  const fetchAllPreferredTimes = async () => {
    if (!astrologer?._id) {
      console.error("Astrologer ID is missing");
      setError("Astrologer ID is required.");
      return;
    }
    const url = new URL(`${BASE_URL}/astrologer/preferred-times`);
    url.searchParams.append("astrologerId", astrologer._id);
    url.searchParams.append("page", currentPage);
    url.searchParams.append("perPage", perPage);

    try {
      setLoading(true);
      setError("");
      const response = await fetch(url.toString());
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
      if (result.success) {
        setData(result.preferredTimes || []);
        setTotalPages(result.totalPages || 1);
      } else {
        setData([]);
        setError(result.message || "Failed to fetch preferred times.");
      }
    } catch (error) {
      console.error("Error fetching preferred times:", error);
    } finally {
      setLoading(false);
    }
  };
  // Fetch preferred times by date
  const fetchPreferredTimesByDate = async () => {
    if (!astrologer?._id || !selectedDate) {
      return;
    }
    const url = new URL(`${BASE_URL}/astrologer/preferred-times-by-date`);
    url.searchParams.append("astrologerId", astrologer._id);
    url.searchParams.append("date", selectedDate);
    url.searchParams.append("page", currentPage);
    url.searchParams.append("perPage", perPage);
    try {
      setLoading(true);
      setError("");
      const response = await fetch(url.toString());
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
      if (result.success) {
        setData(result.preferredTimes || []);
        setTotalPages(result.totalPages || 1);
      } else {
        setData([]);
        setError(
          result.message || "Failed to fetch preferred times for this date."
        );
      }
    } catch (error) {
      console.error("Error fetching preferred times by date:", error);
      setError("Failed to fetch preferred times by date. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (selectedDate) {
      fetchPreferredTimesByDate();
    } else {
      fetchAllPreferredTimes();
    }
  }, [astrologer?._id, selectedDate, currentPage]);
  const paginatedData = data.slice(
    (currentPage - 1) * perPage,
    currentPage * perPage
  );

  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <div className="astro-table-container pymnts-container">
      <h2>
        Preferred Time For The Date -{" "}
        {selectedDate
          ? new Date(selectedDate).toLocaleDateString()
          : new Date().toLocaleDateString()}{" "}
      </h2>
      <div className="date-picker-container">
        <h3 className="date-picker-heading">Select a Date: </h3>
        <input
          type="date"
          id="datePicker"
          value={selectedDate}
          onChange={(e) => {
            setSelectedDate(e.target.value);
            setCurrentPage(1);
          }}
          className="date-input"
        />
        <button className="date-clear" onClick={() => setSelectedDate("")}>
          Clear
        </button>
      </div>

      {error && <div>{error}</div>}

      {data.length === 0 ? (
        <div>
          <h1>No preferred times available for this Date.</h1>
        </div>
      ) : (
        <table className="astro-table">
          <thead className="astro-he">
            <tr className="astro-bg">
              <th className="he">Astrologer Name</th>
              <th className="he">Start Time</th>
              <th className="he">End Time</th>
              <th className="he">Date</th>
            </tr>
          </thead>
          <tbody>
            {paginatedData.map((astrologerData) => (
              <React.Fragment key={astrologerData._id}>
                {astrologerData.preferredTime.map((timeSlot, index) => (
                  <tr className="astro-rows" key={`${timeSlot._id}-${index}`}>
                    <td className="he">
                      {astrologerData.astrologerId?.astrologerName || "N/A"}
                    </td>
                    <td className="he">{timeSlot.startTime}</td>
                    <td className="he">{timeSlot.endTime}</td>
                    <td className="he">
                      {new Date(astrologerData.date).toLocaleDateString()}
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      )}

      <div className="pagination">
        <button
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={() =>
            setCurrentPage((prev) => Math.min(prev + 1, totalPages))
          }
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default PreferredTimeTable;
