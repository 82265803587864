import { useEffect, useState } from "react";
import "./TodayLoginTime.css";
import BASE_URL from "../config";

const TodayLoginTime = ({ astrologerId }) => {
  const [todayLoginHistory, setTodayLoginHistory] = useState(null);
  const [pastHistory, setPastHistory] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [monthData, setMonthData] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupData, setPopupData] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");

  const getLoginHistory = async (controller) => {
    const loginHistoryApiUrl = `${BASE_URL}/astrologer/loginHistory`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ astrologerId }),
      signal: controller.signal,
    };

    try {
      const response = await fetch(loginHistoryApiUrl, options);
      const data = await response.json();
      setTodayLoginHistory(data.todaySummary);
      setPastHistory(data.pastHistory || []);
      const currentMonth = getCurrentMonthYear();
      setSelectedMonth(currentMonth);

      // Automatically set the data for the current month
      const currentMonthData = data.pastHistory.find(
        (item) => item.month === currentMonth
      );
      setMonthData(currentMonthData || null);
    } catch (error) {
      if (error.name !== "AbortError") {
        console.error("Fetch error:", error);
      }
    }
  };

  // Helper function to get the current month in "Month Year" format
  const getCurrentMonthYear = () => {
    const currentDate = new Date();
    const month = currentDate.toLocaleString("default", { month: "long" });
    const year = currentDate.getFullYear();
    return `${month} ${year}`;
  };

  useEffect(() => {
    const controller = new AbortController();
    getLoginHistory(controller);

    // Cleanup function to abort the fetch request if the component unmounts
    return () => {
      controller.abort();
    };
  }, []);

  const handleMonthChange = (event) => {
    const selected = event.target.value;
    setSelectedMonth(selected);
    const selectedData = pastHistory.find(
      (item) => `${item.month}` === selected
    );
    setMonthData(selectedData || null);
  };

  const handleViewClick = (dayData) => {
    setPopupData(dayData.sessions);
    setSelectedDate(dayData.date); 
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setPopupData([]);
  };

  if (!todayLoginHistory || !pastHistory) {
    return (
      <div className="loader">
        <p>Loading login history...</p>
      </div>
    );
  }

  return (
    <div className="main-login-container-lc">
      <div className="login-history-container">
        <div className="filter-section">
          <select
            className="month-year-dropdown"
            value={selectedMonth}
            onChange={handleMonthChange}
          >
            {pastHistory.map((monthData, index) => (
              <option key={index} value={monthData.month}>
                {monthData.month}
              </option>
            ))}
          </select>
        </div>
        <div className="summary-section">
          <div className="today-summary">
            <h3>Today Summary</h3>
            <p>
              <strong>Day:</strong> {todayLoginHistory.day}
            </p>
            <p>
              <strong>Date:</strong>{" "}
              {new Date(todayLoginHistory.date).toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              })}
            </p>
            <p>
              <strong>Total Duty Time:</strong>{" "}
              {todayLoginHistory.totalDutyTime}
            </p>
            {todayLoginHistory.sessions &&
            todayLoginHistory.sessions.length > 0 ? (
              <div className="session-times">
                <h4>Session Times</h4>
                <table className="session-table">
                  <thead className="session-head">
                    <tr className="session-row">
                      <th className="session-col">Start Time</th>
                      <th className="session-col">End Time</th>
                      <th className="session-col">Duration</th>
                    </tr>
                  </thead>
                  <tbody className="session-body">
                    {todayLoginHistory.sessions.map((session, index) => {
                      const startTime = new Date(session.startTime);
                      const endTime = session.endTime
                        ? new Date(session.endTime)
                        : null;
                      const duration = endTime
                        ? endTime - startTime
                        : Date.now() - startTime;
                      const durationMinutes = Math.floor(duration / 60000);
                      const durationSeconds = Math.floor(
                        (duration % 60000) / 1000
                      );
                      return (
                        <tr key={index} className="session-row">
                          <td className="session-col">{startTime.toLocaleTimeString("en-US")}</td>
                          <td className="session-col">{endTime ? endTime.toLocaleTimeString("en-US") : "Ongoing"}</td>
                          <td>{`${Math.floor(durationMinutes / 60)}h ${durationMinutes % 60}m`}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <p>No sessions logged for today.</p>
            )}
          </div>
          {monthData && (
            <div className="login-month-summary">
              <h3>Summary for {monthData.month}</h3>
              <p>
                <strong>Total Duty Time:</strong> {monthData.totalDutyTime}
              </p>
              <p>
                <strong>Average Duty Time:</strong> {monthData.averageDutyTime}
              </p>
            </div>
          )}
        </div>
         {monthData ? (
          <div className="login-time-table-container">
            <h3>Details for {monthData.month}</h3>
            <table className="login-time-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Duty Time</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {monthData.monthDetails.map((dayData, index) => (
                  <tr key={index}>
                    <td>{dayData.date}</td>
                    <td>{dayData.dutyTime}</td>
                    <td>
                      <button
                        className="view-button"
                        onClick={() => handleViewClick(dayData)}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="no-data-message">
            Please select a month to view details.
          </p>
        )}
        {isPopupOpen && (
          <div className="allDatePopup">
            <div className="allDatePopup-content">
              <h3>Session Details for {selectedDate} </h3>
              <button className="close-button" onClick={handleClosePopup}>
                Close
              </button>
              <table className="popup-table">
                <thead>
                  <tr>
                    <th>Start Time</th>
                    <th>End Time</th>
                    <th>Duration</th>
                  </tr>
                </thead>
                <tbody>
                  {popupData.map((session, index) => {
                    const startTime = new Date(session.startTime);
                    const endTime = session.endTime
                      ? new Date(session.endTime)
                      : null;
                    const duration = endTime
                      ? endTime - startTime
                      : Date.now() - startTime;
                    const durationMinutes = Math.floor(duration / 60000);
                    return (
                      <tr key={index}>
                        <td>{startTime.toLocaleTimeString("en-US")}</td>
                        <td>{endTime ? endTime.toLocaleTimeString("en-US") : "Ongoing"}</td>
                        <td>{`${Math.floor(durationMinutes / 60)}h ${durationMinutes % 60}m`}</td>

                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TodayLoginTime;
