import React, { useState, useEffect, useRef } from "react";
import "./EditCouponPopUp.css";
import { ImCancelCircle } from "react-icons/im";
import BASE_URL from "../config";
const EditCouponPopUp = ({ onCancel, couponId, onClick }) => {
  const [amount, setAmount] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [message, setMessage] = useState(null);

  const abortController = useRef(new AbortController());

  useEffect(() => {
    return () => {
      abortController.current.abort();
    };
  }, []);

  const handleSubmit = async () => {
    try {
      const response = await fetch(
        `${BASE_URL}/coupon/updateCoupon/${couponId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            amount: amount,
            expirationDate: expirationDate,
          }),
          signal: abortController.current.signal,
        }
      );

      const data = await response.json();

      if (data.success) {
        setMessage("Coupon updated successfully");
        onCancel();
        onClick();
      } else {
        setMessage(data.message || "Failed to update coupon");
      }
    } catch (error) {
      console.error("Error updating coupon:", error);
      setMessage("An error occurred while updating the coupon");
    }
  };

  return (
    <div className="astro-popup-overlay">
      <div className="astro-popup-content">
        <div className="cancel-x-container">
          <ImCancelCircle
            size={29}
            className="cancel-icon-x"
            onClick={onCancel}
          />
        </div>

        {message && <p className="response-message">{message}</p>}
        <h3> Edit Coupon Details</h3>

        <div className="form-group">
          <label>Amount</label>
          <input
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            type="text"
            className="form-input amounttt"
            required
          />
        </div>

        <div className="form-group">
          <label>Expiration Date:</label>
          <input
            value={expirationDate}
            onChange={(e) => setExpirationDate(e.target.value)}
            className="form-input amounttt"
            type="date"
            required
          />
        </div>

        <div className="popup-buttons">
          <button className="astro-confirm-button" onClick={handleSubmit}>
            Confirm
          </button>
          <button className="astro-cancel-button" onClick={onCancel}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditCouponPopUp;
