import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { FaCrown } from "react-icons/fa";
import { IoMdArrowRoundBack } from "react-icons/io";
import Loader from "../Loader";
import BASE_URL from "../config";
const CategorizedAstrologers = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { status } = location.state || {};
  const [currentPage, setPage] = useState(1);
  const [totalAstrologerPages, setTotalAstrologerPages] = useState(1);
  const [astrologers, setAstrologers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalAstrologers, setTotalAstrologers] = useState(0);
  console.log("clicked status of astrologers", status);

  const handlePageChange = (direction) => {
    if (direction === "prev" && currentPage > 1) {
      setPage(currentPage - 1);
    } else if (direction === "next" && currentPage < totalAstrologerPages) {
      setPage(currentPage + 1);
    }
  };

  const renderAstrologersTable = () => (
    <>
      <div className="atsto-container">
        <button className="astro-back-button" onClick={() => navigate(-1)}>
          <IoMdArrowRoundBack size={23} className="back-arrow" />
        </button>
        <h1 className="atrologers-heading">{`${status.toUpperCase()} Astrologers`}</h1>
        <h3>{`Total ${status} Astrologers: ${totalAstrologers}`}</h3>
        <div className="atsro-section1">
          <div className="astro-search-container">
            {/* <input
            type="text"
            placeholder="Search by Astrologer name or phone number"
            className="astro-search-input"
            value={searchTerm}
            onInput={(event) => handleSearchChange(event.target.value)}
          /> */}
          </div>
        </div>

        <div className="astro-table-container">
          {loading ? (
            <Loader />
          ) : (
            <table className="astro-table">
              <thead className="astro-he">
                <tr className="astro-bg">
                  <th className="he">Name</th>
                  <th className="he">Phone Number</th>
                  <th className="he">Status</th>
                  <th className="he">Wallet</th>
                  <th className="he">Withdrawal Request</th>
                  <th className="he">View Waiting List</th>
                </tr>
              </thead>
              <tbody>
                {astrologers?.map((astrologer) => (
                  <tr
                    className="astro-rows"
                    key={astrologer._id}
                    onClick={() =>
                      navigate(`/dashboard/astrologer/${astrologer._id}`, {
                        state: { astrologer },
                      })
                    }
                  >
                    <td className="he">
                      {astrologer.role === "super" && (
                        <FaCrown
                          style={{
                            fontSize: "20px",
                            color: "gold",
                            marginLeft: "5px",
                          }}
                        />
                      )}{" "}
                      {astrologer.astrologerName}
                    </td>
                    <td className="he">{astrologer.phone_number}</td>
                    <td className="he">{astrologer.status}</td>
                    <td className="he">{astrologer.wallet || "0"}</td>
                    <td
                      className={`he ${
                        astrologer.withdrawlRequest
                          ? "withdrawlRequest-color"
                          : ""
                      }`}
                    >
                      {astrologer.withdrawlRequest || "0"}
                    </td>
                    <td className="he">
                      <button
                        className="move-button"
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate("/dashboard/astrologer-waitingList", {
                            state: { astrologer },
                          });
                        }}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <div className="pagination">
        <button
          className="pagination-button"
          onClick={() => handlePageChange("prev")}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span className="pagination-info">
          Page {currentPage} of {totalAstrologerPages}
        </span>
        <button
          className="pagination-button"
          onClick={() => handlePageChange("next")}
          disabled={currentPage === totalAstrologerPages}
        >
          Next
        </button>
      </div>
    </>
  );

  const getCategorizedAstrologers = async () => {
    try {
      setLoading(true);
      const getCategorizedAstrologersApiUrl = `${BASE_URL}/astrologer/get-astrologers-by-status?page=${currentPage}`;
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ status }),
      };
      const response = await fetch(getCategorizedAstrologersApiUrl, options);
      const data = await response.json();
      const { success } = data;
      console.log("categorized response", response);
      if (success === true) {
        setLoading(false);
        const { astrologers, totalPages, totalAstrologers } = data;
        setAstrologers(astrologers);
        setTotalAstrologers(totalAstrologers);
        setTotalAstrologerPages(totalPages);
        console.log("data", data);
      } else {
        console.error(data.message || "Failed to fetch astrologers.");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching astrologers:", error);
    }
  };

  useEffect(() => {
    getCategorizedAstrologers();
  }, [currentPage]);
  return <div>{renderAstrologersTable()}</div>;
};

export default CategorizedAstrologers;
