import React, { createContext, useState } from 'react';

export const ActiveSectionContext = createContext();

const ActiveSectionProvider = ({ children }) => {
  const [currentActiveSection, setActiveCurrentSection] = useState('Pending Chats'); 

  return (
    <ActiveSectionContext.Provider value={{ currentActiveSection, setActiveCurrentSection }}>
      {children}
    </ActiveSectionContext.Provider>
  );
};

export default ActiveSectionProvider;
