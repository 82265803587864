import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../config";
const AstrologerAcceptedChats = ({ astrologerId }) => {
  const [currentLiveChatPage, setCurrentChatPage] = useState(1);
  const [totalLiveChatPages, setTotalLivePages] = useState(null);
  const [liveChatData, setLiveChatData] = useState([]);
  const [totalNumberOfChats, setTotalNumberOfChats] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const getAcceptedChats = async (signal) => {
    const response = await fetch(
      `${BASE_URL}/astrologer/get-astrologer-accepted-chats?page=${currentLiveChatPage}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ astrologerId }),
        signal,
      }
    );

    const data = await response.json();
    const { totalChats, totalPages, chats } = data;
    setTotalLivePages(totalPages);
    setLiveChatData(chats);
    setTotalNumberOfChats(totalChats);
    // console.log("Accepted chats",data)
  };

  const handlePageChange = (direction) => {
    if (direction === "prev" && currentLiveChatPage > 1) {
      setCurrentChatPage(currentLiveChatPage - 1);
    } else if (
      direction === "next" &&
      currentLiveChatPage < totalLiveChatPages
    ) {
      setCurrentChatPage(currentLiveChatPage + 1);
    }
  };

  const AcceptedChatsTable = () => (
    <>
      <div className="astro-table-container pymnts-container">
        <table className="astro-table">
          <thead className="astro-he">
            <tr className="astro-bg">
              <th className="he">User Name</th>
              <th className="he">Astrologer Name</th>
              <th className="he">User PhoneNumber</th>
              <th className="he">Astrologer PhoneNumber</th>
              <th className="he">Cost/min</th>
              <th className="he">Status</th>
              <th className="he">Date Time</th>
            </tr>
          </thead>
          <tbody>
            {liveChatData?.map((chat) => (
              <tr className="astro-rows" key={chat._id}>
                <td
                  className="he"
                  onClick={() =>
                    navigate(`/dashboard/user/${chat?.userId}`, {
                      state: {
                        user: {
                          _id: chat?.userId,
                          userName: chat?.userName,
                          phone_number: chat?.userNumber,
                        },
                      },
                    })
                  }
                >
                  {chat?.userName || "NA"}
                </td>
                <td className="he">
                  {chat?.astrologerId?.astrologerName || "NA"}
                </td>
                <td className="he">{chat.from || "NA"}</td>
                <td className="he">{chat.to || "NA"}</td>
                <td className="he">{chat?.cost || "NA"}</td>
                <td className="he">{chat?.status || "NA"}</td>
                <td className="he">{chat?.updatedAt}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination">
        <button
          className="pagination-button"
          onClick={() => handlePageChange("prev")}
          disabled={currentLiveChatPage === 1}
        >
          Prev
        </button>
        <span className="pagination-info">
          Page {currentLiveChatPage} of {totalLiveChatPages}
        </span>
        <button
          className="pagination-button"
          onClick={() => handlePageChange("next")}
          disabled={currentLiveChatPage === totalLiveChatPages}
        >
          Next
        </button>
      </div>
    </>
  );

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller?.signal;
    getAcceptedChats(signal);
    return () => controller?.abort();
  }, [currentLiveChatPage]);

  return (
    <div>
      <button className="btn" onClick={() => getAcceptedChats()}>
        {isLoading === true ? "Loading..." : "Reload"}
      </button>
      {totalNumberOfChats !== 0 ? (
        AcceptedChatsTable()
      ) : (
        <h1 className="empty-text">Currently No Accepted Chats are there !</h1>
      )}
    </div>
  );
};
export default AstrologerAcceptedChats;
