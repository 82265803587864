import React, { useState } from "react";
import BASE_URL from "../config";
const Notification = () => {
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [userType, setUserType] = useState("all");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState(""); // 'success' or 'error'

  const handleSendNotification = () => {
    if (title && message && (phoneNumber || userType)) {
      setIsLoading(true);

      const controller = new AbortController();
      const signal = controller?.signal;

      const requestPayload = {
        title,
        message,
        userType,
        phoneNumber: phoneNumber ? phoneNumber : null,
      };

      fetch(`${BASE_URL}/user/sendAdminSide-notification`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestPayload),
        signal,
      })
        .then((response) => {
          setIsLoading(false);
          if (response.ok) {
            setAlertMessage("Notification sent successfully!");
            setAlertType("success");

            // Hide the success message after 2 seconds
            setTimeout(() => {
              setAlertMessage("");
              setAlertType("");
            }, 2000);

            setTitle("");
            setMessage("");
            setUserType("all");
            setPhoneNumber("");
          } else {
            setAlertMessage("Failed to send notification.");
            setAlertType("error");
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setAlertMessage(`Error sending notification: ${error.message}`);
          setAlertType("error");
        });

      setTimeout(() => controller.abort(), 5000);
    } else {
      setAlertMessage(
        "Please fill in Title, Message, and either User Type or Phone Number."
      );
      setAlertType("error");
    }
  };

  const styles = {
    dashboard: {
      maxWidth: "500px",
      margin: "50px auto",
      padding: "20px",
      backgroundColor: "#f9f9f9",
      borderRadius: "8px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      fontFamily: "Arial, sans-serif",
    },
    notificationSections: {
      textAlign: "center",
    },
    header: {
      marginBottom: "20px",
      fontSize: "1.8rem",
      color: "#333",
    },
    formGroup: {
      marginBottom: "20px",
      textAlign: "left",
    },
    label: {
      display: "block",
      marginBottom: "5px",
      fontWeight: "bold",
      color: "#555",
    },
    input: {
      width: "100%",
      padding: "8px",
      margin: "5px 0",
      border: "1px solid #ccc",
      borderRadius: "4px",
      fontSize: "1rem",
    },
    textarea: {
      width: "100%",
      padding: "10px",
      margin: "5px 0",
      border: "1px solid #ccc",
      borderRadius: "4px",
      fontSize: "1rem",
      resize: "vertical",
      height: "100px",
    },
    button: {
      width: "100%",
      padding: "12px",
      backgroundColor: "#007bff",
      color: "white",
      border: "none",
      borderRadius: "4px",
      fontSize: "1.2rem",
      cursor: "pointer",
      transition: "background-color 0.3s ease",
    },
    buttonHover: {
      backgroundColor: "#0056b3",
    },
    buttonDisabled: {
      backgroundColor: "#cccccc",
      cursor: "not-allowed",
    },
  };

  return (
    <div style={styles.dashboard}>
      <div style={styles.notificationSections}>
        {/* Alert Message */}
        {alertMessage && (
          <div
            style={{
              backgroundColor: alertType === "success" ? "green" : "red",
              color: "white",
              padding: "10px",
              marginBottom: "20px",
              borderRadius: "4px",
              textAlign: "center",
            }}
          >
            {alertMessage}
          </div>
        )}

        <h2 style={styles.header}>Send Notification To All User</h2>
        <div style={styles.formGroup}>
          <label htmlFor="title" style={styles.label}>
            Title:
          </label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Enter notification title"
            style={styles.input}
          />
        </div>
        <div style={styles.formGroup}>
          <label htmlFor="message" style={styles.label}>
            Message:
          </label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Enter notification message"
            style={styles.textarea}
          ></textarea>
        </div>
        <div style={styles.formGroup}>
          <label htmlFor="userType" style={styles.label}>
            User Type:
          </label>
          <select
            id="userType"
            value={userType}
            onChange={(e) => setUserType(e.target.value)}
            style={styles.input}
          >
            <option value="all">All</option>
            <option value="paid">Paid</option>
            <option value="free">Free</option>
          </select>
        </div>
        <div style={styles.formGroup}>
          <label htmlFor="phoneNumber" style={styles.label}>
            Phone Number (Optional):
          </label>
          <input
            type="text"
            id="phoneNumber"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="Enter user phone number"
            style={styles.input}
          />
        </div>
        <button
          onClick={handleSendNotification}
          style={{
            ...styles.button,
            ...(isLoading ? styles.buttonDisabled : {}),
          }}
        >
          {isLoading ? "Sending..." : "Send Notification"}
        </button>
      </div>
    </div>
  );
};

export default Notification;
