import React, { useState, useEffect } from "react";
import axios from "axios";
import BASE_URL from "../config";

const monthsData = [
  "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

const AstrologerCallDuration = ({ astrologerId, dateRange }) => {
  const [calendarData, setCalendarData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(
    (new Date().getMonth() + 1).toString().padStart(2, "0")
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (astrologerId) {
      fetchCalendarData();
    }
  }, [astrologerId]);

  useEffect(() => {
    // Filter data based on dateRange whenever it changes
    applyDateRangeFilter();
  }, [calendarData, dateRange]);

  const fetchCalendarData = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/astrologer/getAstrologerCallDurationCalendar-data`,
        { astrologerId }
      );
      const fetchedData = response.data;
      setCalendarData(fetchedData);
    } catch (error) {
      console.error("Error fetching call duration data:", error);
    } finally {
      setLoading(false);
    }
  };

  const applyDateRangeFilter = () => {
    if (dateRange?.from && dateRange?.to) {
      const fromDate = new Date(
        dateRange.from.split("-").reverse().join("-")
      ).getTime();
      const toDate = new Date(
        dateRange.to.split("-").reverse().join("-")
      ).getTime();

      const filtered = calendarData.filter((data) => {
        const dataDate = new Date(data.date.split("-").reverse().join("-"))
          .getTime();
        return dataDate >= fromDate && dataDate <= toDate;
      });

      setFilteredData(filtered);
    } else {
      // If no dateRange is provided, show the full data
      setFilteredData(calendarData);
    }
  };

  const handleMonthChange = (event) => {
    const month = event.target.value;
    setSelectedMonth(month);
    filterDataByYearAndMonth(selectedYear, month);
  };

  const handleYearChange = (event) => {
    const year = event.target.value;
    setSelectedYear(year);
    filterDataByYearAndMonth(year, selectedMonth);
  };

  const filterDataByYearAndMonth = (year, month) => {
    const filtered = calendarData.filter((item) => {
      const [day, itemMonth, itemYear] = item.date.split("-").map(Number);
      return itemYear === parseInt(year, 10) && itemMonth === parseInt(month, 10);
    });
    setFilteredData(filtered);
  };

  const calculateTotalDurationForMonth = () => {
    return filteredData.reduce(
      (acc, data) => acc + data.total_calls_duration,
      0
    );
  };

  const calculateTotalCallsForMonth = () => {
    return filteredData.reduce((acc, data) => acc + data.calls_count, 0);
  };

  const calculateAverageCallDuration = () => {
    const totalDuration = calculateTotalDurationForMonth();
    const totalCalls = calculateTotalCallsForMonth();
    return totalCalls > 0 ? (totalDuration / totalCalls).toFixed(2) : 0;
  };

  const renderDayTable = () => {
    if (!filteredData.length) {
      return <p>No data available for this range.</p>;
    }

    return (
      <table className="day-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Total Duration (HH:MM:SS)</th>
            <th>Total Calls</th>
            
          </tr>
        </thead>
        <tbody>
          {filteredData.map((dayData) => (
            <tr key={dayData.date}>
              <td>{dayData.date}</td>
              <td>{convertSecondsToTimeFormat(dayData.total_calls_duration)}</td>
              <td>{dayData.calls_count}</td>
             
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const convertSecondsToTimeFormat = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const remainingSeconds = seconds % 3600;
    const minutes = Math.floor(remainingSeconds / 60);
    const secs = remainingSeconds % 60;
    return `${hours}h ${minutes}m ${secs.toFixed(0)}s`;
  };

  return (
    <div className="astrologer-call-duration">
      <h2>Astrologer Call Duration</h2>

      {loading ? (
        <div className="loader">Loading...</div>
      ) : (
        <>
          <div className="selectors">
            <div>
              <label>Year:</label>
              <select value={selectedYear} onChange={handleYearChange}>
                {[...new Set(calendarData.map((data) => data.date.split("-")[2]))].map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label>Month:</label>
              <select value={selectedMonth} onChange={handleMonthChange}>
                {monthsData.map((month, index) => (
                  <option key={index} value={String(index + 1).padStart(2, "0")}>
                    {month}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="month-summary">
            <p>
              <strong>Total Calls Duration:</strong>{" "}
              {convertSecondsToTimeFormat(calculateTotalDurationForMonth())}
            </p>
            <p>
              <strong>Total Calls:</strong> {calculateTotalCallsForMonth()}
            </p>
            <p>
              <strong>Average Call Duration:</strong>{" "}
              {convertSecondsToTimeFormat(calculateAverageCallDuration())}
            </p>
          </div>

          <div className="calendar">
            <h3>Daily Details:</h3>
            {renderDayTable()}
          </div>
        </>
      )}
    </div>
  );
};

export default AstrologerCallDuration;
