import { useEffect, useState } from "react";
import BASE_URL from "../config";
const AstrologerSuccessFullTransactions = ({ astrologerId }) => {
  const [currentSuccessFullTransactionPage, setCurrentSuccessFullPage] =
    useState(1);
  const [successFullTransactionsData, setSuccessFullTransactionsData] =
    useState([]);
  const [totalSuccessFullTransactionPages, setTotalSuccessFullPages] =
    useState(1);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const getAstrologerSuccessFullTransactions = async () => {
    const controller = new AbortController(); // Create an AbortController
    const signal = controller.signal; // Extract the signal for fetch cancellation

    const getTransactionsApiUrl = `${BASE_URL}/astrologer/getastrologer-transactions-data/${astrologerId}?page=${currentSuccessFullTransactionPage}`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ status: "success" }),
      signal, // Pass the signal to the fetch options
    };

    try {
      setLoading(true); // Set loading to true before the fetch starts
      setError(null); // Reset any previous errors

      const response = await fetch(getTransactionsApiUrl, options);

      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }

      const data = await response.json();
      console.log("Astrologer success transactions:", data);

      const { totalCount, totalPages, transactions, success } = data;

      if (!success) {
        throw new Error("Failed to fetch transactions.");
      }

      setSuccessFullTransactionsData(transactions);
      setTotalSuccessFullPages(totalPages);
      setTotalTransactions(totalCount);
    } catch (err) {
      if (err.name === "AbortError") {
        console.log("Fetch aborted");
      } else {
        console.error("Error fetching transactions:", err.message);
        setError(err.message); // Set the error state
      }
    } finally {
      setLoading(false); // Set loading to false after the fetch completes
    }

    return () => {
      controller.abort(); // Abort the fetch if the component unmounts
    };
  };

  const handlePendingChatsPage = (direction) => {
    if (direction === "prev" && currentSuccessFullTransactionPage > 1) {
      setCurrentSuccessFullPage(currentSuccessFullTransactionPage - 1);
    } else if (
      direction === "next" &&
      currentSuccessFullTransactionPage < totalSuccessFullTransactionPages
    ) {
      setCurrentSuccessFullPage(currentSuccessFullTransactionPage + 1);
    }
  };

  const renderSuccessFullTransactions = () => (
    <>
      <table className="data-table">
        <thead>
          <tr>
            <th>TransactionsId</th>
            <th>Date & Time</th>
            <th>Status</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {successFullTransactionsData.map((eachSuccess) => (
            <tr
              className="astro-rows"
              key={eachSuccess.transaction_reference_id}
            >
              <td>{eachSuccess.transaction_reference_id}</td>
              <td>{eachSuccess.date}</td>
              <td>{eachSuccess.status}</td>
              <td>{eachSuccess.amount}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination">
        <button
          className="pagination-button"
          onClick={() => handlePendingChatsPage("prev")}
          disabled={currentSuccessFullTransactionPage === 1}
        >
          Prev
        </button>
        <span className="pagination-info">
          Page {currentSuccessFullTransactionPage} of{" "}
          {totalSuccessFullTransactionPages}
        </span>
        <button
          className="pagination-button"
          onClick={() => handlePendingChatsPage("next")}
          disabled={
            currentSuccessFullTransactionPage ===
            totalSuccessFullTransactionPages
          }
        >
          Next
        </button>
      </div>
    </>
  );

  useEffect(() => {
    const controller = new AbortController(); // Create a controller for each fetch call
    const signal = controller.signal;

    const fetchData = async () => {
      await getAstrologerSuccessFullTransactions(signal);
    };

    fetchData();

    return () => {
      controller.abort(); // Abort the fetch when the component unmounts or a new request is made
    };
  }, [currentSuccessFullTransactionPage]);

  return (
    <div>
      {loading && <p>Loading...</p>}

      {!loading && !error && successFullTransactionsData.length > 0
        ? renderSuccessFullTransactions()
        : !loading &&
          !error && <h1>Currently There is No SuccessFull Transactions</h1>}
    </div>
  );
};

export default AstrologerSuccessFullTransactions;
