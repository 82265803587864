import React, { useEffect, useState } from 'react';
import './index.css'; 

const PaymentsViewdetailsPopUp = ({ message, onCancel }) => {
    
    useEffect(() => {
        const timer = setTimeout(() => {
            onCancel();
        }, 7000);
        
        return () => clearTimeout(timer);
    }, [onCancel]);

    return (
        <div className="payment-astro-popup-overlay">
            <div className="payments-astro-popup-content">
                <p className='messegeeee'>{message}</p>
                
                <button className="astro-confirm-button" onClick={onCancel}>
                    {message ? 'Okay' : 'Loading...'}
                </button>
            </div>
        </div>
    );
};

export default PaymentsViewdetailsPopUp;
