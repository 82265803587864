import { useState, useEffect, useRef } from "react";
import "./freeChat.css";
import BASE_URL from "../../config";
const FreeChat = () => {
  const [start_date, setStartDate] = useState("");
  const [end_date, setEndDate] = useState("");
  const [Admintitle, setAdminTitle] = useState("");
  const [message, setAdminMessage] = useState("");
  const [offers, setOffers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const controllerRef = useRef(null);

  const fetchOffers = async () => {
    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    controllerRef.current = new AbortController();
    const signal = controllerRef.current.signal;

    const response = await fetch(`${BASE_URL}/enabledOffers/get-active-offers`);
    const data = await response.json();
    setOffers(data.offers);
  };

  useEffect(() => {
    fetchOffers();

    return () => {
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
    };
  }, []);

  const applyFreeChatToAllUsers = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const adminDetails = {
      title: Admintitle,
      message,
      start_date,
      end_date,
    };
    const enablingApiUrl = `${BASE_URL}/enabledOffers/apply-freeChat-users`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(adminDetails),
    };
    const response = await fetch(enablingApiUrl, options);
    if (response.ok) {
      setAdminTitle("");
      setAdminMessage("");
      setStartDate("");
      setEndDate("");
      fetchOffers();
    }
    setIsLoading(false);
  };

  const expireOffer = async (offerId) => {
    const response = await fetch(
      `${BASE_URL}/enabledOffers/expire-offer/${offerId}`,
      {
        method: "POST",
      }
    );
    if (response.ok) {
      fetchOffers();
    }
  };

  const handleEditClick = (offer) => {
    setSelectedOffer(offer);
    setAdminTitle(offer.title);
    setAdminMessage(offer.message);
    setStartDate(offer.start_date.split("T")[0]);
    setEndDate(offer.end_date.split("T")[0]);
    setIsEditing(true);
  };

  const updateOffer = async (event) => {
    event.preventDefault();

    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    controllerRef.current = new AbortController();
    const signal = controllerRef.current.signal;

    const updatedDetails = {
      title: Admintitle,
      message,
      start_date,
      end_date,
    };
    // console.log(updatedDetails)

    const response = await fetch(
      `${BASE_URL}/enabledOffers/edit-offer/${selectedOffer._id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedDetails),
        signal,
      }
    );

    if (response.ok) {
      setIsEditing(false);
      setAdminTitle("");
      setAdminMessage("");
      setStartDate("");
      setEndDate("");
      fetchOffers();
    }
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setAdminTitle("");
    setAdminMessage("");
    setStartDate("");
    setEndDate("");
  };

  return (
    <div className="offer-main-container">
      <div className="offer-list">
        <h2>Active Free Chat Offers</h2>
        {offers?.length > 0 ? (
          offers.map((offer) => (
            <div key={offer._id} className="offer-card">
              <h3>{offer.title}</h3>
              <p>{offer.message}</p>
              <p>
                Start Date: {new Date(offer.start_date).toLocaleDateString()}
              </p>
              <p>End Date: {new Date(offer.end_date).toLocaleDateString()}</p>
              <button
                onClick={() => expireOffer(offer._id)}
                className="button expire-button"
              >
                Force Stop
              </button>
              <button
                onClick={() => handleEditClick(offer)}
                className="button edit-button"
              >
                Edit
              </button>
            </div>
          ))
        ) : (
          <p>No active offers</p>
        )}
      </div>

      <div className="coupon-container">
        <h1 className="form-title">Free Chat Offer</h1>
        <form
          className="coupon-form"
          onSubmit={isEditing ? updateOffer : applyFreeChatToAllUsers}
        >
          <div className="form-group">
            <label>Title:</label>
            <input
              value={Admintitle}
              onChange={(e) => setAdminTitle(e.target.value)}
              type="text"
              className="form-input"
              required
            />
          </div>
          <div className="form-group">
            <label>Message</label>
            <textarea
              onChange={(e) => setAdminMessage(e.target.value)}
              value={message}
              className="form-input"
              rows="5"
              required
            />
          </div>
          <div className="form-group">
            <label>Start Date:</label>
            <input
              value={start_date}
              onChange={(e) => setStartDate(e.target.value)}
              className="form-input"
              type="date"
              required
            />
          </div>
          <div className="form-group">
            <label>End Date:</label>
            <input
              value={end_date}
              onChange={(e) => setEndDate(e.target.value)}
              className="form-input"
              type="date"
              required
            />
          </div>
          <div className="form-group button-group">
            <button className="submit-button" type="submit">
              {isLoading ? "Loading..." : isEditing ? "Update Offer" : "Submit"}
            </button>
            {isEditing && (
              <button
                type="button"
                className="cancel-button"
                onClick={handleCancelEdit}
              >
                Cancel
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default FreeChat;
