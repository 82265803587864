import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";
import BASE_URL from "../config";
const AstrologerPendingChats = ({ astrologerId }) => {
  const navigate = useNavigate();

  const [currentPendingPage, setCurrentPendingPage] = useState(1);
  const [pendingChatsData, setPendingChatsData] = useState([]);
  const [totalPendingChatsPages, setPendingChatsPages] = useState(null);
  const [totalChats, setTotalNumberOfChats] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getPendingChats = async (controller) => {
    setIsLoading(true);

    try {
      const response = await fetch(
        `${BASE_URL}/astrologer/get-Astrologer-pending-chats?page=${currentPendingPage}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ astrologerId }),
          signal: controller?.signal,
        }
      );

      const data = await response.json();
      console.log("specific astrologer waiting data", data);
      if (response.ok) {
        const { totalChats, totalPages, chats } = data;
        setPendingChatsData(chats);
        setPendingChatsPages(totalPages);
        setTotalNumberOfChats(totalChats);
      } else {
        console.error(data.message || "Failed to fetch pending chats.");
      }
    } catch (error) {
      console.error("Error fetching pending chats:", error);
    }

    setIsLoading(false);
  };

  const handlePendingChatsPage = (direction) => {
    if (direction === "prev" && currentPendingPage > 1) {
      setCurrentPendingPage(currentPendingPage - 1);
    } else if (
      direction === "next" &&
      currentPendingPage < totalPendingChatsPages
    ) {
      setCurrentPendingPage(currentPendingPage + 1);
    }
  };

  const getDurationFromUpdatedAt = (updatedAt) => {
    if (!updatedAt) return "NA";
    const updatedDateTime = new Date(updatedAt);
    const now = new Date();
    const diffMs = now - updatedDateTime;
    const diffMinutes = Math.floor(diffMs / (1000 * 60));
    if (diffMinutes < 0) return "0 min";
    return `${diffMinutes} min`;
  };

  const handleMove = (chatId) => {
    navigate(`/dashboard/super-astrologers/${chatId}`);
  };

  const regularChatDataTable = (data) => (
    <div className="astro-table-container pymnts-container">
      <table className="astro-table">
        <thead className="astro-he">
          <tr className="astro-bg">
            <th className="he">User Name</th>

            <th className="he">User PhoneNumber</th>
            <th className="he">Cost/min</th>
            <th className="he">Waiting Duration (min)</th>
            <th className="he">Move</th>
          </tr>
        </thead>
        <tbody>
          {data.map((chat) => (
            <tr className="astro-rows" key={chat._id}>
              <td className="he">{chat?.userName || "NA"}</td>

              <td className="he">{chat?.userPhone || "NA"}</td>
              <td className="he">{chat?.cost || "NA"}</td>
              <td className="he">{getDurationFromUpdatedAt(chat.updatedAt)}</td>
              <td className="he">
                <button
                  className="move-button"
                  onClick={() => handleMove(chat._id)}
                >
                  Move
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  useEffect(() => {
    const controller = new AbortController();

    getPendingChats(controller);

    return () => {
      controller?.abort();
    };
  }, [currentPendingPage]);

  return (
    <>
      {pendingChatsData.length > 0 ? (
        <div>
          <button
            className="btn"
            onClick={() => getPendingChats(new AbortController())}
          >
            {isLoading ? "Loading..." : "Reload"}
          </button>

          {isLoading ? <Loader /> : regularChatDataTable(pendingChatsData)}

          <div className="pagination">
            <button
              className="pagination-button"
              onClick={() => handlePendingChatsPage("prev")}
              disabled={currentPendingPage === 1}
            >
              Prev
            </button>
            <span className="pagination-info">
              Page {currentPendingPage} of {totalPendingChatsPages}
            </span>
            <button
              className="pagination-button"
              onClick={() => handlePendingChatsPage("next")}
              disabled={currentPendingPage === totalPendingChatsPages}
            >
              Next
            </button>
          </div>
        </div>
      ) : (
        <h1 className="empty-text">Currently No Pending Chats are there !</h1>
      )}
    </>
  );
};

export default AstrologerPendingChats;
