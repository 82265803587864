import { useEffect, useState } from "react";
import BASE_URL from "../config";
const Accepted = () => {
  const [currentLiveChatPage, setCurrentChatPage] = useState(1);
  const [totalLiveChatPages, setTotalLivePages] = useState(null);
  const [liveChatData, setLiveChatData] = useState([]);
  const [totalNumberOfChats, setTotalNumberOfChats] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const getAcceptedChats = async (signal) => {
    setIsLoading(true);
    const liveChatApiUrl = `${BASE_URL}/astrologer/get-Accepted-chats-data?page=${currentLiveChatPage}`;
    const response = await fetch(liveChatApiUrl, { signal });
    const data = await response.json();
    if (response.ok === true) {
      const { totalChats, totalPages, chats } = data;
      setTotalLivePages(totalPages);
      setLiveChatData(chats);
      setTotalNumberOfChats(totalChats);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handlePageChange = (direction) => {
    if (direction === "prev" && currentLiveChatPage > 1) {
      setCurrentChatPage(currentLiveChatPage - 1);
    } else if (
      direction === "next" &&
      currentLiveChatPage < totalLiveChatPages
    ) {
      setCurrentChatPage(currentLiveChatPage + 1);
    }
  };

  const AcceptedChatsTable = () => (
    <>
      <div className="astro-table-container pymnts-container">
        <table className="astro-table">
          <thead className="astro-he">
            <tr className="astro-bg">
              <th className="he">User Name</th>
              <th className="he">Astrologer Name</th>
              <th className="he">User PhoneNumber</th>
              <th className="he">Astrologer PhoneNumber</th>
              <th className="he">Cost/min</th>
              <th className="he">Status</th>
              <th className="he">Date Time</th>
            </tr>
          </thead>
          <tbody>
            {liveChatData?.map((chat) => (
              <tr className="astro-rows" key={chat._id}>
                <td className="he">{chat?.userName || "NA"}</td>
                <td className="he">
                  {chat?.astrologerId?.astrologerName || "NA"}
                </td>
                <td className="he">{chat.from || "NA"}</td>
                <td className="he">{chat.to || "NA"}</td>
                <td className="he">{chat?.cost || "NA"}</td>
                <td className="he">{chat?.status || "NA"}</td>
                <td className="he">{chat?.updatedAt}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination">
        <button
          className="pagination-button"
          onClick={() => handlePageChange("prev")}
          disabled={currentLiveChatPage === 1}
        >
          Prev
        </button>
        <span className="pagination-info">
          Page {currentLiveChatPage} of {totalLiveChatPages}
        </span>
        <button
          className="pagination-button"
          onClick={() => handlePageChange("next")}
          disabled={currentLiveChatPage === totalLiveChatPages}
        >
          Next
        </button>
      </div>
    </>
  );

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    getAcceptedChats(signal);
    return () => {
      controller.abort();
    };
  }, [currentLiveChatPage]);

  return (
    <>
      <button className="btn" onClick={() => getAcceptedChats()}>
        {isLoading === true ? "...Loading" : "Reload"}
      </button>

      <div>
        {totalNumberOfChats !== 0
          ? AcceptedChatsTable()
          : "Currently Accepted Chats are not available!"}
      </div>
    </>
  );
};
export default Accepted;
