import { useEffect, useState } from "react";
import Loader from "../Loader";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../config";
const FailedChats = () => {
  const [currentFailedPage, setCurrentFailedPage] = useState(1);
  const [totalFailedPages, setTotalFailedPages] = useState(null);
  const [failedChatsData, setFailedChatsData] = useState([]);
  const [totalFailedChatsCount, setFailedChatsCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(searchQuery);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const navigate = useNavigate();

  const handlePageChange = (direction) => {
    if (direction === "prev" && currentFailedPage > 1) {
      setCurrentFailedPage(currentFailedPage - 1);
    } else if (direction === "next" && currentFailedPage < totalFailedPages) {
      setCurrentFailedPage(currentFailedPage + 1);
    }
  };

  const failedChatDataTable = () => (
    <>
      <div className="astro-table-container pymnts-container">
        <table className="astro-table">
          <thead className="astro-he">
            <tr className="astro-bg">
              <th className="he">User Name</th>
              <th className="he">Astrologer Name</th>
              <th className="he">User PhoneNumber</th>
              <th className="he">Astrologer PhoneNumber</th>
              <th className="he">Cost/min</th>
              <th className="he">Date Time</th>
            </tr>
          </thead>
          <tbody>
            {failedChatsData?.map((chat) => (
              <tr className="astro-rows" key={chat._id}>
                <td
                  className="he"
                  onClick={() =>
                    navigate(`/dashboard/user/${chat?.userId}`, {
                      state: {
                        user: {
                          _id: chat?.userId,
                          userName: chat?.userName,
                          phone_number: chat?.from,
                        },
                      },
                    })
                  }
                  style={{ color: "blue", cursor: "pointer" }}
                >
                  {chat?.userName || "NA"}
                </td>
                <td
                  className="he"
                  onClick={() =>
                    navigate(`/dashboard/astrologer/${chat?._id}`, {
                      state: { astrologer: chat.astrologer },
                    })
                  }
                  style={{ color: "blue", cursor: "pointer" }}
                >
                  {chat?.astrologer?.astrologerName || "NA"}
                </td>
                <td className="he">{chat.from || "NA"}</td>
                <td className="he">{chat.to || "NA"}</td>
                <td className="he">{chat?.cost || "NA"}</td>
                <td className="he">{chat?.updatedAt}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination">
        <button
          className="pagination-button"
          onClick={() => handlePageChange("prev")}
          disabled={currentFailedPage === 1}
        >
          Prev
        </button>
        <span className="pagination-info">
          Page {currentFailedPage} of {totalFailedPages}
        </span>
        <button
          className="pagination-button"
          onClick={() => handlePageChange("next")}
          disabled={currentFailedPage === totalFailedPages}
        >
          Next
        </button>
      </div>
    </>
  );

  // Fetch failed chats with pagination
  const getFailedChats = async (signal) => {
    const failedChatsApiUrl = `${BASE_URL}/user/get-failed-chats?page=${currentFailedPage}`;
    setIsLoading(true);
    const response = await fetch(failedChatsApiUrl, signal);
    const data = await response.json();
    if (response.ok === true) {
      const { failedChatsList, total, totalPages } = data;
      setFailedChatsCount(total);
      setFailedChatsData(failedChatsList);
      setTotalFailedPages(totalPages);
    }
    setIsLoading(false);
  };

  // Fetch failed chats by date
  const fetchFailedChatsByDate = async (signal) => {
    const apiUrl = `${BASE_URL}/user/get-failed-chats-by-date?date="${selectedDate}"&page=${currentFailedPage}`;
    setIsLoading(true);
    const response = await fetch(apiUrl, signal);
    const data = await response.json();

    if (response.ok) {
      const { failedChatsList, total, totalPages } = data;
      console.log("failed chats by date", failedChatsList);
      setFailedChatsData(failedChatsList);
      setFailedChatsCount(total);
      setTotalFailedPages(totalPages);
    }
    setIsLoading(false);
  };

  // Fetch failed chats with search query
  const searchFailedChats = async (signal) => {
    const searchApiUrl = `${BASE_URL}/user/search-failed-chats?search=${debouncedSearchQuery}`;
    const response = await fetch(searchApiUrl, signal);
    const data = await response.json();
    setFailedChatsData(data.chats);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 500);

    return () => clearTimeout(timer);
  }, [searchQuery]);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    if (debouncedSearchQuery) {
      searchFailedChats(signal);
    } else if (selectedDate) {
      fetchFailedChatsByDate(signal);
    } else {
      getFailedChats(signal);
    }
    return () => controller.abort();
  }, [currentFailedPage, debouncedSearchQuery, selectedDate]);

  return (
    <div>
      <div>
        <input
          type="search"
          placeholder="Search by Username, Astrologer Name, or Phone Number"
          className="search-bar"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button className="btn" onClick={() => getFailedChats()}>
          {isLoading ? "Loading..." : "Reload"}
        </button>
      </div>

      <button className="btn">Expired Chats</button>
      <div className="date-picker-container">
        <h3 className="date-picker-heading">Select a Date</h3>
        <input
          type="date"
          value={selectedDate}
          onChange={(event) => {
            setCurrentFailedPage(1); // Reset to the first page on date change
            setSelectedDate(event.target.value);
          }}
          className="date-input"
        />
        <button
          className="date-clear"
          onClick={() => {
            setSelectedDate("");
            getFailedChats();
          }}
        >
          Clear
        </button>
        {selectedDate && (
          <p className="selected-date-text">{`Selected date: ${selectedDate}`}</p>
        )}
        <h3>{`Total Failed:${totalFailedChatsCount}`}</h3>
      </div>

      {isLoading ? <Loader /> : failedChatDataTable()}
    </div>
  );
};

export default FailedChats;
