import React, { useEffect, useState } from "react";
import BASE_URL from "../config";

const IssuePercentageContainer = () => {
  const [totalChats, setTotalChats] = useState(null);
  const [websocketDisconnectedCount, setWebsocketDisconnectedCount] = useState(null);
  const [userIssuePercentage, setUserIssuePercentage] = useState(null);
  const [astrologerIssuePercentage, setAstrologerIssuePercentage] = useState(null);
  const [totalWebSocketDisconnectedPercentage, setTotalWebSocketDisconnectedPercentage] = useState(null);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth());

  const months = [
    "January", "February", "March", "April", "May", "June", 
    "July", "August", "September", "October", "November", "December"
  ];

  useEffect(() => {
    const fetchIssueData = async () => {
      try {
        const query = `page=1&limit=10&year=${year}&month=${month + 1}`; 
        const response = await fetch(`${BASE_URL}/astrologer/get-Session-end-chats-data-inPercentage?${query}`);
        const data = await response.json();

        if (data.success) {
          setTotalChats(data.totalChats);
          setWebsocketDisconnectedCount(data.websocketDisconnectedCount);
          setUserIssuePercentage(data.userIssuePercentage);
          setAstrologerIssuePercentage(data.astrologerIssuePercentage);
          setTotalWebSocketDisconnectedPercentage(data.totalWebSocketDisconnectedPercentage);
        } else {
          console.error("Failed to fetch data:", data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchIssueData();
  }, [year, month]);

  if (userIssuePercentage === null || astrologerIssuePercentage === null || totalWebSocketDisconnectedPercentage === null) {
    return <div>Loading...</div>;
  }

  return (
    <div style={containerStyle}>
      <h1>WebSocket Issue Percentage</h1>
      <div style={controlStyle}>
        <label>
          Year:
          <select value={year} onChange={(e) => setYear(Number(e.target.value))} style={dropdownStyle}>
         {Array.from({ length: 2 }, (_, i) => (
          <option key={i} value={new Date().getFullYear() - 1 + i}>
          {new Date().getFullYear() - 1 + i}
         </option>
         ))}
         </select>

        </label>
        <label>
          Month:
          <select value={month} onChange={(e) => setMonth(Number(e.target.value))} style={dropdownStyle}>
            {months.map((monthName, index) => (
              <option key={index} value={index}>
                {monthName}
              </option>
            ))}
          </select>
        </label>
      </div>
      <div style={itemStyle}>
        <strong>Total Chats: </strong>
        <span>{totalChats}</span>
      </div>
      <div style={itemStyle}>
        <strong>Total WebSocket Disconnected Count: </strong>
        <span>{websocketDisconnectedCount}</span>
      </div>
      <div style={itemStyle}>
        <strong>Total WebSocket Disconnected Percentage Rate: </strong>
        <span>{totalWebSocketDisconnectedPercentage}%</span>
      </div>
      <div style={itemStyle}>
        <strong>User WebSocket Disconnected Issue Percentage: </strong>
        <span>{userIssuePercentage}%</span>
      </div>
      <div style={itemStyle}>
        <strong>Astrologer WebSocket Disconnected Issue Percentage: </strong>
        <span>{astrologerIssuePercentage}%</span>
      </div>
    </div>
  );
};

const containerStyle = {
  padding: "20px",
  borderRadius: "8px",
  backgroundColor: "#f9f9f9",
  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
  width: "400px",
  margin: "10px auto",
  textAlign: "center",
};

const itemStyle = {
  marginBottom: "10px",
};

const controlStyle = {
  marginBottom: "20px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

const dropdownStyle = {
  padding: "8px",
  fontSize: "16px",
  borderRadius: "4px",
  border: "1px solid #ccc",
};

export default IssuePercentageContainer;
