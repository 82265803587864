import React, { useState } from "react";
import "./onBoardAstrologers.css";
import OnBoardAstrologersPopUp from "./onBooardAstrologerPopUp";
import BASE_URL from "../config";
const OnBoardAstrologers = () => {
  const [formData, setFormData] = useState({
    astrologerName: "",
    phone_number: "",
    profile_photo: null,
    category: "",
    known_languages: "",
    expertIn: "",
    experience: "",
    cost: "",
    order_bookings: "",
  });

  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "profile_photo") {
      setFormData({ ...formData, profile_photo: e.target.files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("astrologerName", formData.astrologerName);
    data.append("phone_number", formData.phone_number);
    data.append("profile_photo", formData.profile_photo);
    data.append("category", formData.category);
    data.append("known_languages", formData.known_languages);
    data.append("expertIn", formData.expertIn);
    data.append("experience", formData.experience);
    data.append("cost", formData.cost);
    data.append("order_bookings", formData.order_bookings);

    setLoading(true);
    setResponseMessage("");

    try {
      const response = await fetch(`${BASE_URL}/onboard/newAstrologer`, {
        method: "POST",
        body: data,
      });
      const result = await response.json();
      setLoading(false);
      if (result.success) {
        setResponseMessage(result.message);
      } else {
        setResponseMessage("Error onboarding astrologer: " + result.message);
      }
    } catch (error) {
      setLoading(false);
      setResponseMessage("Failed to onboard astrologer.");
    }
  };

  const closePopUp = () => {
    setResponseMessage("");
    setFormData({
      astrologerName: "",
      phone_number: "",
      profile_photo: null,
      category: "",
      known_languages: "",
      expertIn: "",
      experience: "",
      cost: "",
      order_bookings: "",
    });
  };

  return (
    <div className="onboard-form">
      <h1>Onboard Astrologer</h1>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="form-group">
          <label>Astrologer Name</label>
          <input
            type="text"
            name="astrologerName"
            placeholder="Enter astrologer's name"
            value={formData.astrologerName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Phone Number</label>
          <input
            type="text"
            name="phone_number"
            placeholder="Enter phone number"
            value={formData.phone_number}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Profile Photo</label>
          <input
            type="file"
            name="profile_photo"
            accept="image/*"
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Category</label>
          <input
            type="text"
            name="category"
            placeholder="Enter categories like Love, Marriage, etc."
            value={formData.category}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Known Languages</label>
          <input
            type="text"
            name="known_languages"
            placeholder="Enter languages"
            value={formData.known_languages}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Expertise</label>
          <input
            type="text"
            name="expertIn"
            placeholder="Enter expertise like Vedic Astrology, Numerology, etc."
            value={formData.expertIn}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group-row">
          <div className="form-subgroup">
            <label>Experience</label>
            <input
              type="number"
              name="experience"
              placeholder="Years"
              value={formData.experience}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-subgroup">
            <label>Cost</label>
            <input
              type="number"
              name="cost"
              placeholder="₹ per session"
              value={formData.cost}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-subgroup">
            <label>Order Bookings</label>
            <input
              type="number"
              name="order_bookings"
              placeholder="Count"
              value={formData.order_bookings}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <button type="submit" className="submit-btn">
          Onboard
        </button>
      </form>

      {(loading || responseMessage) && (
        <OnBoardAstrologersPopUp
          loading={loading}
          message={responseMessage}
          close={closePopUp}
        />
      )}
    </div>
  );
};

export default OnBoardAstrologers;
