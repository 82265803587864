import { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import "./index.css";
import Loader from "../Loader";
import CompletedCalls from "./CompletedCalls";
import FailedCalls from "./FailedCalls";
import PendingCalls from "./PendingCalls";
import TransferredCalls from "./TransferredCalls";

const LiveCallsSection = () => {
  const [callbackHistoryDetails, setCallBackHistory] = useState([]);
  const [completedCallHistory, setCompletedCallHistory] = useState([]);
  const [failedCallHistory, setFailedCallHistory] = useState([]);
  const [waitingCallList, setWaitingCallList] = useState([]);
  const [transferCalls, setTransferCalls] = useState([]);

  const [activeTabButton, setActiveTabButton] = useState("Call History");
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [isLoadingRefresh, setIsLoadingRefresh] = useState(false);
  const [isLoadingTransfer, setIsLoadingTransfer] = useState(false);

  const navigate = useNavigate();

  const rowsPerPage = 10;

  const [completedCurrentPage, setCompletedCurrentPage] = useState(1);
  const [failedCurrentPage, setFailedCurrentPage] = useState(1);
  const [waitingCurrentPage, setWaitingCurrentPage] = useState(1);
  const [transferCurrentPage, setTransferCurrentPage] = useState(1);

  // const callHistoryApi = "${BASE_URL}/user/get-all-exotel-callbacks";
  // // const waitingCallApi =
  // //   "${BASE_URL}/astrologer/get-allwaiting-calls";
  // const transferCallsApi = "${BASE_URL}/astrologer/transfer-chats";

  // const getUserCallHistory = async () => {
  //   try {
  //     const response = await fetch(callHistoryApi);
  //     const data = await response.json();
  //     const sortedCalls = data?.callbackHistory.sort(
  //       (a, b) => new Date(b.date) - new Date(a.date)
  //     );

  //     if (response.ok) {
  //       setCallBackHistory(sortedCalls);

  //       const completedCalls = sortedCalls.filter(
  //         (call) => call.status === "completed"
  //       );
  //       const sortedCallsTwo = data?.callbackHistory.sort(
  //         (a, b) => new Date(b.updatedDate) - new Date(a.updatedDate)
  //       );
  //       const failedCalls = sortedCallsTwo.filter((call) =>
  //         ["failed", "no-answer"].includes(call.status)
  //       );
  //       // console.log(failedCalls);

  //       setCompletedCallHistory(completedCalls);
  //       setFailedCallHistory(failedCalls);
  //     } else {
  //       console.error("Error: Failed to fetch callback history", data);
  //     }
  //   } catch (error) {
  //     console.error("Error: An unexpected error occurred", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const getWaitingCallHistory = async () => {
  //   setIsLoadingRefresh(true);
  //   try {
  //     const response = await fetch(waitingCallApi);
  //     const data = await response.json();
  //     const { chats } = data;
  //     const sortedData = chats.sort(
  //       (a, b) => new Date(b.date) - new Date(a.date)
  //     );
  //     if (response.ok) {
  //       setWaitingCallList(sortedData);
  //     } else {
  //       console.error("Error fetching waiting calls:", data);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching waiting calls:", error);
  //   } finally {
  //     setIsLoadingRefresh(false);
  //   }
  // };

  // const getTransferCalls = async () => {
  //   setIsLoadingTransfer(true);
  //   try {
  //     const response = await fetch(transferCallsApi);
  //     const data = await response.json();
  //     // console.log("Transfer Calls Data:", data);
  //     const Data = data.data.sort(
  //       (a, b) => new Date(b.date) - new Date(a.date)
  //     );
  //     const transfers = Array.isArray(Data) ? data.data : [];
  //     const filteredTransfers = transfers.filter(
  //       (eachTransfer) => eachTransfer.type === "call"
  //     );

  //     if (response.ok) {
  //       setTransferCalls(filteredTransfers);
  //     } else {
  //       console.error("Error fetching transfer calls:", data);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching transfer calls:", error);
  //   } finally {
  //     setIsLoadingTransfer(false);
  //   }
  // };

  const handleCallsMove = (callId) => {
    navigate(`/dashboard/super-astrologers/${callId}`);
  };

  // useEffect(() => {
  //   const fetchAllData = async () => {
  //     try {
  //       await getUserCallHistory();

  //       await getWaitingCallHistory();

  //       await getTransferCalls();
  //     } catch (error) {
  //       console.error("Error fetching call history:", error);
  //     }
  //   };
  //   fetchAllData();
  // }, []);

  const convertTo12Hour = (time) => {
    if (!time) return "NA";
    let [hours, minutes] = time.split(":");
    hours = parseInt(hours, 10);

    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;

    return `${hours}:${minutes} ${ampm}`;
  };

  const getDurationFromUpdatedAt = (updatedAt) => {
    if (!updatedAt) return "NA";

    const updatedDateTime = new Date(updatedAt);
    const now = new Date();
    const diffMs = now - updatedDateTime;
    const diffMinutes = Math.floor(diffMs / (1000 * 60));

    if (diffMinutes < 0) return "0 min";
    return `${diffMinutes} min`;
  };

  const filteredCompletedCallHistory = useMemo(() => {
    if (!searchTerm) return completedCallHistory;
    return completedCallHistory.filter(
      (call) =>
        call.userName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        call.astrologerName?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm, completedCallHistory]);

  const filteredFailedCallHistory = useMemo(() => {
    if (!searchTerm) return failedCallHistory;
    return failedCallHistory.filter(
      (call) =>
        call.userName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        call.astrologerName?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm, failedCallHistory]);

  const filteredWaitingCallList = useMemo(() => {
    if (!searchTerm) return waitingCallList;
    return waitingCallList.filter(
      (call) =>
        call.userName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        call.astrologerId?.astrologerName
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase())
    );
  }, [searchTerm, waitingCallList]);

  const filteredTransferCalls = useMemo(() => {
    if (!searchTerm) return transferCalls;
    return transferCalls.filter(
      (call) =>
        call.userName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        call.astrologerName?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm, transferCalls]);

  const totalCompletedPages = Math.ceil(
    filteredCompletedCallHistory.length / rowsPerPage
  );
  const totalFailedPages = Math.ceil(
    filteredFailedCallHistory.length / rowsPerPage
  );
  const totalWaitingPages = Math.ceil(
    filteredWaitingCallList.length / rowsPerPage
  );
  const totalTransferPages = Math.ceil(
    filteredTransferCalls.length / rowsPerPage
  );

  const paginatedCompletedHistory = filteredCompletedCallHistory.slice(
    (completedCurrentPage - 1) * rowsPerPage,
    completedCurrentPage * rowsPerPage
  );

  const paginatedFailedHistory = filteredFailedCallHistory.slice(
    (failedCurrentPage - 1) * rowsPerPage,
    failedCurrentPage * rowsPerPage
  );

  const paginatedWaitingHistory = filteredWaitingCallList.slice(
    (waitingCurrentPage - 1) * rowsPerPage,
    waitingCurrentPage * rowsPerPage
  );

  const paginatedTransferHistory = filteredTransferCalls.slice(
    (transferCurrentPage - 1) * rowsPerPage,
    transferCurrentPage * rowsPerPage
  );

  const handlePageChange = (setPage, currentPage, direction, totalPages) => {
    if (direction === "prev" && currentPage > 1) {
      setPage(currentPage - 1);
    } else if (direction === "next" && currentPage < totalPages) {
      setPage(currentPage + 1);
    }
  };

  // const RegularCallsTable = ({
  //   data,
  //   currentPage,
  //   setPage,
  //   totalChats,
  //   showMoveButton = false,
  //   failedTimeShow = false,
  // }) => (
  //   <div className="astro-table-container pymnts-container">
  //     <table className="astro-table">
  //       <thead className="astro-he">
  //         <tr className="astro-bg">
  //           <th className="he">User Name</th>
  //           <th className="he">User Phone Number</th>
  //           <th className="he">Astrologer Name</th>
  //           <th className="he">Astrologer Phone Number</th>
  //           <th className="he">Date/Time</th>
  //           <th className="he">Cost/min</th>
  //           {!showMoveButton && <th className="he">Recording</th>}
  //           {showMoveButton && <th className="he">Move</th>}
  //         </tr>
  //       </thead>
  //       <tbody>
  //         {data.map((call) => {
  //           const updatedAt = new Date(call.updatedDate).toLocaleString();
  //           const formattedDate = new Date(
  //             call.createdAt || call.date
  //           ).toLocaleDateString("en-IN");
  //           const formattedTime = call.time
  //             ? convertTo12Hour(call.time)
  //             : new Date(call.createdAt || call.date).toLocaleTimeString(
  //                 "en-IN",
  //                 { hour: "numeric", minute: "numeric", hour12: true }
  //               );

  //           return (
  //             <tr className="livecalls-table-rows" key={call._id || call.id}>
  //               <td className="he">{call.userName || "NA"}</td>
  //               <td className="he">{call.from || "NA"}</td>
  //               <td className="he">{call.astrologerName || "NA"}</td>
  //               <td className="he">{call.to || "NA"}</td>
  //               <td className="he">
  //                 {failedTimeShow === true
  //                   ? `${updatedAt}`
  //                   : `${formattedDate} ${formattedTime}`}
  //               </td>
  //               <td className="he">{call.cost || call.callCost || "NA"}</td>
  //               {!showMoveButton && call.recordingUrl && (
  //                 <td className="he">
  //                   <audio className="audio-container" controls>
  //                     <source src={call.recordingUrl} type="audio/mp3" />
  //                   </audio>
  //                 </td>
  //               )}
  //               {showMoveButton && (
  //                 <td className="he">
  //                   <button
  //                     className="move-button"
  //                     onClick={() => handleCallsMove(call._id || call.id)}
  //                   >
  //                     Move
  //                   </button>
  //                 </td>
  //               )}
  //             </tr>
  //           );
  //         })}
  //       </tbody>
  //     </table>
  //     <div className="pagination">
  //       <button
  //         className="pagination-button"
  //         onClick={() =>
  //           handlePageChange(
  //             setPage,
  //             currentPage,
  //             "prev",
  //             Math.ceil(totalChats.length / rowsPerPage)
  //           )
  //         }
  //         disabled={currentPage === 1}
  //       >
  //         Previous
  //       </button>
  //       <span className="pagination-info">
  //         Page {currentPage} of {Math.ceil(totalChats.length / rowsPerPage)}
  //       </span>
  //       <button
  //         className="pagination-button"
  //         onClick={() =>
  //           handlePageChange(
  //             setPage,
  //             currentPage,
  //             "next",
  //             Math.ceil(totalChats.length / rowsPerPage)
  //           )
  //         }
  //         disabled={currentPage === Math.ceil(totalChats.length / rowsPerPage)}
  //       >
  //         Next
  //       </button>
  //     </div>
  //   </div>
  // );

  // const WaitingCallsTable = ({ data, currentPage, setPage }) => (
  //   <>
  //     <button
  //       className="btn"
  //       onClick={getWaitingCallHistory}
  //       disabled={isLoadingRefresh}
  //     >
  //       {isLoadingRefresh ? ".Loading..." : "Reload"}
  //     </button>
  //     <table className="astro-table">
  //       <thead className="astro-he">
  //         <tr className="astro-bg">
  //           <th className="he">User Name</th>
  //           <th className="he">User Phone Number</th>
  //           <th className="he">Astrologer Name</th>
  //           <th className="he">Previous Astrologer</th>
  //           <th className="he">Astrologer Phone Number</th>
  //           <th className="he">Date</th>
  //           <th className="he">Waiting Duration(min)</th>
  //           <th className="he">Cost/min</th>
  //           <th className="he">Move</th>
  //         </tr>
  //       </thead>
  //       <tbody>
  //         {data.map((call) => {
  //           const formattedDate = new Date(
  //             call.createdAt || call.date
  //           ).toLocaleDateString("en-IN");
  //           const formattedTime = getDurationFromUpdatedAt(call.updatedAt);

  //           return (
  //             <tr className="livecalls-table-rows" key={call._id || call.id}>
  //               <td className="he">{call.userName || "NA"}</td>
  //               <td className="he">{call.from || "NA"}</td>
  //               <td className="he">
  //                 {call.astrologerId?.astrologerName || "NA"}
  //               </td>
  //               <td className="he">{call.prevAstrologerId || "NA"}</td>
  //               <td className="he">{call.to || "NA"}</td>
  //               <td className="he">{formattedDate}</td>
  //               <td className="he">{formattedTime} </td>
  //               <td className="he">{call.cost || "NA"}</td>
  //               <td className="he">
  //                 <button
  //                   className="move-button"
  //                   onClick={() => handleCallsMove(call._id || call.id)}
  //                 >
  //                   Move
  //                 </button>
  //               </td>
  //             </tr>
  //           );
  //         })}
  //       </tbody>
  //     </table>
  //     <div className="pagination">
  //       <button
  //         className="pagination-button"
  //         onClick={() =>
  //           handlePageChange(setPage, currentPage, "prev", totalWaitingPages)
  //         }
  //         disabled={currentPage === 1}
  //       >
  //         Previous
  //       </button>
  //       <span className="pagination-info">
  //         Page {currentPage} of {totalWaitingPages}
  //       </span>
  //       <button
  //         className="pagination-button"
  //         onClick={() =>
  //           handlePageChange(setPage, currentPage, "next", totalWaitingPages)
  //         }
  //         disabled={currentPage === totalWaitingPages}
  //       >
  //         Next
  //       </button>
  //     </div>
  //   </>
  // );

  // const TransferCallsTable = ({ data, currentPage, setPage }) => (
  //   <>
  //     <button
  //       className="btn"
  //       onClick={getTransferCalls}
  //       disabled={isLoadingTransfer}
  //     >
  //       {isLoadingTransfer ? ".Loading..." : "Reload"}
  //     </button>
  //     <div className="astro-table-container pymnts-container">
  //       <table className="astro-table">
  //         <thead className="astro-he">
  //           <tr className="astro-bg">
  //             <th className="he">User Name</th>
  //             <th className="he">Astrologer Name</th>
  //             <th className="he">Time</th>
  //             <th className="he">Date</th>
  //           </tr>
  //         </thead>
  //         <tbody>
  //           {data.map((call) => {
  //             const dateObj = new Date(call.createdAt || call.date);
  //             const isValidDate = !isNaN(dateObj.getTime());
  //             const formattedDate = isValidDate
  //               ? dateObj.toLocaleDateString("en-IN")
  //               : "NA";
  //             const formattedTime = isValidDate
  //               ? dateObj.toLocaleTimeString("en-IN", {
  //                   hour: "numeric",
  //                   minute: "numeric",
  //                   hour12: true,
  //                 })
  //               : "NA";
  //             return (
  //               <tr className="astro-rows" key={call._id || call.id}>
  //                 <td className="he">{call.userName || "NA"}</td>
  //                 <td className="he">{call.astrologerName || "NA"}</td>
  //                 <td className="he">{formattedTime}</td>
  //                 <td className="he">{formattedDate}</td>
  //               </tr>
  //             );
  //           })}
  //         </tbody>
  //       </table>
  //     </div>
  //     <div className="pagination">
  //       <button
  //         className="pagination-button"
  //         onClick={() =>
  //           handlePageChange(setPage, currentPage, "prev", totalTransferPages)
  //         }
  //         disabled={currentPage === 1}
  //       >
  //         Previous
  //       </button>
  //       <span className="pagination-info">
  //         Page {currentPage} of {totalTransferPages}
  //       </span>
  //       <button
  //         className="pagination-button"
  //         onClick={() =>
  //           handlePageChange(setPage, currentPage, "next", totalTransferPages)
  //         }
  //         disabled={currentPage === totalTransferPages}
  //       >
  //         Next
  //       </button>
  //     </div>
  //   </>
  // );

  return (
    <div className="call-container">
      <h1 className="chats-main-heading">Calls</h1>
      <div className="Status-panel-container">
        <button
          className={`btn ${
            activeTabButton === "Call History" ? "activeTabSection" : ""
          }`}
          onClick={() => setActiveTabButton("Call History")}
        >
          Completed
        </button>

        <button
          className={`btn ${
            activeTabButton === "Failed Call History" ? "activeTabSection" : ""
          }`}
          onClick={() => setActiveTabButton("Failed Call History")}
        >
          Failed
        </button>
        <button
          className={`btn ${
            activeTabButton === "Waiting Call History" ? "activeTabSection" : ""
          }`}
          onClick={() => setActiveTabButton("Waiting Call History")}
        >
          Pending
        </button>

        <button
          className={`btn ${
            activeTabButton === "Transfer" ? "activeTabSection" : ""
          }`}
          onClick={() => setActiveTabButton("Transfer")}
        >
          Transfered
        </button>
      </div>

      <>
        {activeTabButton === "Call History" && (
          <>
            {/* <button
                className="btn"
                onClick={getUserCallHistory}
                disabled={isLoadingRefresh}
              >
                {isLoadingRefresh ? ".Loading..." : "Reload"}
              </button> */}
            {/* <RegularCallsTable
                data={paginatedCompletedHistory}
                currentPage={completedCurrentPage}
                setPage={setCompletedCurrentPage}
                totalChats={filteredCompletedCallHistory}
                showMoveButton={false}
              /> */}
            <CompletedCalls />
          </>
        )}

        {activeTabButton === "Failed Call History" && (
          <>
            {/* <button
                className="btn"
                onClick={getUserCallHistory}
                disabled={isLoadingRefresh}
              >
                {isLoadingRefresh ? ".Loading..." : "Reload"}
              </button> */}
            {/* <RegularCallsTable
                data={paginatedFailedHistory}
                currentPage={failedCurrentPage}
                setPage={setFailedCurrentPage}
                totalChats={filteredFailedCallHistory}
                showMoveButton={false}
                failedTimeShow={true}
              /> */}
            {<FailedCalls />}
          </>
        )}

        {activeTabButton === "Waiting Call History" && (
          // <WaitingCallsTable
          //   data={paginatedWaitingHistory}
          //   currentPage={waitingCurrentPage}
          //   setPage={setWaitingCurrentPage}
          // />
          <PendingCalls />
        )}

        {activeTabButton === "Transfer" && (
          // <TransferCallsTable
          //   data={paginatedTransferHistory}
          //   currentPage={transferCurrentPage}
          //   setPage={setTransferCurrentPage}
          // />
          <TransferredCalls />
        )}
        {/* <button onClick={getUserCallHistory}>Checking callHistory</button> */}
      </>
    </div>
  );
};

export default LiveCallsSection;
