import { useState } from "react";
import TodayLoginTime from "../TodayLoginTime/TodayLoginTime";
import PastPickupTimes from "../PastPickupTimes/PastPickupTimes";
const AstrologerLoginHistory=({astrologerId,pickedDate})=>{
    const [filter, setFilter] = useState("DutyTimes");
    console.log("picked date in login history", pickedDate);
    return (
        <div>
           <div className="filters">
            <button
          className={`filter-button ${
            filter === "DutyTimes" ? "active-filter" : ""
          }`}
          onClick={() => setFilter("DutyTimes")}
        >
          Duty
        </button>
        <button
          className={`filter-button ${
            filter === "Pickup Timings" ? "active-filter" : ""
          }`}
          onClick={() => setFilter("Pickup Timings")}
        >
          Pickup 
        </button>
        
      </div>
        
    {filter==="DutyTimes" && (<TodayLoginTime astrologerId={astrologerId}/>)}
   {filter==="Pickup Timings" && (<PastPickupTimes astrologerId={astrologerId}/>)}
  </div>
    )
}

export default AstrologerLoginHistory