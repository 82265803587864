import { useEffect, useState } from "react";
import BASE_URL from "../config";
const UserProfileDetails = (user) => {
  const [userProfile, setUserProfile] = useState("");
  const userId = user.user;

  console.log("user profile id", userId);

  const getUserProfileDetails = async (signal) => {
    const profileApiUrl = `${BASE_URL}/user/get-user/${userId}`;
    const response = await fetch(profileApiUrl, { signal });
    const data = await response.json();
    const { success, user } = data;
    // console.log("user profile details",user)
    setUserProfile(user);
  };

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    getUserProfileDetails(signal);

    return () => {
      controller.abort();
    };
  }, []);

  const getProfileTable = () => {
    return (
      <table className="astro-details-table">
        <tbody>
          <tr className="single-astro-rows">
            <td>
              <strong className="astro-properties">DOB:</strong>
            </td>
            <td>{userProfile?.dob?.slice(0, 10)}</td>
          </tr>
          <tr className="single-astro-rows">
            <td>
              <strong className="astro-properties">Zodiac Sign:</strong>
            </td>
            <td>{userProfile?.zodiac_sign || "NA"}</td>
          </tr>
          <tr className="single-astro-rows">
            <td>
              <strong className="astro-properties">Address1:</strong>
            </td>
            <td>{userProfile?.address1 || "NA"}</td>
          </tr>
          <tr className="single-astro-rows">
            <td>
              <strong className="astro-properties">Address2:</strong>
            </td>
            <td>{userProfile?.address2 || "NA"}</td>
          </tr>
          <tr className="single-astro-rows">
            <td>
              <strong className="astro-properties">Gender:</strong>
            </td>
            <td>{userProfile?.gender}</td>
          </tr>
          <tr className="single-astro-rows">
            <td>
              <strong className="astro-properties">Place of Birth:</strong>
            </td>
            <td>{userProfile?.place_of_birth}</td>
          </tr>
          <tr className="single-astro-rows">
            <td>
              <strong className="astro-properties">Time of Birth:</strong>
            </td>
            <td>{userProfile?.time_of_birth}</td>
          </tr>
          <tr className="single-astro-rows">
            <td>
              <strong className="astro-properties">Wallet:</strong>
            </td>
            <td>{userProfile?.wallet}</td>
          </tr>
          <tr className="single-astro-rows">
            <td>
              <strong className="astro-properties">User Preferences:</strong>
            </td>
            <td>{userProfile?.userPreference?.join(",") || "NA"}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  return <div>{getProfileTable()}</div>;
};

export default UserProfileDetails;
